import { FC, useEffect, useMemo, useState } from "react";

import {
  Box,
  Checkbox,
  CheckIcon,
  Column,
  EmptyState,
  ErrorIcon,
  LinkButton,
  Paragraph,
  RadioGroup,
  Spinner,
  Text,
} from "@hightouchio/ui";

import { useUser } from "src/contexts/user-context";
import { CanDeployModelsQuery, DeploymentOperation, LinkableWorkspaceResourcesQuery } from "src/graphql";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

import { SimpleWizard, SimpleWizardStep } from "../simple-wizard";
import { Deployment, TargetWorkspace } from "./common";
import { DeploymentComparison, DeploymentDiff } from "./diff";
import { DeploymentTargetRadio } from "./target";

interface TargetDeploymentDetails {
  test: CanDeployModelsQuery["canDeploySegments"][0];
  targetWorkspace: TargetWorkspace;
}

interface DeploymentWizardProps {
  deployment: Deployment;

  // Deployment validation shares the same types between segments and destination instances
  deploymentTests: CanDeployModelsQuery["canDeploySegments"] | undefined;
  deplomentTestsLoading: boolean;

  deployResource: (
    resourceId: string,
    targetWorkspaceId: string,
    additionalOptions: Record<string, unknown>,
  ) => Promise<{ success: boolean; resourceId?: string }>;
  isDeploying: boolean;

  targetWorkspaces: TargetWorkspace[];
  targetWorkspacesLoading: boolean;

  linkableResources: LinkableWorkspaceResourcesQuery["getLinkableResources"] | undefined;
  linkableResourcesLoading: boolean;

  getDeploymentDiff: (deployment: Deployment, sourceObj: any, targetObj: any) => DeploymentDiff[];

  onClose: () => void;
}

export const DeploymentWizard: FC<Readonly<DeploymentWizardProps>> = ({
  deployment,
  deployResource,
  deploymentTests,
  isDeploying,
  targetWorkspaces,
  targetWorkspacesLoading,
  linkableResources,
  linkableResourcesLoading,
  getDeploymentDiff,
  onClose,
}) => {
  const { workspace } = useUser();
  const [step, setStep] = useWizardStepper(0);

  const [deploymentConsent, setDeploymentConsent] = useState(false);
  const [deploymentDiff, setDeploymentDiff] = useState<DeploymentDiff[]>();
  const [targetWorkspaceId, setTargetWorkspaceId] = useState<string>();
  const [deploymentResults, setDeploymentResults] = useState<{ success: boolean; resourceId?: string }>();
  const [additionalOptions, setAdditionalOptions] = useState<{ [key: string]: unknown }>({});

  const targetDeploymentDetails: TargetDeploymentDetails | undefined = useMemo(() => {
    const test = deploymentTests?.find((d) => String(d.targetWorkspaceId) === String(targetWorkspaceId));
    const targetWorkspace = targetWorkspaces.find((workspace) => String(workspace.id) === String(targetWorkspaceId));

    if (!test || !targetWorkspace) {
      return undefined;
    }
    return {
      test,
      targetWorkspace,
    };
  }, [deploymentTests, targetWorkspaces, targetWorkspaceId]);

  const isSuccessfulDeploymentTest = targetDeploymentDetails?.test.__typename === "SuccessfulDeploymentTestResult";
  const resourceName = deployment.resourceName.toLowerCase();

  const onCancel = () => {
    setDeploymentDiff(undefined);
    onClose();
  };

  const onDeploy = async () => {
    try {
      if (!targetDeploymentDetails) {
        return;
      }
      const deploymentResults = await deployResource(
        deployment.sourceResourceId,
        targetDeploymentDetails.targetWorkspace.id || "",
        additionalOptions,
      );

      if (deploymentResults.success) {
        setDeploymentResults(deploymentResults);
      }
    } catch (e) {
      setDeploymentResults({ success: false });
    }
  };

  useEffect(() => {
    if (targetWorkspaceId === undefined && deploymentTests?.length) {
      let deployableTest;
      let deployableTestDiff;
      for (const t of deploymentTests) {
        if (t.__typename !== "SuccessfulDeploymentTestResult") {
          continue;
        }
        const testDiff = getDeploymentDiff(deployment, t.sourceObj, t.targetObj);
        if (t?.type === DeploymentOperation.Create || (t?.type === DeploymentOperation.Update && testDiff.length > 0)) {
          deployableTest = t;
          deployableTestDiff = testDiff;
        }
      }

      if (deployableTest) {
        setTargetWorkspaceId(String(deployableTest?.targetWorkspaceId));
        setDeploymentDiff(deployableTestDiff);
      }
    }

    if (targetWorkspaceId) {
      // TODO(ernest): handle case where deployment is no longer valid
    }
  }, [linkableResources, deploymentTests]);

  const steps: SimpleWizardStep[] = [
    {
      title: "Select target workspace",
      render: () => (
        <Column gap={4}>
          <Paragraph>
            Deployments allows you to create and update this {resourceName} in another workspace. If you create a new{" "}
            {resourceName} in the target workspace via deployments, the two {resourceName}s will be permanently linked. If you
            want to link to an existing {resourceName} in your target workspace, please contact our team.
          </Paragraph>
          {deploymentTests?.length ? (
            <RadioGroup
              orientation="vertical"
              value={targetDeploymentDetails?.targetWorkspace.id}
              onChange={setTargetWorkspaceId}
            >
              {deploymentTests?.map((test, idx) => {
                const targetWorkspace = targetWorkspaces[idx];
                if (!targetWorkspace) {
                  return null;
                }
                return (
                  <DeploymentTargetRadio
                    key={targetWorkspace.id}
                    linkableResources={linkableResources}
                    loading={linkableResourcesLoading}
                    isSelected={targetDeploymentDetails?.targetWorkspace.id === targetWorkspace.id}
                    getDeploymentDiff={getDeploymentDiff}
                    deploymentTest={test}
                    deployment={deployment}
                    targetWorkspace={targetWorkspace}
                  />
                );
              })}
            </RadioGroup>
          ) : (
            <EmptyState
              title={`No ${resourceName}s to deploy to`}
              message="Deployments work when sources and destinations are linked across workspaces."
            />
          )}
        </Column>
      ),
      disabled: !isSuccessfulDeploymentTest,
    },
    {
      title: `Review ${resourceName} changes`,
      continueLabel: "Deploy",
      onContinue: () => {
        onDeploy();
        setStep((step) => step + 1);
      },
      disabled: !deploymentConsent,
      render: () => {
        if (targetDeploymentDetails?.test?.__typename !== "SuccessfulDeploymentTestResult") {
          return null;
        }
        if (isDeploying) {
          <Column justifyContent="center">
            <Spinner size="lg" />
          </Column>;
        }

        const deploymentType = targetDeploymentDetails?.test.type;

        return (
          <Column justifyContent="space-between" gap={8}>
            <Text>
              You are about to deploy a {resourceName} resource from <Text fontWeight="semibold">{workspace?.name}</Text> to{" "}
              <Text fontWeight="semibold">{targetDeploymentDetails?.targetWorkspace.name}</Text>.
            </Text>
            <Column gap={2}>
              <Text size="lg" fontWeight="medium">
                Review {resourceName} changes from {workspace?.name} →{" "}
                {targetWorkspaces?.find((w) => String(w.id) === String(targetWorkspaceId))?.name}
              </Text>
              {deploymentType === DeploymentOperation.Create ? (
                <Text> Create new {resourceName}</Text>
              ) : (
                <DeploymentComparison diffs={deploymentDiff} />
              )}
            </Column>
            <Column gap={2}>
              <Text size="lg" fontWeight="medium">
                Confirm actions:
              </Text>

              <Checkbox
                isChecked={deploymentConsent}
                onChange={(e) => setDeploymentConsent(Boolean(e.target.checked))}
                label={`${deploymentType === DeploymentOperation.Create ? "Create" : "Update"} this ${resourceName} in ${
                  targetDeploymentDetails?.targetWorkspace.name
                }`}
              />
              {deployment.resourceType === "destination_instances" && (
                <Checkbox
                  isChecked={Boolean(additionalOptions.copySchedule)}
                  onChange={(e) => setAdditionalOptions((o) => ({ ...o, copySchedule: Boolean(e.target.checked) }))}
                  label={`Set the schedule of this ${resourceName} in ${targetDeploymentDetails?.targetWorkspace.name}`}
                />
              )}
            </Column>
          </Column>
        );
      },
    },
    {
      title: "Deploy",
      continueLabel: "Done",
      disabled: !deploymentConsent,
      previousHidden: true,
      render: () => {
        const targetWorkspace = targetWorkspaces?.find(
          (w) => String(w.id) === String(targetDeploymentDetails?.targetWorkspace.id),
        );
        const { success, resourceId } = deploymentResults || {};
        return (
          <Column justifyContent="center" width="100%" height="100%" alignItems="center" gap={4}>
            {isDeploying ? (
              <>
                <Spinner size="lg" />
                <Text color="text.secondary" size="lg">
                  Deploying {resourceName} to {targetDeploymentDetails?.targetWorkspace.name}
                </Text>
              </>
            ) : success ? (
              <>
                <Box sx={{ "& > svg": { width: "var(--chakra-sizes-12)", height: "var(--chakra-sizes-12)" } }}>
                  <CheckIcon color="success.base" />
                </Box>
                <Text size="lg" fontWeight="medium">
                  Deployed {resourceName} to {targetDeploymentDetails?.targetWorkspace.name}
                </Text>
                <LinkButton
                  href={`${import.meta.env.VITE_APP_BASE_URL}/${targetWorkspace?.slug}/${resourceName}s/${resourceId}`}
                >
                  View {resourceName}
                </LinkButton>
              </>
            ) : (
              <>
                <Box sx={{ "& > svg": { width: "var(--chakra-sizes-12)", height: "var(--chakra-sizes-12)" } }}>
                  <ErrorIcon color="danger.base" />
                </Box>
                <Text size="lg" fontWeight="medium">
                  Failed to deploy {resourceName} to {targetDeploymentDetails?.targetWorkspace.name}
                </Text>
              </>
            )}
          </Column>
        );
      },
    },
  ];

  if (targetWorkspacesLoading) {
    return <Spinner></Spinner>;
  }

  return (
    <SimpleWizard
      steps={steps}
      step={step}
      setStep={setStep}
      title={`Deploy ${resourceName}`}
      onCancel={onCancel}
      onSubmit={() => {
        onClose();
      }}
    />
  );
};
