import { FC, useState } from "react";

import { AudienceIcon, Box, Column, GlobeIcon, ModelIcon, Row, Spinner, Text } from "@hightouchio/ui";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { ResourcePermissionGrant, useUpdateFoldersMutation } from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";

import { PermissionedButton } from "../permissioned-button";
import { AddFolder } from "./add-folder";
import { IndividualFolder } from "./folder";
import { Folder, FolderType, FolderViewType } from "./types";

interface FoldersProps {
  folders: Folder[];
  loading: boolean;
  selectedFolder: Folder | null;
  setSelectedFolder: (folder: string | null) => void;
  rootFolder: FolderType | undefined;
  setRootFolder: (type: FolderType | undefined) => void;
  viewType: FolderViewType;
  modelsRootName?: string;
  audiencesRootName?: string;
  refetchFolders: () => void;
  activeSearch?: boolean;
  clearFolderType?: () => void;
  modelCount?: number;
  audienceCount?: number;
  totalCount?: number;
}

export const Folders: FC<FoldersProps> = ({
  folders,
  loading,
  rootFolder,
  selectedFolder,
  setSelectedFolder,
  setRootFolder,
  viewType,
  modelsRootName,
  audiencesRootName,
  refetchFolders,
  activeSearch,
  clearFolderType,
  modelCount,
  audienceCount,
  totalCount,
}) => {
  const [addFolderOpen, setAddFolderOpen] = useState(false);

  return (
    <DndProvider backend={HTML5Backend}>
      <Column>
        <Row align="center" height={9} justify="space-between" pl={2}>
          <Text fontWeight="semibold" size="sm" textTransform="uppercase" color="text.secondary">
            Folders
          </Text>
          <PermissionedButton
            permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}
            size="sm"
            onClick={() => setAddFolderOpen(true)}
          >
            New folder
          </PermissionedButton>
        </Row>

        {viewType === "syncs" && (
          <IndividualFolder
            count={totalCount}
            depth={-1}
            icon={
              <Box
                as={GlobeIcon}
                color={
                  !rootFolder && selectedFolder?.type === undefined ? "var(--chakra-colors-primary-pressed)" : "text.tertiary"
                }
                height="20px"
                width="20px"
              />
            }
            isSelected={!rootFolder && selectedFolder?.type === undefined}
            name={activeSearch ? "Search results" : "All syncs"}
            setSelectedFolder={setSelectedFolder}
            onClick={() => clearFolderType && clearFolderType()}
          />
        )}

        {modelsRootName && (
          <RootFolder
            folders={folders.filter((folder) => folder.type === "models")}
            loading={loading}
            folderType="models"
            icon={<Box as={ModelIcon} height="20px" width="20px" />}
            name={modelsRootName}
            refetchFolders={refetchFolders}
            rootCount={modelCount}
            rootFolder={rootFolder}
            selectedFolder={selectedFolder}
            setRootFolder={setRootFolder}
            setSelectedFolder={setSelectedFolder}
          />
        )}
        {audiencesRootName && (
          <RootFolder
            folders={folders.filter((folder) => folder.type === "audiences")}
            loading={loading}
            folderType="audiences"
            icon={<Box as={AudienceIcon} height="20px" width="20px" />}
            name={audiencesRootName}
            refetchFolders={refetchFolders}
            rootCount={audienceCount}
            rootFolder={rootFolder}
            selectedFolder={selectedFolder}
            setRootFolder={setRootFolder}
            setSelectedFolder={setSelectedFolder}
          />
        )}
      </Column>
      {addFolderOpen && (
        <AddFolder
          folderType={rootFolder || "models"}
          toggleDisabled={viewType !== "syncs" || !audiencesRootName}
          viewType={viewType}
          onSave={(folder) => {
            setSelectedFolder(folder);
          }}
          onClose={() => {
            setAddFolderOpen(false);
          }}
        />
      )}
    </DndProvider>
  );
};

const RootFolder: FC<{
  name: string;
  folders: Folder[];
  loading: boolean;
  setSelectedFolder: (folder: string | null) => void;
  setRootFolder: (folder: FolderType | undefined) => void;
  rootFolder: FolderType | undefined;
  selectedFolder: Folder | null;
  folderType: FolderType;
  refetchFolders: () => void;
  rootCount: number | undefined;
  icon: JSX.Element;
}> = ({
  name,
  folders,
  loading,
  rootFolder,
  setSelectedFolder,
  selectedFolder,
  folderType,
  setRootFolder,
  refetchFolders,
  rootCount,
  icon,
}) => {
  const [_isOpen, _setIsOpen] = useState(true);
  const isSelected = folderType === rootFolder;
  const isChildSelected = Boolean(selectedFolder && selectedFolder.type === folderType);

  const isOpen = _isOpen || isChildSelected;
  const setIsOpen = (isOpen: boolean) => {
    if (isChildSelected && !isOpen) {
      setSelectedFolder(null);
    }
    _setIsOpen(isOpen);
  };

  const isRootSelected = isSelected && !selectedFolder;

  return (
    <Column>
      <IndividualFolder
        count={rootCount}
        depth={-1}
        icon={icon}
        isOpen={isOpen}
        isSelected={isRootSelected}
        name={name}
        setIsOpen={setIsOpen}
        setSelectedFolder={setSelectedFolder}
        onClick={() => {
          setRootFolder(folderType);
          setSelectedFolder(null);

          if (isRootSelected) {
            setIsOpen(!isOpen);
          } else {
            setIsOpen(true);
          }
        }}
      />
      {loading && isOpen && <Spinner ml={12} my={1} size="sm" />}
      {!loading &&
        isOpen &&
        (!folders?.length ? (
          <Row align="center" pl={`${4 * 13}px`} py={1}>
            <Text color="text.secondary" size="sm">
              No folders
            </Text>
          </Row>
        ) : (
          folders.map((folder) => (
            <DraggableFolder
              key={folder.id}
              folder={folder}
              refetchFolders={refetchFolders}
              rootFolder={rootFolder}
              selectedFolder={selectedFolder}
              setSelectedFolder={(folder) => {
                setSelectedFolder(folder);
                setRootFolder(folderType);
              }}
            />
          ))
        ))}
    </Column>
  );
};

export const isChild = (folder: Folder, maybeChild: Folder): boolean => {
  return (
    maybeChild.id === folder.id || maybeChild.parentId === folder.id || folder.children.some((f) => isChild(f, maybeChild))
  );
};

const DraggableFolder = ({
  folder,
  setSelectedFolder,
  selectedFolder,
  refetchFolders,
  rootFolder,
  parentFolder,
}: {
  folder: Folder;
  setSelectedFolder: (folder: string | null) => void;
  selectedFolder: Folder | null;
  refetchFolders: () => void;
  rootFolder?: FolderType;
  parentFolder?: Folder | undefined;
}) => {
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "workspace", grants: [ResourcePermissionGrant.Update] },
  ]);
  const [_isOpen, _setIsOpen] = useState(false);
  const isChildSelected = Boolean(selectedFolder && isChild(folder, selectedFolder) && folder.id !== selectedFolder.id);

  const isOpen = _isOpen || isChildSelected;
  const setIsOpen = (isOpen: boolean) => {
    if (isChildSelected && !isOpen) {
      setSelectedFolder(folder.id);
    }
    _setIsOpen(isOpen);
  };

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: "folder",
      item: { folder },
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
      }),
    }),
    [],
  );
  const { mutateAsync: updateFolder } = useUpdateFoldersMutation();
  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: "folder",
    drop: async (item: { folder: Folder }, monitor) => {
      const didDrop = monitor.didDrop();
      const oldParent = item.folder?.parentId;
      // Only process the drop once the highest level drop target has handled it.
      if (didDrop) {
        return;
      }

      // Don't do anything if the folder is dropped on its parent.
      if (folder.id === oldParent) {
        return;
      }

      userCanUpdate &&
        (await updateFolder({
          ids: [item.folder.id],
          object: {
            parent_id: folder.id,
          },
        }));
      refetchFolders();
    },
    canDrop: (item: { folder: Folder }) => {
      if (!userCanUpdate) {
        return false;
      }
      const newParent = folder.id;

      // Don't do anything if the folder is dropped on itself.
      if (newParent === item.folder.id) {
        return false;
      }

      // We can't assign a folder to a current child; that would create a circular reference.
      if (isChild(item.folder, folder)) {
        return false;
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isSelected = selectedFolder?.id === folder.id;

  return (
    <div ref={drop}>
      <div ref={dragRef}>
        <Column key={folder.id} style={{ opacity }}>
          <IndividualFolder
            bg={isOver && canDrop ? "gray.200" : undefined}
            count={folder.count}
            depth={folder.depth - 1}
            folder={folder}
            isOpen={isOpen}
            isSelected={isSelected}
            name={folder.name}
            parentFolder={parentFolder}
            setIsOpen={setIsOpen}
            setSelectedFolder={setSelectedFolder}
            onClick={() => {
              setSelectedFolder(folder.id);

              if (isSelected) {
                setIsOpen(!isOpen);
              } else {
                setIsOpen(true);
              }
            }}
          />
          {isOpen && (
            <Column sx={{ width: "100%" }}>
              {folder.children.map((childFolder) => (
                <DraggableFolder
                  key={childFolder.id}
                  folder={childFolder}
                  parentFolder={folder}
                  refetchFolders={refetchFolders}
                  rootFolder={rootFolder}
                  selectedFolder={selectedFolder}
                  setSelectedFolder={setSelectedFolder}
                />
              ))}
            </Column>
          )}
        </Column>
      </div>
    </div>
  );
};
