import { FC } from "react";

import { ViewColumnsIcon } from "@heroicons/react/24/outline";
import { capitalize } from "lodash";

import { useUser } from "src/contexts/user-context";
import { useCanDeployModelsQuery, useDeployModelMutation, useLinkableWorkspaceResourcesQuery } from "src/graphql";
import { ResourceActivityDiffer } from "src/hooks/use-resource-activity";
import { modelActivityMappers } from "src/pages/models/model-activity";

import { ResourceActivityMapper } from "../resource-activity/timeline";
import { Deployment } from "./common";
import { DeploymentWizard } from "./deployment-wizard";
import { getDeploymentDiff } from "./diff";

const modelDeploymentDiffer = ResourceActivityDiffer(["connection_id", "workspace_id", "model_id", "segment_id"], ["name"]);

const modelDeploymentDiffMappers: ResourceActivityMapper[] = [
  ...modelActivityMappers,
  {
    accessor: "columns",
    parser: (_, { parsedDiff }) => {
      if (parsedDiff.type !== "array") {
        return null;
      }

      const changes: string[] = [];
      for (const column of parsedDiff.array) {
        changes.push(`${capitalize(column.operation)} \`${column.value.name}\``);
      }
      return {
        message: `updated columns`,
        changes,
        icon: <ViewColumnsIcon />,
      };
    },
  },
];

const getModelDeploymentDiff = getDeploymentDiff(modelDeploymentDiffer, modelDeploymentDiffMappers);

export const ModelDeploymentWizard: FC<
  Readonly<{
    deployment: Deployment<{ connectionId: string }>;
    onClose: () => void;
  }>
> = ({ deployment, onClose }) => {
  const { workspaces, user, isLoading: userLoading } = useUser();
  const { mutateAsync: deploy, isLoading: deploying } = useDeployModelMutation();
  const targetWorkspaces = workspaces?.filter((workspace) => String(workspace.id) !== String(user?.current_workspace_id)) || [];

  const { data: deploymentTests, isLoading: deploymentTestsLoading } = useCanDeployModelsQuery(
    {
      targetWorkspaceIds: targetWorkspaces?.map((workspace) => workspace.id) || [],
      sourceResourceId: deployment.sourceResourceId,
    },
    {
      enabled: !userLoading,
      refetchOnMount: "always",
      select: (data) => data.canDeploySegments,
    },
  );

  const { data: linkableResources, isLoading: linkableResourcesLoading } = useLinkableWorkspaceResourcesQuery(
    {
      sourceResourceId: deployment.metadata?.connectionId || "",
      resourceType: "connections",
    },
    { select: (data) => data.getLinkableResources },
  );

  return (
    <DeploymentWizard
      onClose={onClose}
      deplomentTestsLoading={deploymentTestsLoading}
      isDeploying={deploying}
      getDeploymentDiff={getModelDeploymentDiff}
      linkableResources={linkableResources}
      linkableResourcesLoading={linkableResourcesLoading}
      deployResource={async (resourceId, targetWorkspaceId) => {
        const { deployModel } = await deploy({
          modelId: resourceId,
          targetWorkspaceId,
        });

        const success = deployModel.__typename === "SuccessfulModelDeploymentResult";
        return {
          success,
          resourceId: success ? deployModel.deployedResourceId : undefined,
        };
      }}
      deployment={deployment}
      deploymentTests={deploymentTests}
      targetWorkspaces={targetWorkspaces}
      targetWorkspacesLoading={userLoading}
    />
  );
};
