import { FC, useEffect, useState } from "react";

import { Portal } from "@chakra-ui/react";
import { ArrowTopRightOnSquareIcon, TrashIcon, ViewfinderCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  Alert,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Column,
  ConfirmationDialog,
  EditableDescription,
  EditableHeading,
  FormField,
  IconButton,
  Link,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Paragraph,
  Row,
  SectionHeading,
  Tab,
  TabList,
  Tabs,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";
import pluralize from "pluralize";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { ColumnSettings } from "src/components/audiences/column-settings";
import { ParentTraits } from "src/components/audiences/parent-traits";
import { getSchemaModelType, getSchemaModelTypeNames } from "src/components/audiences/utils";
import { DetailBar } from "src/components/detail-bar";
import { SaveWarning } from "src/components/modals/save-warning";
import { ColumnSelect } from "src/components/models/column-select";
import { PrimaryKeyDialog } from "src/components/models/primary-key-dialog";
import { Query } from "src/components/models/query";
import { Permission } from "src/components/permission";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { DisplaySlug } from "src/components/slug/display-slug";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  ObjectQuery,
  ResourcePermissionGrant,
  useDeleteObjectMutation,
  useUpdateAudiencesByParentIdMutation,
  useUpdateEventMutation,
  useUpdateObjectMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { parentModelActivityMappers } from "src/pages/audiences/setup/parent-model-activity";
import { modelColumnIgnoreFields, modelColumnMappers } from "src/pages/models/model-activity";
import { getParams } from "src/pages/schema/utils";
import { QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { isTimestampColumn } from "src/utils/models";
import { formatDate } from "src/utils/time";

import { MatchBoostingSettingsWrapper } from "./match-boosting";
import { Relationships } from "./relationships/relationships";

type SchemaObject = NonNullable<ObjectQuery["segments_by_pk"]>;

const rootPath = "/schema-v2/view";

export const SchemaObject: FC<Readonly<{ object: SchemaObject; audienceCount: number | undefined }>> = ({
  object,
  audienceCount,
}) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const type = getSchemaModelType(object);
  const { typeName, typePath } = getSchemaModelTypeNames(type);
  const { appMatchBoosting } = useFlags();

  const [name, setName] = useState(object.name ?? "");
  const [description, setDescription] = useState(object.description ?? "");
  const [deleteQuery, setDeleteQuery] = useState(false);

  const queryType = object.query_type as QueryType;
  const columns = object.columns;
  const dbtColumns = object.query_dbt_model?.data?.columns;
  const source = object.connection;

  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: deleteObject } = useDeleteObjectMutation();

  const trackUpdate = () => {
    analytics.track("Model Updated", {
      model_id: object.id,
      model_type: queryType,
      model_name: object.name,
      source_id: source?.id,
      source_type: source?.type,
    });
  };

  const updatePermission = useHasPermission([{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]);

  const saveName = async () => {
    try {
      await updateObject({
        id: object.id,
        input: {
          name,
        },
      });
      toast({
        id: `update-name`,
        title: `Name was updated`,
        variant: "success",
      });

      trackUpdate();
    } catch (e) {
      toast({
        id: "update-name-error",
        title: "Failed to update name",
        message: e.message,
        variant: "error",
      });
    }
  };

  const saveDescription = async () => {
    try {
      await updateObject({
        id: object.id,
        input: {
          description,
        },
      });
    } catch (e) {
      toast({
        id: "update-description-error",
        title: "Failed to update description",
        message: e.message,
        variant: "error",
      });
    }

    trackUpdate();
  };

  useEffect(() => {
    setName(object.name ?? "");
    setDescription(object.description ?? "");
  }, [object]);

  const hasPrimaryKeyIssue = Boolean(
    type === SchemaModelType.Parent && object.columns?.length && !object.columns.some((c) => c.name === object.primary_key),
  );

  const updatedByUsername = object.updated_by_user?.name || object.created_by_user?.name;
  const tabRoutes = [
    { label: "Query", path: `query` },
    { label: "Relationships", path: `relationships` },
    { label: "Columns", path: "columns" },
    type === SchemaModelType.Parent && { label: "Traits", path: `traits` },
    type === SchemaModelType.Parent && { label: "Activity", path: `activity` },
    type === SchemaModelType.Parent && appMatchBoosting && { label: "Match booster", path: `match-booster` },
  ]
    .filter(Boolean)
    .map((obj: any) => ({ ...obj, path: `${rootPath}/${obj.path}` }));

  return (
    <Routes>
      <Route
        element={
          <PermissionProvider permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]}>
            <Column py={4} px={6}>
              <Row align="center" justify="space-between" gap={8}>
                <EditableHeading
                  isDisabled={!updatePermission.hasPermission}
                  size="lg"
                  value={name}
                  onChange={setName}
                  onSubmit={saveName}
                />
                <ButtonGroup>
                  {type === SchemaModelType.Parent && (
                    <Tooltip message="View model schema">
                      <IconButton
                        aria-label="View parent model graph"
                        variant="secondary"
                        icon={ViewfinderCircleIcon}
                        onClick={() => {
                          const { source } = getParams();
                          navigate(`/schema-v2?source=${source}&parent=${object.id}`);
                        }}
                      />
                    </Tooltip>
                  )}
                  <Permission permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Delete] }]}>
                    <Menu>
                      <MenuActionsButton variant="secondary" />
                      <Portal>
                        <MenuList>
                          <MenuItem
                            icon={TrashIcon}
                            variant="danger"
                            onClick={() => {
                              setDeleteQuery(true);
                            }}
                          >
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Portal>
                    </Menu>
                  </Permission>
                  <IconButton
                    aria-label="Close"
                    icon={XMarkIcon}
                    onClick={() => {
                      const { queryString } = getParams();
                      navigate(`/schema-v2${queryString}`);
                    }}
                  />
                </ButtonGroup>
              </Row>
              <EditableDescription value={description} onChange={setDescription} onSubmit={saveDescription} />
              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Last updated:</Text>
                  <Row gap={1} align="center">
                    {formatDate((object.updated_at || object.created_at)!)}
                    {updatedByUsername && (
                      <>
                        <Text>by</Text>
                        <Avatar size="xs" name={updatedByUsername} />
                      </>
                    )}
                  </Row>
                </Row>
                <Row align="center" gap={2} flexShrink={0} overflow="hidden">
                  <Text>Slug:</Text>
                  <DisplaySlug currentSlug={object.slug} />
                </Row>
                {Boolean(audienceCount) && (
                  <Row align="center" flexShrink={0}>
                    <Link href={`/audiences?parent=${object.id}`}>
                      <Row align="center" gap={2}>
                        {audienceCount} {pluralize("audience", audienceCount)}{" "}
                        <ArrowTopRightOnSquareIcon height="14px" strokeWidth="2px" />
                      </Row>
                    </Link>
                  </Row>
                )}
              </DetailBar>
            </Column>

            <Tabs
              index={tabRoutes.findIndex(({ path }) => location.pathname.startsWith(path))}
              onChange={(index) => {
                navigate({ pathname: tabRoutes[index].path!, search: location.search });
              }}
            >
              <Box as={TabList} px={2}>
                {tabRoutes.map((tab) => (
                  <Tab key={tab.path}>{tab.label}</Tab>
                ))}
              </Box>
            </Tabs>
            {hasPrimaryKeyIssue && (
              <Alert
                type="warning"
                title="Undefined primary key"
                message="Without a primary key your syncs may fail or undefined behavior may occur. Check that your primary key is set to a valid column."
              />
            )}
            <Column flex={1} minHeight={0}>
              <Outlet />
            </Column>

            <ConfirmationDialog
              confirmButtonText={`Delete ${typeName}`}
              isOpen={deleteQuery}
              title={`Delete ${typeName}`}
              variant="danger"
              onClose={() => {
                setDeleteQuery(false);
              }}
              onConfirm={async () => {
                await deleteObject({
                  id: object.id,
                });
                analytics.track("Model Deleted", {
                  model_id: object.id,
                  model_type: queryType,
                  model_name: object.name,
                  source_id: source?.id,
                  source_type: source?.type,
                });
                toast({
                  id: `delete-${typePath}`,
                  title: `${capitalize(typeName)} was deleted`,
                  variant: "success",
                });
                const { queryString } = getParams();
                navigate(`/schema-v2${queryString}`);
              }}
            >
              <Paragraph>Are you sure you want to delete this {typeName}? You won't be able to undo this.</Paragraph>
            </ConfirmationDialog>
          </PermissionProvider>
        }
      >
        <Route path="/" index element={<Navigate to={{ pathname: "query", search: location.search }} replace />} />
        <Route path="/query" element={<QueryTab object={object} />} />
        <Route
          path="/relationships/*"
          element={<Relationships type={type} modelId={String(object.id)} sourceId={String(object.connection?.id)} />}
        />
        <Route
          path="/columns"
          element={
            <Column p={6} overflow="auto">
              <ColumnSettings columns={columns} dbtColumns={dbtColumns} modelId={object.id} source={source} />
            </Column>
          }
        />
        {type === SchemaModelType.Parent && (
          <Route
            path="/traits"
            element={
              <Column p={6} overflow="auto">
                <ParentTraits model={object} />
              </Column>
            }
          />
        )}
        {type === SchemaModelType.Parent && (
          <Route
            path="/activity"
            element={
              <Column p={6} overflow="auto">
                <ResourceActivityTimeline
                  primaryResource={{
                    mappers: parentModelActivityMappers,
                    resourceNameOverride: "Parent Model",
                    resource: "Audience Schema",
                    resourceId: String(object.id),
                  }}
                  relatedResources={[
                    {
                      mappers: modelColumnMappers,
                      resource: "Model Column",
                      resourceNameOverride: "Model column",
                      resourceId: `model_id:${object.id}:%`,
                      ignoreColumns: modelColumnIgnoreFields,
                    },
                  ]}
                />
              </Column>
            }
          />
        )}
        {type === SchemaModelType.Parent && appMatchBoosting && (
          <Route
            path="/match-booster"
            element={<MatchBoostingSettingsWrapper columns={columns} model={object} segmentType="schema" />}
          />
        )}
      </Route>
    </Routes>
  );
};

const QueryTab: FC<Readonly<{ object: SchemaObject }>> = ({ object }) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const updatePermission = useHasPermission([{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]);

  const type = getSchemaModelType(object);
  const { typePath } = getSchemaModelTypeNames(type);

  const [updatingPrimaryKey, setUpdatingPrimaryKey] = useState(false);
  const [updatingTimestamp, setUpdatingTimestamp] = useState(false);
  const [updatingPrimaryLabel, setUpdatingPrimaryLabel] = useState(false);
  const [updatingSecondaryLabel, setUpdatingSecondaryLabel] = useState(false);
  const [primaryKey, setPrimaryKey] = useState<string | undefined | null>();
  const [timestampColumn, setTimestampColumn] = useState<string | undefined | null>();
  const [primaryLabel, setPrimaryLabel] = useState<string | undefined | null>();
  const [secondaryLabel, setSecondaryLabel] = useState<string | undefined | null>();
  const [newPrimaryKey, setNewPrimaryKey] = useState<string | undefined>();

  const isDirty =
    object.event?.timestamp_column !== timestampColumn ||
    object.primary_key !== primaryKey ||
    object.visual_query_primary_label !== primaryLabel ||
    object.visual_query_secondary_label !== secondaryLabel;

  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: updateEvent } = useUpdateEventMutation();
  const { mutateAsync: updateAudiencesByParentId } = useUpdateAudiencesByParentIdMutation();

  const updatePrimaryKey = async (value: string) => {
    setUpdatingPrimaryKey(true);
    setPrimaryKey(value);
    const oldPrimaryKey = object.primary_key;
    try {
      await updateObject({
        id: object.id,
        input: {
          primary_key: value,
          visual_query_primary_label: primaryLabel,
          visual_query_secondary_label: secondaryLabel,
        },
      });
      if (type === SchemaModelType.Parent) {
        // Update the primary key for all the audiences associated with this parent model
        await updateAudiencesByParentId({
          parent_id: object.id,
          input: {
            primary_key: value,
          },
        });
      }
      toast({
        id: "update-primary-key",
        title: "Primary key updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-key-error",
        title: "Failed to update primary key",
        message: e.message,
        variant: "error",
      });
      setPrimaryKey(oldPrimaryKey ?? "");
    }
    setUpdatingPrimaryKey(false);
  };

  const updatePrimaryLabel = async (value: string) => {
    setUpdatingPrimaryLabel(true);
    setPrimaryLabel(value);
    const oldPrimaryLabel = object.visual_query_primary_label;
    try {
      await updateObject({
        id: object.id,
        input: {
          visual_query_primary_label: value,
        },
      });
      toast({
        id: "update-primary-key",
        title: "Primary label updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-label-error",
        title: "Failed to update primary label",
        message: e.message,
        variant: "error",
      });
      setPrimaryLabel(oldPrimaryLabel);
    }
    setUpdatingPrimaryLabel(false);
  };

  const updateSecondaryLabel = async (value: string) => {
    setUpdatingSecondaryLabel(true);
    setSecondaryLabel(value);
    const oldSecondaryLabel = object.visual_query_secondary_label;
    try {
      await updateObject({
        id: object.id,
        input: {
          visual_query_secondary_label: value,
        },
      });
      toast({
        id: "update-primary-key",
        title: "Primary label updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-label-error",
        title: "Failed to update primary label",
        message: e.message,
        variant: "error",
      });
      setSecondaryLabel(oldSecondaryLabel);
    }
    setUpdatingSecondaryLabel(false);
  };

  const updateTimestamp = async (value: string) => {
    setUpdatingTimestamp(true);
    setTimestampColumn(value);
    const oldTimestamp = object.event?.timestamp_column;
    try {
      await updateEvent({
        id: object.id,
        set: {
          timestamp_column: timestampColumn,
        },
      });
      toast({
        id: "update-timestamp",
        title: "Timestamp column updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-timestamp-error",
        title: "Failed to update timestamp column",
        message: e.message,
        variant: "error",
      });
      setTimestampColumn(oldTimestamp ?? "");
    }
    setUpdatingTimestamp(false);
  };

  useEffect(() => {
    setPrimaryKey(object.primary_key);
    setTimestampColumn(object.event?.timestamp_column);
    setPrimaryLabel(object.visual_query_primary_label);
    setSecondaryLabel(object.visual_query_secondary_label);
  }, [object]);

  return (
    <Column gap={8} p={6} overflow="auto" height="100%">
      <Column maxHeight="40vh" overflow="hidden" flexShrink={0}>
        <Query
          model={object}
          actions={
            <Permission>
              <Button onClick={() => navigate(`/schema/${typePath}/${object.id}/query`)}>Edit</Button>
            </Permission>
          }
        />
      </Column>

      {type !== SchemaModelType.Related && (
        <Column gap={6}>
          <SectionHeading>Configuration</SectionHeading>
          {type === SchemaModelType.Event && (
            <FormField label="Timestamp">
              <ColumnSelect
                isDisabled={!updatePermission.hasPermission}
                isLoading={updatingTimestamp}
                columns={object.columns?.filter(isTimestampColumn)}
                model={object}
                value={timestampColumn ?? ""}
                onChange={updateTimestamp}
              />
            </FormField>
          )}
          {type === SchemaModelType.Parent && (
            <>
              <FormField label="Primary key" tip="Key that is unique and consistent across queries. E.g. “id” or “email”">
                <ColumnSelect
                  isDisabled={!updatePermission.hasPermission}
                  isLoading={updatingPrimaryKey}
                  model={object}
                  value={newPrimaryKey ?? primaryKey ?? ""}
                  onChange={setNewPrimaryKey}
                />
              </FormField>
              <FormField label="Primary label" tip="Used when previewing results from an audience. E.g. “full_name”">
                <ColumnSelect
                  isDisabled={!updatePermission.hasPermission}
                  isLoading={updatingPrimaryLabel}
                  model={object}
                  value={primaryLabel ?? ""}
                  onChange={updatePrimaryLabel}
                />
              </FormField>
              <FormField label="Secondary label" tip="Used when previewing results from an audience. E.g. “email”">
                <ColumnSelect
                  isDisabled={!updatePermission.hasPermission}
                  isLoading={updatingSecondaryLabel}
                  model={object}
                  value={secondaryLabel ?? ""}
                  onChange={updateSecondaryLabel}
                />
              </FormField>
            </>
          )}
        </Column>
      )}
      <PrimaryKeyDialog
        oldPrimaryKey={primaryKey ?? undefined}
        newPrimaryKey={newPrimaryKey}
        onClose={() => {
          setNewPrimaryKey(undefined);
        }}
        onConfirm={async () => {
          if (newPrimaryKey) {
            await updatePrimaryKey(newPrimaryKey);
          }
        }}
      />
      <SaveWarning dirty={isDirty} />
    </Column>
  );
};
