import { useEffect, useState } from "react";

import { Brand, CreateSyncConfig, ViewSourceConfig } from "@hightouch/core/server/routes/partner/types";
import qs from "query-string";
import { Route, Routes } from "react-router-dom";

import { Layout } from "./layout";
import { Source } from "./source";

type Config = {
  brand: Brand;
  config: CreateSyncConfig | ViewSourceConfig;
};

export const Partner = () => {
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    const parsed = qs.parse(window.location.search) as Record<string, string>;
    if (parsed.config) {
      const json = JSON.parse(atob(parsed.config));
      setConfig(json);
    }
  }, []);

  if (!config) {
    return null;
  }

  return (
    <Routes>
      <Route element={<Layout logo={config.brand.logo} />}>
        <Route path="/sources/:id" element={<Source />} />
      </Route>
    </Routes>
  );
};
