import { FC, useEffect, useState } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Row, Column, useToast, Button, EditableHeading } from "@hightouchio/ui";
import { isEqual, omit, omitBy } from "lodash";
import { useParams } from "react-router-dom";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DeleteSourceWarning } from "src/components/modals/delete-source-warning";
import { SaveWarning } from "src/components/modals/save-warning";
import { SourceForm } from "src/components/sources/setup";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import { Warning } from "src/components/warning";
import { useUser } from "src/contexts/user-context";
import { ListSourceTestStepsQueryVariables, useSourceQuery, useUpdateSourceV2Mutation } from "src/graphql";
import { hideIntercom } from "src/lib/intercom";
import { Modal } from "src/ui/modal";
import { SourceConfig } from "src/utils/sources";

export const Source: FC = () => {
  const { id } = useParams<{ id: string }>();
  const { user } = useUser();
  const { toast } = useToast();
  const [name, setName] = useState("");
  const [config, setConfig] = useState<SourceConfig | undefined>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [lightningEnabled, setLightningEnabled] = useState<boolean | undefined>();
  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [testConnectionModalOpen, setTestConnectionModalOpen] = useState(false);

  const [deleteSource, setDeleteSource] = useState<boolean>(false);

  const { data: source, refetch } = useSourceQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.connections_by_pk, suspense: true },
  );

  const { results: testResults, steps: testSteps, getTestSteps, runTest, timeElapsed } = useSourceTesting();
  const { isLoading: updateLoading, mutateAsync: updateSource } = useUpdateSourceV2Mutation();

  useEffect(() => {
    hideIntercom();
  }, []);

  useEffect(() => {
    setName(source?.name ?? "");
    setConfig(source?.config ?? undefined);
    setTunnelId(source?.tunnel?.id ?? undefined);
    setCredentialId(source?.credential_id ?? undefined);
    setLightningEnabled(source?.plan_in_warehouse ?? undefined);
    setPlannerDatabase(source?.plan_in_warehouse_config?.plannerDatabase ?? undefined);
  }, [source]);

  if (!id) {
    return null;
  }

  if (!source) {
    return <Warning subtitle="It may have been deleted" title="Source not found" />;
  }

  const onUpdate = () => {
    toast({
      id: "update-source",
      title: "Source was updated",
      variant: "success",
    });
  };

  const saveName = async (name: string) => {
    await updateSource({
      id,
      object: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const save = async () => {
    let updatedConfig = config;

    if (tunnelId) {
      updatedConfig = {
        ...updatedConfig,
        host: null,
        server: null,
        port: null,
      };
    }

    await updateSource({
      id,
      object: {
        updated_by: user?.id != null ? String(user?.id) : undefined,
        tunnel_id: tunnelId ? tunnelId : null,
        credential_id: credentialId != null ? String(credentialId) : undefined,
        config: updatedConfig,
        plan_in_warehouse: lightningEnabled || undefined,
        plan_in_warehouse_config: plannerDatabase ? { plannerDatabase } : undefined,
      },
    });

    refetch();
  };

  const configChanged = !isEqual(
    config
      ? omit(
          omitBy(config, (v) => v === undefined),
          ["methodKey"],
        )
      : undefined,
    omit(source.config, ["methodKey"]) ?? undefined,
  );
  const tunnelChanged = !isEqual(tunnelId, source.tunnel?.id ?? undefined);
  const credentialChanged = !isEqual(credentialId, source.credential_id ?? undefined);
  const lightningEnabledChanged = !isEqual(lightningEnabled, source.plan_in_warehouse ?? undefined);
  const plannerDatabaseChanged = !isEqual(plannerDatabase, source.plan_in_warehouse_config?.plannerDatabase ?? undefined);

  const dirty = tunnelChanged || configChanged || credentialChanged || lightningEnabledChanged || plannerDatabaseChanged;
  const complete = tunnelId !== null;

  const variables: ListSourceTestStepsQueryVariables = {
    sourceType: source.definition?.type,
    sourceId: String(source.id),
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled ? { plannerDatabase } : undefined,
  };

  return (
    <>
      <Column flex={1} width="100%">
        <Column flex={1} maxW="800px" width="100%" mx="auto" p={6} gap={6}>
          <Row gap={4} align="center">
            <IntegrationIcon src={source.definition.icon} name={source.definition.name} />
            <EditableHeading size="lg" value={name} onChange={setName} onSubmit={saveName} />
          </Row>

          <Column flex={1}>
            {source.definition && (
              <SourceForm
                //Cheeky way to reset child componenents to initial state after save. (EX: Reseting senstive field edit state)
                key={`${updateLoading}`}
                hideSecret
                config={config}
                credentialId={credentialId}
                definition={source.definition}
                hasSetupLightning={Boolean(source.plan_in_warehouse)}
                isSetup={false}
                lightningEnabled={lightningEnabled}
                plannerDatabase={plannerDatabase}
                setConfig={setConfig}
                setCredentialId={setCredentialId}
                setLightningEnabled={setLightningEnabled}
                setPlannerDatabase={setPlannerDatabase}
                setTunnelId={setTunnelId}
                sourceId={id}
                tunnelId={tunnelId}
              />
            )}
          </Column>
        </Column>

        <Row borderTop="1px" borderColor="base.border" width="100%" mt={6}>
          <Row maxW="800px" width="100%" gap={4} p={6} mx="auto">
            <Button size="lg" variant="primary" isDisabled={!dirty || !complete} isLoading={updateLoading} onClick={save}>
              Save changes
            </Button>
            {!source.definition?.disableTest && config ? (
              <Button
                size="lg"
                isDisabled={!complete}
                onClick={async () => {
                  await getTestSteps(variables);
                  runTest(variables);
                  setTestConnectionModalOpen(true);
                }}
              >
                Test connection
              </Button>
            ) : null}
          </Row>
        </Row>
      </Column>

      <Modal
        footer={
          <>
            <Button
              variant={testResults?.success !== false && !dirty ? undefined : "secondary"}
              onClick={() => {
                setTestConnectionModalOpen(false);
              }}
            >
              Close
            </Button>
            {testResults?.success === false ? (
              <Button
                icon={ArrowPathIcon}
                onClick={() => {
                  runTest(variables);
                }}
              >
                Test again
              </Button>
            ) : dirty ? (
              <Button
                isDisabled={!testResults?.success}
                isLoading={updateLoading}
                onClick={async () => {
                  await save();
                  setTestConnectionModalOpen(false);
                }}
              >
                Save changes
              </Button>
            ) : null}
          </>
        }
        isOpen={testConnectionModalOpen}
        sx={{ maxWidth: "800px", width: "90%" }}
        title={`Test connection to ${source.definition?.name}`}
        onClose={() => {
          setTestConnectionModalOpen(false);
        }}
      >
        <Testing
          config={config}
          credentialId={credentialId}
          isSetup={false}
          plannerDatabase={plannerDatabase}
          results={testResults}
          sourceDefinition={source.definition}
          steps={testSteps}
          timeElapsed={timeElapsed}
        />
      </Modal>

      <SaveWarning dirty={dirty} />
      {deleteSource && <DeleteSourceWarning source={source} onClose={() => setDeleteSource(false)} />}
    </>
  );
};
