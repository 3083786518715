import { ReactElement } from "react";

import { ModelDraft } from "src/components/models/draft";
import { ResourcePermissionGrant } from "src/graphql";
import { ResourcePermission } from "src/hooks/use-has-permission";
import { Analytics } from "src/pages/analytics";
import { Audience } from "src/pages/audiences/audience";
import Audiences from "src/pages/audiences/audiences";
import { CloneAudience } from "src/pages/audiences/clone-audience";
import { CreateAudience } from "src/pages/audiences/create-audience";
import { CreateMetric } from "src/pages/audiences/metrics/create-metric";
import { Metric } from "src/pages/audiences/metrics/metric";
import { CreatePriorityList } from "src/pages/audiences/priority-lists/create-priority-list";
import { PriorityList } from "src/pages/audiences/priority-lists/priority-list";
import { PriorityLists } from "src/pages/audiences/priority-lists/priority-lists";
import { CreateAudienceObject } from "src/pages/audiences/setup/create-object";
import { CreateSyncTemplate } from "src/pages/audiences/setup/create-sync-template";
import { AudienceObject } from "src/pages/audiences/setup/object";
import { AudienceObjectQuery } from "src/pages/audiences/setup/object-query";
import { SetupAudiences } from "src/pages/audiences/setup/setup-audiences";
import { SyncTemplate } from "src/pages/audiences/setup/sync-template";
import { CreateDestination } from "src/pages/destinations/create-destination";
import { Destination } from "src/pages/destinations/destination";
import Destinations from "src/pages/destinations/destinations";
import { Alerting } from "src/pages/extensions/alerting";
import { DbtCloud } from "src/pages/extensions/dbt-cloud";
import { DbtModels } from "src/pages/extensions/dbt-models";
import { Extensions } from "src/pages/extensions/extensions";
import { Fivetran } from "src/pages/extensions/fivetran";
import { GitSync as GitSyncExtension } from "src/pages/extensions/git-sync";
import { Looker } from "src/pages/extensions/looker";
import { Monitoring } from "src/pages/extensions/monitoring";
import { Sigma } from "src/pages/extensions/sigma";
import { CloneModel } from "src/pages/models/clone-model";
import { CreateModel } from "src/pages/models/create-model";
import { ModelWrapper } from "src/pages/models/model";
import { ModelQueryWrapper } from "src/pages/models/model-query";
import Models from "src/pages/models/models";
import { SchemaPage } from "src/pages/schema";
import { CreateSequence } from "src/pages/sequences/create-sequence";
import { Sequence } from "src/pages/sequences/sequence";
import { Sequences } from "src/pages/sequences/sequences";
import { APIKeys } from "src/pages/settings/api-keys";
import { AuditLog } from "src/pages/settings/audit-log";
import { Billing } from "src/pages/settings/billing";
import { CloudProviders } from "src/pages/settings/cloud-providers";
import { Members } from "src/pages/settings/members";
import { Roles } from "src/pages/settings/roles";
import { SSO } from "src/pages/settings/sso";
import { Storage } from "src/pages/settings/storage";
import { Tunnels } from "src/pages/settings/tunnels";
import { Usage } from "src/pages/settings/usage";
import { VersionControl } from "src/pages/settings/version-control";
import { Workspace } from "src/pages/settings/workspace";
import { Setup } from "src/pages/setup";
import { CreateSource } from "src/pages/sources/create-source";
import { Source } from "src/pages/sources/source";
import Sources from "src/pages/sources/sources";
import { CreateSync } from "src/pages/syncs/create/create-sync";
import { RunDebug } from "src/pages/syncs/debug";
import { SyncDraft } from "src/pages/syncs/draft";
import { Run } from "src/pages/syncs/run";
import { SyncWrapper } from "src/pages/syncs/sync";
import Syncs from "src/pages/syncs/syncs";
import { Partner } from "src/partner";

export type RouteObject = {
  path: string;
  component: ReactElement;
  permissions?: ResourcePermission[];
  unauthorizedRedirect?: string;
  permanentRedirect?: string;
  analyticsData: { pageName: string };
  permissionPathParam?: string;
};

export const config: RouteObject[] = [
  { analyticsData: { pageName: "Onboarding Page" }, path: "onboarding/*", component: <Setup /> },
  {
    analyticsData: { pageName: "Clone Model Page" },
    path: "models/:model_id/clone",
    component: <CloneModel />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/models",
  },
  { analyticsData: { pageName: "Model Query Page" }, path: "models/:model_id/query", component: <ModelQueryWrapper /> },
  {
    analyticsData: { pageName: "New Model Page" },
    path: "models/new",
    component: <CreateModel />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/models",
  },
  { analyticsData: { pageName: "Model Details Page" }, path: "models/:model_id", component: <ModelWrapper /> },
  { analyticsData: { pageName: "Model Draft Page" }, path: "models/:model_id/draft", component: <ModelDraft /> },
  {
    analyticsData: { pageName: "Models Page" },
    path: "models",
    component: <Models />,
    permissions: [{ resource: "model", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/syncs",
  },
  {
    analyticsData: { pageName: "Sync Run Details Debugger Page" },
    path: "syncs/:sync_id/runs/:run_id/debug/:row_id",
    component: <RunDebug />,
    permissions: [{ resource: "sync", grants: [ResourcePermissionGrant.Debugger] }],
    permissionPathParam: "sync_id",
    unauthorizedRedirect: "/syncs/:sync_id",
  },
  {
    analyticsData: { pageName: "Sync Run Details Page" },
    path: "syncs/:sync_id/runs/:run_id",
    component: <Run />,
    permissions: [{ resource: "sync", grants: [ResourcePermissionGrant.Debugger] }],
    permissionPathParam: "sync_id",
    unauthorizedRedirect: "/syncs/:sync_id",
  },
  {
    analyticsData: { pageName: "New Sync Page" },
    path: "syncs/new",
    component: <CreateSync />,
    permissions: [{ resource: "sync", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/syncs",
  },
  { analyticsData: { pageName: "Sync Details Page" }, path: "syncs/:sync_id", component: <SyncWrapper /> },
  { analyticsData: { pageName: "Sync Draft Page" }, path: "syncs/:sync_id/draft", component: <SyncDraft /> },
  { analyticsData: { pageName: "Syncs Page" }, path: "syncs", component: <Syncs /> },

  {
    analyticsData: { pageName: "New Sequence Page" },
    path: "sequences/new",
    component: <CreateSequence />,
    permissions: [{ resource: "sync", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/sequences",
  },
  { analyticsData: { pageName: "Sequence Details Page" }, path: "sequences/:id", component: <Sequence /> },
  { analyticsData: { pageName: "Sequences Page" }, path: "sequences", component: <Sequences /> },

  {
    analyticsData: { pageName: "New Source Page" },
    path: "sources/new",
    component: <CreateSource />,
    permissions: [{ resource: "source", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/destinations",
  },
  { analyticsData: { pageName: "Source Details Page" }, path: "sources/:source_id", component: <Source /> },
  { analyticsData: { pageName: "Sources Page" }, path: "sources", component: <Sources /> },

  {
    analyticsData: { pageName: "New Destination Page" },
    path: "destinations/new",
    component: <CreateDestination />,
    permissions: [{ resource: "destination", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/destinations",
  },
  {
    analyticsData: { pageName: "Destination Details Page" },
    path: "destinations/:destination_id",
    component: <Destination />,
  },
  { analyticsData: { pageName: "Destinations Page" }, path: "destinations", component: <Destinations /> },

  {
    analyticsData: { pageName: "[Extensions] Monitoring Page" },
    path: "extensions/monitoring/*",
    component: <Monitoring />,
  },
  {
    analyticsData: { pageName: "[Extensions] dbt Cloud Page" },
    path: "extensions/dbt-cloud/*",
    component: <DbtCloud />,
  },
  {
    analyticsData: { pageName: "[Extensions] dbt Models Page" },
    path: "extensions/dbt-models/*",
    component: <DbtModels />,
  },
  {
    analyticsData: { pageName: "[Extensions] fivetran Page" },
    path: "extensions/fivetran/*",
    component: <Fivetran />,
  },
  {
    analyticsData: { pageName: "[Extensions] Looker Page" },
    path: "extensions/looker/*",
    component: <Looker />,
  },
  {
    analyticsData: { pageName: "[Extensions] Sigma Page" },
    path: "extensions/sigma/*",
    component: <Sigma />,
  },
  {
    analyticsData: { pageName: "[Extensions] Alerting Page" },
    path: "extensions/alerting/*",
    component: <Alerting />,
  },
  {
    analyticsData: { pageName: "[Extensions] Git Sync Page" },
    path: "extensions/git-sync/*",
    component: <GitSyncExtension />,
  },
  {
    analyticsData: { pageName: "Extensions Page" },
    path: "extensions",
    component: <Extensions />,
  },

  {
    analyticsData: { pageName: "[Settings] Storage Page" },
    path: "settings/storage",
    component: <Storage />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Tunnels Page" },
    path: "settings/tunnels",
    component: <Tunnels />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Tunnel Details Page" },
    path: "settings/tunnels/:modal_type",
    component: <Tunnels />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Version Control Page" },
    path: "settings/version-control",
    component: <VersionControl />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] API Keys Page" },
    path: "settings/api-keys",
    component: <APIKeys />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Cloud Providers Page" },
    path: "settings/cloud-providers",
    component: <CloudProviders />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Usage Page" },
    path: "settings/usage",
    component: <Usage />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Billing Page" },
    path: "settings/billing",
    component: <Billing />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  {
    analyticsData: { pageName: "[Settings] Roles Page" },
    path: "settings/roles",
    component: <Roles />,
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
  },
  { analyticsData: { pageName: "[Settings] Members Page" }, path: "settings/members", component: <Members /> },
  {
    analyticsData: { pageName: "[Settings] SSO Page" },
    path: "settings/sso",
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
    component: <SSO />,
  },
  {
    analyticsData: { pageName: "[Settings] Audit Log Page" },
    path: "settings/audit-log",
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
    component: <AuditLog />,
  },
  {
    analyticsData: { pageName: "Settings Page" },
    path: "settings/*",
    permissions: [{ resource: "workspace", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/settings/members",
    component: <Workspace />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] New Sync Template Page" },
    path: "schema/sync-templates/new",
    component: <CreateSyncTemplate />,
    permissions: [{ resource: "sync_template", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/schema/sync-templates",
  },
  {
    analyticsData: { pageName: "[Audience Setup] New Metric Page" },
    path: "schema/metrics/new",
    component: <CreateMetric />,
    permissions: [{ resource: "audience_schema", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/schema/metrics",
  },
  {
    analyticsData: { pageName: "[Audience Setup] Metric Details Page" },
    path: "schema/metrics/:metric_id",
    component: <Metric />,
    permissions: [{ resource: "audience_schema", grants: [ResourcePermissionGrant.Read] }],
    unauthorizedRedirect: "/schema/metrics",
  },
  {
    analyticsData: { pageName: "[Audience Setup] Sync Template Details Page" },
    path: "schema/sync-templates/:sync_template_id",
    component: <SyncTemplate />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] Object Audience Details Query Page" },
    path: "schema/:object/:id/query",
    component: <AudienceObjectQuery />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] New Object Page" },
    path: "schema/:object/new",
    component: <CreateAudienceObject />,
    permissions: [{ resource: "audience_schema", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/schema/:object",
  },
  {
    analyticsData: { pageName: "[Audience Setup] Object Audience Detail Page" },
    path: "schema/:object/:id",
    component: <AudienceObject />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] Object Navigation" },
    path: "schema/*",
    component: <SetupAudiences />,
  },
  {
    analyticsData: { pageName: "[Audience Setup] Object Navigation" },
    path: "schema/",
    component: <SetupAudiences />,
    permanentRedirect: "/schema/parent-models",
  },
  {
    analyticsData: { pageName: "[Audience Setup V2]" },
    path: "schema-v2/*",
    component: <SchemaPage />,
  },
  {
    analyticsData: { pageName: "Clone Audience Page" },
    path: "audiences/:id/clone",
    component: <CloneAudience />,
    permissions: [{ resource: "audience", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences/:id",
  },
  {
    analyticsData: { pageName: "New Audience Page" },
    path: "audiences/new",
    component: <CreateAudience />,
    permissions: [{ resource: "audience", grants: [ResourcePermissionGrant.Create] }],
    unauthorizedRedirect: "/audiences",
  },
  {
    analyticsData: { pageName: "Audience Details Page" },
    path: "audiences/:id/*",
    component: <Audience />,
  },
  {
    analyticsData: { pageName: "Audiences Page" },
    path: "audiences",
    component: <Audiences />,
  },
  {
    analyticsData: { pageName: "Audience Priority Lists Page" },
    path: "priority-lists",
    component: <PriorityLists />,
  },
  {
    analyticsData: { pageName: "New Priority List Page" },
    path: "priority-lists/new",
    component: <CreatePriorityList />,
  },
  {
    analyticsData: { pageName: "Priority List Detail Page" },
    path: "priority-lists/:priority_list_id",
    component: <PriorityList />,
  },
  {
    analyticsData: { pageName: "Analytics Page" },
    path: "analytics/*",
    component: <Analytics />,
  },
  {
    analyticsData: { pageName: "Partner Page" },
    path: "partner/*",
    component: <Partner />,
  },
];
