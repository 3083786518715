import { FC, useState } from "react";

import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { PencilIcon } from "@heroicons/react/24/solid";
import { Box, Button, Column, Row } from "@hightouchio/ui";
import { format, parseISO } from "date-fns";

import { ResourceActivity } from "src/hooks/use-resource-activity";
import { Markdown } from "src/ui/markdown";

import { ResourceActivityExpandedView } from "./expanded-view";
import { DisplayedResourceActivity, SubtleText } from "./timeline";

export const ActivityChanges: FC<{ changes?: string[] }> = ({ changes }) => {
  if (!changes) {
    return null;
  }
  return (
    <Box as="ul" ml={4}>
      {changes.map((change, index) => {
        return (
          <li key={index}>
            <Markdown useHightouchUi>{change}</Markdown>
          </li>
        );
      })}
    </Box>
  );
};

export const ActivityItem: FC<{
  activity: ResourceActivity;
  formattedChanges: DisplayedResourceActivity[];
  attributionLoading: boolean;
  icon?: JSX.Element;
}> = ({ activity, formattedChanges, icon = <PencilIcon /> }) => {
  const [rawViewOpen, setRawViewOpen] = useState(false);
  return (
    <>
      <Row>
        <Column mx={3}>
          <Box
            sx={{
              width: "32px",
              height: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "50%",
              bg: "gray.200",
            }}
          >
            <Box sx={{ color: "#697586" }} width="16px">
              {icon}
            </Box>
          </Box>
        </Column>
        <Column gap={1} mt={1}>
          {activity.metadata.is_guest_admin && (
            <Box sx={{ fontWeight: "bold", color: "danger.600", display: "flex", flexDirection: "row", alignItems: "center" }}>
              <ExclamationTriangleIcon width="16px" /> Change made by Hightouch employee
            </Box>
          )}
          <Markdown useHightouchUi>{activity.metadata.user_name + " " + formattedChanges[0]?.message}</Markdown>
          <Column>
            {formattedChanges.map((display, idx) => (
              <ActivityChanges key={idx} changes={display.changes} />
            ))}
          </Column>
          {activity.approvedDraft && activity.approvedDraft.applied_by_user?.id !== Number(activity.metadata.action_by) && (
            <SubtleText>
              Approved by {activity.approvedDraft.applied_by_user?.name} on{" "}
              {activity.approvedDraft.applied_at ? format(parseISO(activity.approvedDraft.applied_at), "PPpp") : "unknown"}
            </SubtleText>
          )}
        </Column>
      </Row>
      <Column mt={1} pt="2px">
        <SubtleText>{format(parseISO(activity.metadata.created_at), "PPpp")}</SubtleText>
      </Column>
      <Column alignItems="flex-end" mt={1}>
        <Button size="sm" onClick={() => setRawViewOpen(true)}>
          Details
        </Button>
      </Column>

      <ResourceActivityExpandedView activity={activity} open={rawViewOpen} onClose={() => setRawViewOpen(false)} />
    </>
  );
};
