import React, { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Checkbox,
  ChevronDownIcon,
  Column,
  FilterIcon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  OverflowMenuIcon,
  Row,
  SearchInput,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import _, { isEqual, orderBy, uniqBy } from "lodash";
import { useSearchParams } from "react-router-dom";

import {
  ModelFiltersQuery,
  ResourcePermissionGrant,
  ResourceToPermission,
  useCreateViewMutation,
  useDeleteViewMutation,
  useUpdateViewMutation,
  useViewsQuery,
  ViewsQuery,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
// eslint-disable-next-line no-restricted-imports
import { Menu as LegacyMenu } from "src/ui/menu";
import { QueryTypeDictionary } from "src/utils/models";
import { load, save } from "src/utils/storage";
import { SyncStatusBadge, SyncStatusToText, SyncStatus } from "src/utils/syncs";

import { FilterType, Filter as ViewFilter, FilterOperator } from "../filter";
import { CreateViewModal } from "../filter/create-view";

export type FilterState = { selected: FilterOption[]; isAllSelected: boolean };

type FilterData = {
  selectedOptions: FilterOption[];
  setSelectedOptions: (options: FilterOption[]) => void;
  options: FilterOption[];
  title: string;
};

type FiltersResult = {
  state: { [key: string]: FilterState };
  data: FilterData[];
};

export function useFilters<F extends { [key: string]: Filter }, S extends { [key in keyof F]: FilterState }>({
  filters,
  viewKey,
  loading,
}: {
  filters: F;
  viewKey: string;
  loading: boolean;
}) {
  const localStorageKey = `${viewKey}-view`;
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedView, setSelectedView] = useState<string | null>(null);
  const [viewNotSaved, setViewNotSaved] = useState(false);

  const { toast } = useToast();

  const { mutateAsync: createNewView, isLoading: creatingView } = useCreateViewMutation();
  const { mutateAsync: updateSavedView, isLoading: updatingView } = useUpdateViewMutation();
  const { mutateAsync: deleteSavedView } = useDeleteViewMutation();

  const { data: viewsData } = useViewsQuery({
    resourceType: viewKey,
  });

  const translateBackendFilters = (newFilter: ViewsQuery["resource_views"][0]["view"]) => {
    const currentFilter = filters[newFilter.type]!;
    if (newFilter.operator === FilterOperator.Excludes) {
      return {
        operator: FilterOperator.Includes,
        type: newFilter.type,
        value: currentFilter.options.filter((o) => !newFilter.value.includes(o.id)).map((o) => o.id),
      };
    }
    return newFilter;
  };

  const views = useMemo(
    () =>
      (viewsData?.resource_views ?? [])
        .filter((view) => {
          // We don't support views that filter on label keys only.
          return view.view.every((v) => v.operator === FilterOperator.Includes || v.operator === FilterOperator.Excludes);
        })
        .map((view) => {
          return {
            ...view,
            view: view.view.map(translateBackendFilters),
          };
        }),
    [viewsData?.resource_views],
  );

  const existingView = views.find(({ id }) => id === selectedView);

  useEffect(() => {
    // Set view saved in local storage on load
    const savedValue = load(localStorageKey);

    if (views && savedValue) {
      const savedView = views.find((view) => view.id === savedValue);

      if (savedView?.view) {
        saveView(savedValue);
        setFilters(savedView.view);
      }
    }
  }, [views, filters]);

  const saveView = useCallback(
    (viewId: string | null) => {
      save(localStorageKey, viewId);
      setSelectedView(viewId);
      setViewNotSaved(false);
      if (viewId) {
        searchParams.set("view", viewId);
        setSearchParams(searchParams);
      } else {
        searchParams.delete("view");
        setSearchParams(searchParams);
      }
    },
    [searchParams],
  );

  const setFilters = useCallback(
    (newFilters: ViewFilter[]) => {
      if (loading) {
        return;
      }

      // clear all filters from query params.
      for (const key of Object.keys(filters)) {
        searchParams.delete(key);
      }

      // set the query params.
      for (const filter of newFilters) {
        const currentFilter = filters[filter.type];
        if (!currentFilter) {
          continue;
        }

        if (filter.operator === FilterOperator.Includes) {
          const optionsToSet = filter.value
            .map((id) => {
              const option = currentFilter.options.find((o) => o?.id?.toString() === id.toString());
              if (!option) {
                return null;
              }
              return option;
            })
            .filter((o: FilterOption | null): o is FilterOption => Boolean(o));
          searchParams.set(filter.type, optionsToSet.map((o) => o.id).join(","));
        } else if (filter.operator === FilterOperator.Excludes) {
          const optionsToSet = currentFilter.options.filter((o) => !filter.value.includes(o.id));
          searchParams.set(filter.type, optionsToSet.map((o) => o.id).join(","));
        }
      }

      setSearchParams(searchParams);
    },
    [filters, loading, searchParams],
  );

  const selectView = useCallback(
    ({ view, filters: newFilters }: { view: string; filters: ViewFilter[] }) => {
      setFilters(newFilters);
      saveView(view);
    },
    [saveView, setFilters],
  );

  const getFilterQueryState = useCallback(
    (filterKey: string, filterOptions: FilterOption[]) => () => {
      const selectedOptions = searchParams.get(filterKey);

      if (!selectedOptions || selectedOptions.length === 0) {
        return filterOptions;
      }

      return selectedOptions
        .split(",")
        .map((id) => {
          const option = filterOptions.find((o) => o?.id?.toString() === id?.toString());
          if (!option) {
            return null;
          }
          return option;
        })
        .filter((o: FilterOption | null): o is FilterOption => Boolean(o));
    },
    [searchParams],
  );

  const setFilterQueryState = useCallback(
    (filterKey: string, filterOptions: FilterOption[]) => (selectedOptions: FilterOption[]) => {
      if (selectedOptions.length === filterOptions.length) {
        searchParams.delete(filterKey);
        setSearchParams(searchParams);
        return;
      }

      searchParams.set(filterKey, selectedOptions.map((o) => o.id).join(","));
      setSearchParams(searchParams);
    },
    [searchParams],
  );

  const resetViewFilters = useCallback(() => {
    const newFilters: ViewFilter[] | undefined = views.find(({ id }) => id === selectedView)?.view;

    if (newFilters) {
      setFilters(newFilters);
      setViewNotSaved(false);
    }
  }, [views, filters]);

  const clearFilters = useCallback(() => {
    setSelectedView(null);
    setFilters([]);
  }, [searchParams, filters]);

  // triggers when the query params change to calculate the current filters state.
  const result: FiltersResult = useMemo(() => {
    const data: FilterData[] = [];
    const state = {} as any;
    const newFilters: ViewFilter[] = [];
    if (!loading) {
      for (const [filterKey, { options, title }] of Object.entries(filters)) {
        const value = getFilterQueryState(filterKey, options)();
        state[filterKey] = { selected: value, isAllSelected: value.length === options.length };

        const filter = { selectedOptions: value, setSelectedOptions: setFilterQueryState(filterKey, options), options, title };
        data.push(filter);
        if (!state[filterKey].isAllSelected) {
          newFilters.push({
            type: filterKey as FilterType,
            operator: FilterOperator.Includes,
            value: filter.selectedOptions.map((o) => o.id),
          });
        }
      }

      if (!isEqual(newFilters, existingView?.view)) {
        setViewNotSaved(true);
      }
    }
    return { state: state as S, data };
  }, [searchParams, filters, loading, existingView]);

  const currentFilterObjects = useMemo(() => {
    const filterObjects: ViewFilter[] = [];
    for (const [filterKey, { selected, isAllSelected }] of Object.entries(result.state)) {
      if (isAllSelected) {
        continue;
      }

      filterObjects.push({
        type: filterKey as FilterType,
        operator: FilterOperator.Includes,
        value: selected.map((o) => o.id),
      });
    }

    return filterObjects;
  }, [result.state]);

  const createView = useCallback(
    async ({ name, private: privateView }: { name: string; private?: boolean }) => {
      try {
        const res = await createNewView({
          resourceType: viewKey,
          name,
          private: Boolean(privateView),
          view: currentFilterObjects,
        });
        saveView(res.insert_resource_views_one?.id);
      } catch (error) {
        Sentry.captureException(error);
        toast({
          id: "create-view",
          title: "View couldn't be created",
          message: error.message,
          variant: "error",
        });

        return;
      }
    },
    [filters, saveView, viewKey, currentFilterObjects],
  );

  const updateCurrentView = useCallback(async () => {
    const id = views.find(({ id }) => id === selectedView)?.id;
    try {
      await updateSavedView({ id, view: currentFilterObjects });
      setViewNotSaved(false);
      toast({
        id: "save-view",
        title: "View saved",
        variant: "success",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        id: "save-view",
        title: "View couldn't be saved",
        message: error.message,
        variant: "error",
      });
    }
  }, [filters, updateSavedView, views, currentFilterObjects]);

  const deleteView = useCallback(
    async (args: { id: string }) => {
      try {
        await deleteSavedView(args);
        setSelectedView(null);
        setFilters([]);

        toast({
          id: "delete-view",
          title: "View deleted",
          variant: "success",
        });
      } catch (error) {
        Sentry.captureException(error);
        toast({
          id: "delete-view",
          title: "View couldn't be deleted",
          message: error.message,
          variant: "error",
        });
      }
    },
    [deleteSavedView],
  );

  const state = useMemo(() => {
    return {
      creatingView,
      selectedView,
      viewNotSaved,
      views,
      updatingView,
    };
  }, [creatingView, selectedView, viewNotSaved, views, updatingView]);

  const actions = useMemo(() => {
    return {
      createView,
      deleteView,
      selectView,
      updateCurrentView,
      resetViewFilters,
      clearFilters,
    };
  }, [createView, deleteView, selectView, updateCurrentView, resetViewFilters, clearFilters]);

  return {
    result,
    state,
    actions,
  };
}

interface FilterProps {
  filters: CollapsableFilterProps[];
  resource: ResourceToPermission;
  resetFilters: () => void;
  clearFilters: () => void;
  viewNotSaved: boolean;
  selectedView: string | null;
  updatingView: boolean;
  updateCurrentView: () => void;
  views: ViewsQuery["resource_views"];
  selectView: (view: { view: string; filters: ViewFilter[] }) => void;
  deleteView: ({ id }: { id: string }) => void;
  creatingView: boolean;
  createView: (data: { name: string; private: boolean }) => Promise<void>;
}

interface IndividualViewProps {
  name: string;
  id: string;
  selectedView: string | null;
  canDelete: boolean;
  selectView: (view: { view: string; filters: ViewFilter[] }) => void;
  deleteView: ({ id }: { id: string }) => void;
  views: { name: string; id: string | null; view?: ViewsQuery["resource_views"][0]["view"] }[];
}

const IndividualView: FC<IndividualViewProps> = ({ views, name, id, selectedView, canDelete, selectView, deleteView }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const onClick = () => {
    if (id === null) {
      selectView({ view: id, filters: [] });
      return;
    }

    const selectedView = views.find((v) => v.id === id);

    if (selectedView) {
      selectView({ view: id, filters: selectedView.view });
    }
  };

  const isSelected: boolean = selectedView === id;

  return (
    <Row
      _hover={{
        bg: isSelected ? undefined : "base.background",
        cursor: "pointer",
        ".action-button": {
          display: "block",
        },
      }}
      align="center"
      borderRadius="md"
      border="1px"
      borderColor={isSelected ? "primary.border" : "transparent"}
      bg={isSelected ? "forest.background" : undefined}
      cursor="pointer"
      fontWeight="medium"
      gap={2}
      justify="space-between"
      px={2}
      py={1}
      transition="border-color 0.1s, background-color 0.1s, box-shadow 0.1s"
      width="100%"
      onClick={onClick}
    >
      <Row align="center" gap={2}>
        <Box
          as={FilterIcon}
          color={isSelected ? "primary.pressed" : "gray.500"}
          fontSize="20px"
          height="20px"
          width="20px"
          mr={-1}
        />
        <Text size="sm" color={isSelected ? "primary.pressed" : "text.primary"}>
          {name}
        </Text>
      </Row>
      {canDelete && id !== null && (
        <Box
          className="action-button"
          sx={{
            display: menuOpen ? "block" : "block",
            ":hover": {
              cursor: "pointer",
              color: "base.500",
            },
          }}
        >
          <LegacyMenu
            options={[
              {
                label: "Delete",
                variant: "danger",
                onClick: () => {
                  deleteView({ id });
                },
              },
            ]}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setMenuOpen(true);
            }}
            onClose={() => setMenuOpen(false)}
          >
            <Box
              _hover={{ bg: "gray.300" }}
              height="20px"
              color={isSelected ? "primary.pressed" : "text.secondary"}
              borderRadius="sm"
              transition="background-color 0.1s, color 0.1s"
            >
              <Box as={OverflowMenuIcon} height="20px" width="20px" />
            </Box>
          </LegacyMenu>
        </Box>
      )}
    </Row>
  );
};

export const Filters: FC<FilterProps> = ({
  filters,
  resource,
  resetFilters,
  viewNotSaved,
  clearFilters,
  selectedView,
  updatingView,
  updateCurrentView,
  views,
  selectView,
  deleteView,
  createView,
  creatingView,
}) => {
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const { hasPermission: userCanDelete } = useHasPermission([{ resource, grants: [ResourcePermissionGrant.Delete] }]);

  const allViews = [{ id: null, name: "Default", views: [] }, ...views];

  const filterCount = filters.filter((filter) => filter.selectedOptions.length !== filter.options.length).length;
  const canClear = filterCount > 0;

  return (
    <Column gap={4}>
      <Column>
        <Box mb={2} pl={2}>
          <Text color="text.secondary" fontWeight="semibold" size="sm" textTransform="uppercase">
            Saved filters
          </Text>
        </Box>
        <Column>
          {allViews.map((view) => {
            return (
              <IndividualView
                key={view.id}
                canDelete={userCanDelete}
                deleteView={deleteView}
                id={view.id}
                name={view.name}
                selectView={selectView}
                selectedView={selectedView}
                views={allViews}
              />
            );
          })}
        </Column>
      </Column>

      <Box borderBottom="1px solid" borderColor="base.divider" />

      <Column>
        <Row align="center" justify="space-between" pl={2} mb={2}>
          <Row alignItems="center" color="text.secondary" gap={2} height={6}>
            <Text fontWeight="semibold" size="sm" textTransform="uppercase" color="text.secondary">
              Filter by
            </Text>
            {filterCount > 0 && (
              <Box as={Text} fontWeight="normal" color="white" bg="text.secondary" px={1.5} borderRadius={100} size="sm">
                {filterCount}
              </Box>
            )}
          </Row>
          <Row gap={1}>
            {canClear && (
              <>
                {viewNotSaved &&
                  (selectedView === null ? (
                    <Button
                      size="sm"
                      onClick={() => {
                        setCreateViewModalOpen(true);
                      }}
                    >
                      Save as
                    </Button>
                  ) : (
                    <Menu>
                      <MenuButton size="sm">Save</MenuButton>

                      <MenuList>
                        <MenuItem
                          isDisabled={updatingView}
                          onClick={() => {
                            updateCurrentView();
                          }}
                        >
                          Save
                        </MenuItem>
                        <MenuItem
                          isDisabled={creatingView}
                          onClick={() => {
                            setCreateViewModalOpen(true);
                          }}
                        >
                          Save as
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            resetFilters();
                          }}
                        >
                          Reset filter
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  ))}
                <Button
                  size="sm"
                  onClick={() => {
                    clearFilters();
                  }}
                >
                  Clear
                </Button>
              </>
            )}
          </Row>
        </Row>

        {filters.map((filter) => (
          <CollapsableFilter key={filter.title} {...filter} />
        ))}

        <CreateViewModal
          isOpen={createViewModalOpen}
          loading={creatingView}
          onClose={() => setCreateViewModalOpen(false)}
          onSave={createView}
        />
      </Column>
    </Column>
  );
};

interface FilterOption {
  id: string;
  label?: string;
  renderOption?: () => ReactNode;
}

interface CollapsableFilterProps {
  title: string;
  options: FilterOption[];
  selectedOptions: FilterOption[];
  setSelectedOptions: (option: FilterOption[]) => void;
}

const CollapsableFilter: FC<CollapsableFilterProps> = ({ title, options, setSelectedOptions, selectedOptions }) => {
  const [isOpen, setIsOpen] = useState(options.length !== selectedOptions.length);
  const [search, setSearch] = useState<string>("");

  const scrollToActive = useCallback(
    (node) => {
      if (node) {
        node.scrollIntoView({ behavior: "smooth", block: "nearest" });
      }
    },
    [isOpen],
  );

  const filteredOptions = orderBy(
    options?.filter((o) => {
      if (o.label && String(o.label).toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      if (o.id && String(o.id).toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      return false;
    }) || [],
    ["label"],
    ["asc"],
  );

  const onCheckboxClick = (e, isChecked: boolean, option: FilterOption) => {
    e.preventDefault();
    e.stopPropagation();
    if (isChecked) {
      setSelectedOptions(selectedOptions.filter((o) => o.id !== option.id));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  return (
    <Column ref={isOpen ? scrollToActive : undefined}>
      <Row
        _active={{ outline: "none" }}
        _focus={{ outline: "none" }}
        _hover={{
          cursor: "pointer",
          bg: "base.background",
        }}
        align="center"
        borderRadius="md"
        as="button"
        px={2}
        gap={1}
        py={1}
        transition="background-color 0.1s"
        width="100%"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Box
          _hover={{ bg: "gray.300" }}
          height="20px"
          borderRadius="sm"
          color="gray.500"
          transform={isOpen ? undefined : "rotate(-90deg)"}
          transition="transform 0.1s"
        >
          <Box as={ChevronDownIcon} height="20px" width="20px" />
        </Box>
        <Row alignItems="center" fontWeight="medium" gap={2}>
          <Box as={Text} size="sm" color="text.primary">
            {title}
          </Box>
          {selectedOptions.length !== options.length && (
            <Box as={Text} border="1px" borderColor="gray.400" borderRadius={100} color="gray.700" px={1.5} size="sm">
              {selectedOptions.length}
            </Box>
          )}
        </Row>
      </Row>

      {isOpen && (
        <Column>
          <Column px={2} pt={1}>
            <SearchInput
              placeholder={`Filter ${title.toLowerCase()}...`}
              value={search ?? ""}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Column>
          {filteredOptions.length === 0 ? (
            <Row ml={8} my={2}>
              <Text color="text.secondary" size="sm">
                No options
              </Text>
            </Row>
          ) : (
            <Column
              borderBottom="solid 1px"
              borderColor="base.divider"
              mb={2}
              maxHeight="200px"
              overflowX="hidden"
              overflowY="auto"
              py={2}
            >
              {filteredOptions.map((option) => {
                const isChecked = Boolean(selectedOptions.find((o) => o.id === option.id));
                return (
                  <FilterRow
                    key={option.id}
                    isAll={selectedOptions.length === 1 && selectedOptions[0]?.id === option.id}
                    isChecked={isChecked}
                    option={option}
                    onCheckboxClick={(e) => onCheckboxClick(e, isChecked, option)}
                    onRowClick={() => {
                      if (selectedOptions.length === 1 && selectedOptions[0]?.id === option.id) {
                        setSelectedOptions(options);
                      } else {
                        setSelectedOptions([option]);
                      }
                    }}
                  />
                );
              })}
            </Column>
          )}
        </Column>
      )}
    </Column>
  );
};

type FilterRowProps = {
  onCheckboxClick: (e: React.MouseEvent<HTMLElement>) => void;
  onRowClick: () => void;
  isChecked: boolean;
  option: FilterOption;
  isAll: boolean;
};

const FilterRow: FC<FilterRowProps> = ({ onCheckboxClick, onRowClick, option, isChecked, isAll }) => {
  const [isCheckboxHovered, setIsCheckboxHovered] = useState(false);

  return (
    <Row
      key={option.id}
      _hover={{
        bg: "base.background",
        ".action-button": {
          display: "flex",
        },
        cursor: "pointer",
      }}
      borderRadius="md"
      justifyContent="space-between"
      pos="relative"
      px={2}
      py={1}
      transition="background-color 0.1s"
      width="100%"
      onClick={onRowClick}
    >
      <Row
        alignItems="center"
        flex={1}
        gap={2}
        overflow="hidden"
        sx={{
          "label > span": { width: "16px", height: "16px", svg: { strokeWidth: "1.5px !important" } },
          "& > div": { display: "flex" },
        }}
      >
        <Checkbox
          isChecked={isChecked}
          onChange={() => {}}
          onClick={onCheckboxClick}
          onMouseEnter={() => {
            setIsCheckboxHovered(true);
          }}
          onMouseLeave={() => {
            setIsCheckboxHovered(false);
          }}
        />
        <Row
          gap={2}
          overflow="hidden"
          sx={{ span: { textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" } }}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {option.renderOption ? option.renderOption() : option.label}
        </Row>
      </Row>
      <Row className="action-button" align="center" display="none" pl={2}>
        <Text color="text.secondary" size="sm">
          {isCheckboxHovered ? "Toggle" : isAll ? "All" : "Only"}
        </Text>
      </Row>
    </Row>
  );
};

type Filter = {
  options: FilterOption[];
  title: string;
};

export const modelQueryTypeFilterConfig = (models: ModelFiltersQuery["segments"]): FilterOption[] => {
  const items = models
    .filter((s) => !!s.query_type)
    .map(({ query_type }) => ({
      id: query_type!,
      label: QueryTypeDictionary[query_type!],
    }));

  return uniqBy(items, "id");
};

export const syncStatusFilterConfig = (): FilterOption[] => {
  const syncStatusFilters: string[] = [
    SyncStatus.DISABLED,
    SyncStatus.SUCCESS,
    SyncStatus.FAILED,
    SyncStatus.WARNING,
    SyncStatus.PROCESSING,
    SyncStatus.QUERYING,
    SyncStatus.PENDING,
  ];

  return syncStatusFilters.map((status) => ({
    id: status,
    label: SyncStatusToText[status],
    renderOption: () => (
      <Box maxWidth={20} sx={{ span: { fontSize: "11px" } }}>
        <SyncStatusBadge status={status} />
      </Box>
    ),
  }));
};

export const createdByFilterConfig = (
  items: { created_by?: string; created_by_user: { id: string; name: string } | null }[],
): FilterOption[] => {
  const res = items
    .filter((item) => !!(item.created_by || item.created_by_user))
    .map((item) => {
      const label = item.created_by_user?.name || "Unknown";
      return {
        id: item.created_by_user?.id || item.created_by || "",
        label,
        renderOption: () => <Text size="sm">{label}</Text>,
      };
    });

  return uniqBy(res, "id");
};

export const labelFilterConfig = (items: { tags: Record<string, string> }[]): FilterOption[] => {
  const allLabels: Record<string, string>[] = [];
  items.forEach(({ tags }) => {
    Object.entries(tags).forEach(([key, value]) => {
      const entry = {};
      entry[key] = value;
      allLabels.push(entry);
    });
  });
  const res = allLabels.map((entry) => {
    const obj = Object.entries(entry)[0]!;
    const label = `${obj[0]}: ${obj[1]}`;
    return {
      id: [obj[0], obj[1]].join(":"),
      label,
      renderOption: () => <Text size="sm">{label}</Text>,
    };
  });
  return uniqBy(res, "id");
};

export const sourceFilterConfig = (
  syncs: {
    segment: {
      connection: {
        id?: string;
        name?: string;
        definition?: {
          name?: string;
          icon?: string;
        };
      } | null;
    } | null;
  }[],
): FilterOption[] => {
  const items = syncs
    ?.filter(({ segment }) => !!segment?.connection?.id)
    .map(({ segment }) => ({
      id: segment?.connection?.id || "",
      label: `${segment?.connection?.name} ${segment?.connection?.definition?.name}`, // label is only used for search
      renderOption: () => {
        return (
          <>
            {segment?.connection?.definition?.name && (
              <Box
                alt={segment?.connection?.definition?.name}
                as="img"
                flexShrink={0}
                maxHeight="100%"
                objectFit="contain"
                src={segment?.connection?.definition?.icon}
                width="18px"
              />
            )}
            <Text size="sm">{segment?.connection?.name}</Text>
          </>
        );
      },
    }));

  return uniqBy(items, "id");
};

export const sourceTypeFilterConfigForModels = (
  connections: {
    connection: {
      type?: string;
      id?: string;
      name?: string;
      definition?: {
        name?: string;
        icon?: string;
      };
    } | null;
  }[],
): FilterOption[] => {
  const items = connections.map(({ connection }) => ({
    id: connection?.type || "",
    label: connection?.type,
    renderOption: () => {
      return (
        <>
          {connection?.definition?.name && (
            <Box
              alt={connection?.definition?.name}
              as="img"
              flexShrink={0}
              maxHeight="100%"
              objectFit="contain"
              src={connection?.definition?.icon}
              width="18px"
            />
          )}
          <Text size="sm">{connection?.definition?.name}</Text>
        </>
      );
    },
  }));

  return uniqBy(items, "id");
};

export const sourceTypeFilterConfig = (
  connections: {
    type?: string;
    id?: string;
    name?: string;
    definition?: {
      name?: string;
      icon?: string;
    };
  }[],
): FilterOption[] => {
  const items = connections.map((connection) => ({
    id: connection?.type || "",
    label: `${connection?.type}`, // label is only used for search
    renderOption: () => {
      return (
        <>
          {connection?.definition?.name && (
            <Box
              alt={connection?.definition?.name}
              as="img"
              flexShrink={0}
              maxHeight="100%"
              objectFit="contain"
              src={connection?.definition?.icon}
              width="18px"
            />
          )}
          <Text size="sm">{connection?.definition?.name}</Text>
        </>
      );
    },
  }));

  return uniqBy(items, "id");
};

export const destinationFilterConfig = (
  syncs: {
    destination: {
      id?: string | null;
      name?: string | null;
      definition?: {
        icon?: string;
        name?: string;
      };
    } | null;
  }[],
): FilterOption[] => {
  const items = syncs.map(({ destination }) => {
    const { id, name, definition } = destination || {};

    return {
      id: id || "",
      label: `${name} ${definition?.name}`, // label is only used for search
      renderOption: () => {
        return (
          <>
            <Box
              alt={definition?.name}
              as="img"
              flexShrink={0}
              maxHeight="100%"
              objectFit="contain"
              src={definition?.icon}
              width="18px"
              height="18px"
            />
            <Text size="sm">{name || definition?.name}</Text>
          </>
        );
      },
    };
  });

  return uniqBy(items, "id");
};

export const destinationTypeFilterConfig = (
  destinations: {
    name?: string | null;
    type?: string | null;
    definition?: {
      icon?: string;
      name?: string;
    };
  }[],
): FilterOption[] => {
  const items = destinations.map((destination) => {
    const { type, name, definition } = destination!;

    return {
      id: type || "",
      label: `${name} ${definition?.name}`, // label is only used for search
      renderOption: () => {
        return (
          <>
            <Box
              alt={definition?.name}
              as="img"
              flexShrink={0}
              maxHeight="100%"
              objectFit="contain"
              src={definition?.icon}
              width="18px"
            />
            <Text size="sm">{name || definition?.name}</Text>
          </>
        );
      },
    };
  });

  return uniqBy(items, "id");
};

export const parentModelFilterConfigForAudiences = (parents: { id: string; name: string }[]): FilterOption[] => {
  const items = parents.map((parent) => ({
    id: parent?.id,
    label: parent?.name,
  }));

  return uniqBy(items, "id");
};
