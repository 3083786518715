import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { SizeCap } from "@hightouch/lib/query/visual/types";
import {
  AudienceIcon,
  Box,
  Button,
  ButtonGroup,
  ChevronRightIcon,
  CloseIcon,
  CodeIcon,
  Column,
  IconButton,
  Menu,
  MenuActionsButton,
  MenuList,
  Pill,
  RedoIcon,
  RefreshIcon,
  Row,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  UndoIcon,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { cloneDeep, isEqual } from "lodash";
import { useQueryClient } from "react-query";

import { ActionBar } from "src/components/action-bar";
import AudienceIllustration from "src/components/audiences/audience-illustration.svg";
import { Editor } from "src/components/editor";
import { QueryBuilder } from "src/components/explore/query-builder";
import { useFormErrorContext } from "src/contexts/form-error-context";
import {
  AudienceBreakdownResult,
  AudienceComparisonResult,
  CompareAudiencesQueryVariables,
  ResourcePermissionGrant,
  useAudienceBreakdownQuery,
  useAudiencesForDropdownsQuery,
  useCompareAudiencesQuery,
  useVisualQuerySqlQuery,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { useMeasureHeightOnce } from "src/hooks/use-measured-height-once";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { Audience, AudienceParent, RootCondition, VisualQueryFilter } from "src/types/visual";
import { SIZES } from "src/ui/box/container";
import { Modal } from "src/ui/modal";
import ErrorSrc from "src/ui/table/error.svg";
import { EMPTY_AUDIENCE_DEFINITION, QueryState, useModelRun } from "src/utils/models";
import { commaNumber } from "src/utils/numbers";
import { useCalculateAudienceSize } from "src/utils/use-calculate-audience-size";

import { Indices } from "../../../../design";
import { cssVariableBottomSectionHeight, cssVariableTopOffset, cssVariableTopSectionHeight } from "../layout/detail-page";
import { DefaultPageContainerPadding } from "../layout/page-container";
import { Permission } from "../permission";
import { AudienceBreakdowns, BreakdownColumn, ChartType } from "./audience-breakdowns";
import { AudienceOverlap } from "./audience-overlap";
import { AudienceResults } from "./audience-results";
import { AudienceSizeCap } from "./audience-size-cap";
import { toSingleCondition } from "./utils";

type Source = {
  id: string;
  definition: {
    type: string;
    supportsResultSchema: boolean;
  };
};

enum TabOptions {
  Results = "Results",
  Overlap = "Overlap",
  Breakdowns = "Breakdown",
}

const defaultSidebarWidth = 400;
const actionBarDefaultHeight = 48;
const footerDefaultHeight = 0;

export type AudienceExploreProps = {
  audience?: Audience;
  bodyOverflow?: "" | "hidden";
  canRedo?: boolean;
  canUndo?: boolean;
  parentModel?: AudienceParent | null;
  queryState: QueryState;
  source: Source | undefined | null;
  onRedo: () => void;
  onReset?: () => void;
  onSave?:
    | ((data: { columns: any }, queryStateOverride?: Partial<QueryState>, showToast?: boolean) => Promise<void>)
    | undefined;
  onUndo: () => void;
  onVisualQueryFilterChange?: (filter: VisualQueryFilter) => void;
};

export const AudienceExplore: FC<Readonly<AudienceExploreProps>> = ({
  audience,
  bodyOverflow = "",
  canRedo,
  canUndo,
  parentModel,
  queryState,
  source,
  onVisualQueryFilterChange,
  onRedo,
  onReset,
  onSave,
  onUndo,
}) => {
  const client = useQueryClient();
  const { toast } = useToast();

  const { hasValidationErrors } = useFormErrorContext();

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState: reset,
    rows,
    numRowsWithoutLimit,
    columns,
    loading,
    error,
  } = useModelRun(QueryType.Visual, parentModel?.columns, {
    modelId: audience ? audience?.id?.toString() : undefined,
    variables: { sourceId: source?.id, parentModelId: parentModel?.id, ...queryState },
    onCompleted: (data, error) => {
      if (!error) {
        setTransformedSql((state) => ({
          ...state,
          sql: data?.transformedSql,
          fetchedAt: Date.now(),
          loading: false,
        }));
      }
    },
  });

  const {
    audienceSize,
    audienceSizeUpdatedAt,
    isLoading: calculatingAudienceSize,
    lastUsedFilter: lastUsedFilterAudienceCount,

    calculateAudienceSize,
    cancelCalculateAudienceSize,
    updateAudienceSize,
  } = useCalculateAudienceSize({
    audienceId: audience?.id?.toString(),
    parentModelId: parentModel?.id?.toString() ?? "",
    sourceId: source?.id?.toString() ?? "",
    visualQueryFilter: queryState.visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
  });

  const sizeLoading = loading || calculatingAudienceSize;
  const visualQueryFilter = queryState.visualQueryFilter;
  const sizeCap = visualQueryFilter?.sizeCap;

  const saveSizeCap = async (payload: SizeCap | undefined): Promise<void> => {
    const isAudiencePersisted = Boolean(audience?.id);

    // If audience is being created, we don't want to actually save the size cap
    // because we would need to save the full audience entity.
    // Instead, we'll just update the visual query filter.
    if (!isAudiencePersisted) {
      onVisualQueryFilterChange?.({
        ...(visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION),
        sizeCap: payload,
      });

      return;
    }

    const newVisualQueryFilter = {
      ...audience?.visual_query_filter,
      sizeCap: payload,
    };

    await onSave?.({ columns: [] }, { visualQueryFilter: newVisualQueryFilter }, false);
  };

  const { refetch: refetchVisualQuerySql } = useVisualQuerySqlQuery(
    {
      audience_id: audience ? audience.id.toString() : undefined,
      connection_id: audience?.connection?.id?.toString() ?? source?.id?.toString() ?? "",
      parent_model_id: audience?.parent?.id?.toString() ?? parentModel?.id?.toString() ?? "",
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
    },
    {
      enabled: false,
    },
  );

  const [transformedSql, setTransformedSql] = useState<{
    sql: string | undefined | null;
    fetchedAt: number | undefined;
    loading: boolean;
    visible: boolean;
  }>({
    sql: undefined,
    fetchedAt: undefined,
    loading: false,
    visible: false,
  });

  const actionBarRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const tabs = [TabOptions.Results, TabOptions.Overlap, TabOptions.Breakdowns];
  const [tab, setTab] = useState<TabOptions>(TabOptions.Results);
  const [isDrawerOpen, setDrawerIsOpen] = useState(false);
  const [chartType, setChartType] = useState<ChartType>("bar");
  const [overlapQueryError, setOverlapQueryError] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);

  const actionBarHeight = useMeasureHeightOnce(actionBarRef, actionBarDefaultHeight);
  const footerBarHeight = useMeasureHeightOnce(footerRef, footerDefaultHeight);

  const { hasPermission: userCanPreviewAudience } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Preview], resource_id: audience?.id },
  ]);
  const { hasPermission: userCanEditAudience } = useHasPermission([
    { resource: "audience", grants: [ResourcePermissionGrant.Update], resource_id: audience?.id },
  ]);

  const [comparedAudienceIds, setComparedAudienceIds] = useState<string[]>([]);
  const [comparisonData, setComparisonData] = useState<AudienceComparisonResult>();
  const [comparisonsLoading, setComparisonsLoading] = useState(false);

  const refetchBreakdown = useRef(false);
  const [breakdownColumns, setBreakdownColumns] = useState<BreakdownColumn[]>([]);
  const [breakdownData, setBreakdownData] = useState<AudienceBreakdownResult>();

  const [lastUsedFilterInsights, setLastUsedFilterInsights] = useState<VisualQueryFilter | null>(null);

  const initialPreviewRun = isDrawerOpen ? Boolean(lastUsedFilterInsights) : Boolean(lastUsedFilterAudienceCount);
  const isDataStale =
    initialPreviewRun && !isEqual(visualQueryFilter, isDrawerOpen ? lastUsedFilterInsights : lastUsedFilterAudienceCount);

  const copySQLToClipboard = () => {
    if (transformedSql.sql) {
      navigator.clipboard.writeText(transformedSql.sql);

      toast({
        id: "copy-sql",
        title: "SQL copied to clipboard",
        variant: "success",
      });
    }
  };

  const {
    error: breakdownError,
    isLoading: breakdownsInitialLoading,
    isRefetching: breakdownsRefetching,
    refetch: refetchBreakdowns,
  } = useAudienceBreakdownQuery(
    {
      id: audience?.connection?.id?.toString() ?? source?.id?.toString() ?? "",
      parentModelId: audience?.parent?.id?.toString() ?? parentModel?.id?.toString() ?? "",
      columns: breakdownColumns,
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      audienceId: audience ? audience.id.toString() : undefined,
      perColumnLimit: 10,
    },
    {
      enabled: false,
      keepPreviousData: breakdownColumns?.length > 0 ? false : true,
    },
  );

  const { data: audiencesData, isLoading: audiencesLoading } = useAudiencesForDropdownsQuery(
    { limit: 5000 },
    {
      refetchOnWindowFocus: true,
      staleTime: 1000 * 60, // 1 min
      notifyOnChangeProps: ["data", "isLoading"],
    },
  );

  const fetchBreakdowns = async () => {
    try {
      const visualQueryUsedToRunBreakdown = cloneDeep(visualQueryFilter);
      const { data } = await refetchBreakdowns();

      if (!data) {
        throw new Error("Audience breakdowns returned no data");
      }
      setLastUsedFilterInsights(visualQueryUsedToRunBreakdown ?? EMPTY_AUDIENCE_DEFINITION);
      updateAudienceSize(data.audienceBreakdown.audienceSize);
      const formattedColumns: AudienceBreakdownResult["columns"] = [];

      for (const column of data?.audienceBreakdown.columns ?? []) {
        // replace name with alias
        const columnData = breakdownColumns.find(({ name }) => name === column.name);
        const columnName = columnData?.alias || column.name;

        const sumOfValues = column.values.reduce((sum, value) => sum + value.count, 0);
        let newValues = column.values.map((value) => ({
          ...value,
          percentage: sumOfValues ? value.count / sumOfValues : "unknown",
        }));
        const numberOfRemainingValues = data.audienceBreakdown.audienceSize - sumOfValues;

        if (numberOfRemainingValues > 0) {
          newValues = newValues.map((value) => ({
            ...value,
            percentage: value.count / data.audienceBreakdown.audienceSize,
          }));
          newValues.push({
            value: "Other",
            count: numberOfRemainingValues,
            percentage: numberOfRemainingValues / data.audienceBreakdown.audienceSize,
          });
          newValues.sort((valueA, valueB) => valueB.count - valueA.count);
        }

        formattedColumns.push({ ...column, name: columnName, values: newValues });
      }

      setBreakdownData({ ...data?.audienceBreakdown, columns: formattedColumns });
    } catch (error) {
      toast({
        id: "audience-breakdowns",
        title: "Breakdown calculation failed",
        message: "Please try again",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const breakdownsLoading = breakdownsInitialLoading || breakdownsRefetching;
  const audiences = audiencesData?.segments ?? [];

  const columnsForBreakdowns = (parentModel?.filterable_audience_columns ?? [])
    .map(({ alias, column_reference, disable_preview }) => ({ ...column_reference, alias, disable_preview }))
    .filter(
      (columnReference) =>
        !columnReference?.disable_preview &&
        columnReference?.type === "raw" &&
        !breakdownColumns.some((selectedColumn) => isEqual(selectedColumn, columnReference)),
    );

  const supportsResultSchema = Boolean(source?.definition?.supportsResultSchema);

  const splitTestGroupName = visualQueryFilter?.splitTestDefinition?.groupColumnName;

  const shouldFetchQuerySql = useMemo(() => {
    /**
     * If we have previewed or saved the query, then the generated SQL will have been cached in local state.
     * So we only need to regenerate the SQL if the filter conditions have changed.
     */
    if (transformedSql.sql && transformedSql.fetchedAt && isDataStale) {
      return audienceSizeUpdatedAt !== null ? audienceSizeUpdatedAt > transformedSql.fetchedAt : false;
    }

    /**
     * If the query hasn't been saved:
     * we don't have a way of knowing whether the query conditions have changed so we refetch to be safe.
     */
    return !transformedSql.sql || !rows;
  }, [audienceSizeUpdatedAt, transformedSql.sql, transformedSql.fetchedAt, isDataStale, rows]);

  const showSqlPreview = useCallback(async () => {
    if (shouldFetchQuerySql) {
      setTransformedSql((state) => ({ ...state, loading: true, visible: true }));
      try {
        const result = await refetchVisualQuerySql();
        setTransformedSql((state) => ({
          ...state,
          sql: result?.data?.visualQuerySQL?.sql,
          fetchedAt: Date.now(),
          loading: false,
        }));
      } catch (error) {
        toast({
          id: "sql-preview",
          title: "SQL preview failed",
          message: "Please try again.",
          variant: "error",
        });
      }
    } else {
      setTransformedSql((state) => ({ ...state, visible: true }));
    }
  }, [shouldFetchQuerySql, refetchVisualQuerySql, setTransformedSql]);

  const handleSave = async () => {
    if (!userCanEditAudience) {
      toast({
        id: "save-audience",
        title: "Unable to save audience",
        message: "You do not have permission to update this audience.",
        variant: "error",
      });
      return;
    }

    if (hasValidationErrors()) {
      toast({
        id: "save-audience",
        title: "Unable to save audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    setSaveLoading(true);

    if (supportsResultSchema && !hasQueryColumns) {
      // This is allowed to fail and still call onSave. :[
      // TODO: track 500 errors once error codes are surfaced
      const schema = await getSchema();

      if (schema?.data?.columns?.length && typeof onSave === "function") {
        await onSave(schema?.data);
      } else {
        toast({
          id: "save-audience",
          title: "There was a problem saving your audience",
          variant: "error",
        });
      }
    } else if (typeof onSave === "function") {
      await onSave({ columns });
    }

    setSaveLoading(false);
  };

  const resetOverlapData = () => {
    setComparedAudienceIds([]);
    setComparisonData(undefined);
  };

  const addBreakdown = (column: BreakdownColumn) => {
    if (hasValidationErrors()) {
      toast({
        id: "add-breakdown",
        title: "Unable to breakdown audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    const newBreakdownColumns = [...breakdownColumns, column];
    setBreakdownColumns(newBreakdownColumns);
  };

  const removeBreakdown = (columnName: string) => {
    refetchBreakdown.current = false;

    // remove data
    setBreakdownData((prevData) => {
      if (!prevData) {
        return undefined;
      }

      const newColumns = [...(prevData?.columns ?? [])].filter((column) => column.name !== columnName);
      if (newColumns.length === 0) {
        return undefined;
      }

      return {
        ...prevData,
        columns: newColumns,
      };
    });

    // remove column
    setBreakdownColumns((prevColumns) => prevColumns.filter((column) => column.name !== columnName));
  };

  const clearBreakdowns = () => {
    refetchBreakdown.current = false;
    setBreakdownColumns([]);
    setBreakdownData(undefined);
  };

  useEffect(() => {
    if (refetchBreakdown.current && breakdownColumns.length > 0) {
      fetchBreakdowns();
    }

    refetchBreakdown.current = true;
  }, [breakdownColumns.length]);

  const compareAudiences = async (
    audienceIds: string[],
    { invalidateQuery }: { invalidateQuery: boolean } = { invalidateQuery: false },
  ) => {
    if (hasValidationErrors()) {
      toast({
        id: "compare-audiences",
        title: "Unable to compare audiences",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    if (audienceIds.length === 0) {
      setComparedAudienceIds([]);
      setComparisonData(undefined);
      setOverlapQueryError(false);
      return;
    }

    setComparisonsLoading(true);

    const variables: CompareAudiencesQueryVariables = {
      id: audience?.connection?.id?.toString() ?? source?.id?.toString() ?? "",
      parentModelId: audience?.parent?.id?.toString() ?? parentModel?.id?.toString() ?? "",
      compareToAudiences: audienceIds || comparedAudienceIds,
      filter: visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION,
      audienceId: audience ? audience.id.toString() : undefined,
    };

    const queryKey = useCompareAudiencesQuery.getKey(variables);

    if (invalidateQuery) {
      await client.invalidateQueries(queryKey);
    }

    try {
      const { audienceComparison } = await client.fetchQuery({
        queryFn: useCompareAudiencesQuery.fetcher(variables),
        queryKey,
      });

      setComparedAudienceIds(audienceIds || comparedAudienceIds);
      setLastUsedFilterInsights(variables.filter);
      updateAudienceSize(audienceComparison?.audienceSize, visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION);
      setComparisonData(audienceComparison);
      setOverlapQueryError(false);
    } catch (error) {
      // TODO: Log only 500 errors to sentry
      setOverlapQueryError(true);
    }

    setComparisonsLoading(false);
  };

  const removeComparison = (idToRemove: string) => {
    setComparedAudienceIds((previousComparedIds) => {
      const newIds = [...previousComparedIds];
      const indexToRemove = newIds.findIndex((comparedId) => comparedId === idToRemove);

      if (indexToRemove > -1) {
        newIds.splice(indexToRemove, 1);
      }

      return newIds;
    });

    setComparisonData((previousComparedData) => {
      if (!previousComparedData) {
        return previousComparedData;
      }

      const newComparedData = {
        ...previousComparedData,
        comparisons: [...previousComparedData.comparisons],
      };

      const indexToRemove = newComparedData.comparisons.findIndex(
        (comparedAudience) => comparedAudience.audienceId === idToRemove,
      );

      if (indexToRemove > -1) {
        newComparedData.comparisons.splice(indexToRemove, 1);
      }

      return newComparedData;
    });
  };

  const handleRunQuery = useCallback(
    ({ limitResults }: { limitResults: boolean }) => {
      if (hasValidationErrors()) {
        toast({
          id: "preview-audience",
          title: "Unable to preview audience",
          message: "Check your query and try again.",
          variant: "error",
        });

        return;
      }

      setLastUsedFilterInsights(visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION);

      // Run both the member preview and the size calculation in parallel
      runQuery({ limit: limitResults, disableRowCounter: true });
      return calculateAudienceSize();
    },
    [runQuery, visualQueryFilter],
  );

  const previewQuery = async () => {
    if (hasValidationErrors()) {
      toast({
        id: "preview-audience",
        title: "Unable to preview audience",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    await handleRunQuery({ limitResults: true });
    analytics.track("Model Query Previewed", {
      model_type: source?.definition?.type,
      query_mode: "visual",
    });
  };

  const toggleDrawer = () => {
    if (userCanPreviewAudience) {
      if (!rows && !error) {
        previewQuery();
      }
      setDrawerIsOpen((prevValue) => !prevValue);
    }
  };

  useEffect(() => {
    if (numRowsWithoutLimit) {
      updateAudienceSize(numRowsWithoutLimit, visualQueryFilter ?? EMPTY_AUDIENCE_DEFINITION);
    }
  }, [numRowsWithoutLimit]);

  const refreshResults = () => {
    if (hasValidationErrors()) {
      toast({
        id: "refresh-audience-results",
        title: "Unable to refresh results",
        message: "Check your query and try again.",
        variant: "error",
      });

      return;
    }

    if (rows || error) {
      previewQuery();
    }

    if (comparedAudienceIds.length) {
      compareAudiences(comparedAudienceIds, { invalidateQuery: true });
    }
    if (breakdownColumns.length > 0) {
      fetchBreakdowns();
    }
  };

  const refresh = () => {
    if (isDrawerOpen) {
      refreshResults();
    } else {
      if (hasValidationErrors()) {
        toast({
          id: "refresh-audience-results",
          title: "Unable to refresh results",
          message: "Check your query and try again.",
          variant: "error",
        });

        return;
      }

      calculateAudienceSize();
    }
  };

  const clickTab = (index: number) => {
    const tabs = [TabOptions.Results, TabOptions.Overlap, TabOptions.Breakdowns];
    setTab(tabs?.[index] || TabOptions.Results);
  };

  useEffect(() => {
    setHasQueryColumns(false);
  }, [visualQueryFilter]);

  useEffect(() => {
    if (columns?.length && !error) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  useEffect(() => {
    if (error) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: "visual",
        error,
      });
    }
  }, [error]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        handleRunQuery({ limitResults: true });
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [handleRunQuery]);

  useEffect(() => {
    return reset;
  }, []);

  const displayBreakdownError =
    breakdownError && !breakdownError?.message.includes("missing columns for breakdown") && !breakdownsLoading;

  useEffect(() => {
    const handleKeyDown = (evt: KeyboardEvent) => {
      const metaKeyPressed = evt.metaKey || evt.ctrlKey;
      const shiftKeyPressed = evt.shiftKey;

      if (canRedo && evt.key === "z" && metaKeyPressed && shiftKeyPressed) {
        onRedo();
      } else if (canUndo && evt.key === "z" && metaKeyPressed && !shiftKeyPressed) {
        onUndo();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [canRedo, canUndo, onUndo, onRedo]);

  // Set body height to stick action bar to top
  useEffect(() => {
    document.body.style.overflowY = bodyOverflow;

    return () => {
      // defaults are empty strings
      document.body.style.overflowY = "";
    };
  }, [bodyOverflow]);

  return (
    <>
      <Row
        ref={actionBarRef}
        height={`${actionBarDefaultHeight}px`}
        position="sticky"
        sx={{ top: `var(${cssVariableTopOffset})` }}
        width="100%"
        zIndex={Indices.Content}
      >
        <Row bg="white" borderBottom="1px solid" borderColor="base.border" flex={1} minWidth={0}>
          <Row
            alignItems="center"
            color="text.secondary"
            flex={1}
            height="48px"
            justifyContent="space-between"
            maxWidth={SIZES.page}
            minWidth={0}
            mx="auto"
            position="relative"
            px={DefaultPageContainerPadding.X}
          >
            <Row gap={2}>
              <Tooltip message="Undo" keyboardShortcut="mod+z" placement="bottom">
                <IconButton
                  aria-label="Undo audience change."
                  icon={UndoIcon}
                  isDisabled={!canUndo}
                  size="lg"
                  onClick={onUndo}
                />
              </Tooltip>
              <Tooltip message="Redo" keyboardShortcut="mod+shift+z" placement="bottom">
                <IconButton
                  aria-label="Redo audience change."
                  icon={RedoIcon}
                  isDisabled={!canRedo}
                  size="lg"
                  onClick={onRedo}
                />
              </Tooltip>
              <Tooltip message="View SQL" placement="bottom">
                <IconButton aria-label="Open SQL preview." icon={CodeIcon} size="lg" onClick={showSqlPreview} />
              </Tooltip>
            </Row>

            {userCanPreviewAudience && (
              <>
                <Row alignItems="center" gap={2}>
                  {!calculatingAudienceSize && audienceSize === null && !sizeLoading && (
                    <Box sx={{ svg: { color: "text.secondary" } }}>
                      <Button icon={AudienceIcon} onClick={refresh}>
                        Calculate size
                      </Button>
                    </Box>
                  )}

                  {(audienceSize !== null || sizeLoading) && (
                    <Row align="center" gap={2} fontSize="20px">
                      {!sizeLoading && isDataStale && (
                        <Box mr={2} sx={{ button: { color: "text.secondary", _hover: { color: "text.secondary" } } }}>
                          <Tooltip message={isDrawerOpen ? "Refresh results" : "Recalculate audience size"}>
                            <IconButton
                              aria-label={isDrawerOpen ? "Refresh results." : "Recalculate audience size."}
                              icon={RefreshIcon}
                              variant="secondary"
                              onClick={refresh}
                            />
                          </Tooltip>
                        </Box>
                      )}

                      {sizeLoading ? (
                        <Box
                          onMouseEnter={() => {
                            if (sizeLoading) {
                              setShowCancelButton(true);
                            }
                          }}
                          onMouseLeave={() => setShowCancelButton(false)}
                        >
                          {showCancelButton ? (
                            <Box sx={{ button: { color: "danger.base", _hover: { color: "danger.base" } } }}>
                              <Tooltip message="Cancel query">
                                <IconButton
                                  aria-label="Cancel query."
                                  icon={CloseIcon}
                                  onClick={() => {
                                    cancelCalculateAudienceSize();
                                    cancelQuery();
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          ) : (
                            <Box as={Spinner} mr={2} size="sm" />
                          )}
                        </Box>
                      ) : (
                        <AudienceIcon />
                      )}

                      <Text fontWeight="semibold" color={sizeLoading ? "text.tertiary" : "text.primary"} size="lg">
                        {audienceSize !== null ? commaNumber(audienceSize) : "--"}
                      </Text>
                    </Row>
                  )}

                  {sizeCap?.limit != null && <Pill>Limited to {commaNumber(sizeCap?.limit)}</Pill>}
                  <Menu>
                    <MenuActionsButton />
                    <MenuList>
                      <AudienceSizeCap
                        properties={parentModel?.filterable_audience_columns || []}
                        data={sizeCap}
                        onSave={saveSizeCap}
                      />
                    </MenuList>
                  </Menu>

                  <Box as={Button} height={8} width="160px" onClick={toggleDrawer}>
                    {isDrawerOpen ? (
                      <Box as={ChevronRightIcon} color="text.secondary" fontSize="24px" />
                    ) : (
                      <Box
                        alignItems="end"
                        display="grid"
                        height="20px"
                        gridTemplateColumns="1fr 1fr 1fr 1fr"
                        mr={3}
                        width="20px"
                      >
                        <Box backgroundColor="cyan.400" height="5px" />
                        <Box backgroundColor="electric.600" height="10px" />
                        <Box backgroundColor="cyan.400" height="15px" />
                        <Box backgroundColor="electric.600" height="20px" />
                      </Box>
                    )}
                    {isDrawerOpen ? "Hide insights" : "Show insights"}
                  </Box>
                </Row>
              </>
            )}
          </Row>
        </Row>
      </Row>
      <Row
        flex={1}
        minHeight={0}
        maxWidth={SIZES.page}
        mb={footerBarHeight ? `${footerBarHeight}px` : undefined}
        mx="auto"
        position="relative"
        px={DefaultPageContainerPadding.X}
        width="100%"
      >
        <QueryBuilder
          audience={audience}
          // To allow for top level switching between and/or the conditions are nested within one single condition.
          filter={toSingleCondition(visualQueryFilter?.conditions)?.[0]}
          parent={parentModel}
          setConditions={(conditions) => {
            if (typeof onVisualQueryFilterChange === "function") {
              onVisualQueryFilterChange({
                ...(visualQueryFilter || {}),
                conditions: conditions ? ([conditions] as RootCondition[]) : [],
              });
            }
          }}
        />

        {userCanPreviewAudience && (
          <Box
            height={
              isDrawerOpen
                ? `calc(100vh - var(${cssVariableTopSectionHeight}) - ${actionBarHeight}px - ${footerBarHeight}px - var(${cssVariableBottomSectionHeight}) - 10px)` // if I don't add 10px, there's some small scroll
                : 0
            }
            flex="none"
            overflowY="auto"
            overscrollBehaviorY="contain"
            pl={isDrawerOpen ? 4 : 0}
            position="sticky"
            right={0}
            top={`calc(var(${cssVariableTopOffset}) + ${actionBarHeight}px)`}
            transition="all 120ms ease-in-out"
            transform={isDrawerOpen ? undefined : "translate(100%, 0)"}
            opacity={isDrawerOpen ? 1 : 0}
            width={isDrawerOpen ? `${defaultSidebarWidth}px` : 0}
          >
            <Column flex={1} minHeight={0} mt={4} position="relative">
              <Tabs index={tabs.findIndex((tabOption) => tab === tabOption)} onChange={clickTab}>
                <TabList>
                  <Tab>Members</Tab>
                  <Tab>Overlap</Tab>
                  <Tab>Breakdown</Tab>
                </TabList>

                <Box as={TabPanels} height="100%">
                  <Box as={TabPanel} height="100%" overflowY="auto">
                    {(rows || error) && !loading ? (
                      <AudienceResults
                        error={error}
                        parentModel={parentModel}
                        rows={rows}
                        splitTestGroupName={splitTestGroupName}
                      />
                    ) : (
                      <Column
                        sx={{
                          alignItems: "center",
                          borderTopRightRadius: 3,
                          flex: 1,
                          justifyContent: "center",
                          overflowY: "auto",
                          p: 4,
                        }}
                      >
                        {loading ? (
                          <>
                            <Spinner size="lg" />
                            <Text mt={6} color="base.6">
                              Querying your data...
                            </Text>
                            <Button mt={6} variant="secondary" onClick={cancelQuery}>
                              Cancel
                            </Button>
                          </>
                        ) : (
                          <>
                            <Box as="img" src={AudienceIllustration} />
                            <Text color="text.secondary" mt={8} mb={4}>
                              Preview this audience to see a sample of results
                            </Text>
                            <Permission
                              permissions={[
                                { resource: "audience", grants: [ResourcePermissionGrant.Preview], resource_id: audience?.id },
                              ]}
                            >
                              <Button isLoading={loading} mb={8} variant="secondary" onClick={previewQuery}>
                                Preview results
                              </Button>
                            </Permission>
                          </>
                        )}
                      </Column>
                    )}
                  </Box>
                  <Box as={TabPanel} height="100%">
                    {!overlapQueryError || comparisonsLoading ? (
                      <AudienceOverlap
                        audienceId={audience?.id}
                        audienceName={audience?.name ?? "Current audience"}
                        audiences={audiences ?? []}
                        comparedAudienceIds={comparedAudienceIds}
                        comparisonData={comparisonData}
                        loading={comparisonsLoading || audiencesLoading}
                        parentModelId={parentModel?.id}
                        onAddComparison={compareAudiences}
                        onClearComparisons={resetOverlapData}
                        onRemoveComparison={removeComparison}
                      />
                    ) : (
                      <Column sx={{ alignItems: "center", pt: 24, px: 4, flex: 1, overflowY: "auto" }}>
                        <Box as="img" src={ErrorSrc} sx={{ mb: 4, maxWidth: "200px" }} />
                        <Text fontWeight="semibold" mb={2}>
                          Error
                        </Text>
                        <Text color="text.secondary" size="sm" mb={4}>
                          Update your query and try again
                        </Text>

                        <Button variant="secondary" onClick={() => compareAudiences([], { invalidateQuery: true })}>
                          Reset
                        </Button>
                      </Column>
                    )}
                  </Box>
                  <Box as={TabPanel} height="100%">
                    {!displayBreakdownError ? (
                      <AudienceBreakdowns
                        breakdownData={breakdownData?.columns ?? []}
                        chartType={chartType}
                        columns={columnsForBreakdowns}
                        graphWidth={defaultSidebarWidth}
                        loading={breakdownsLoading || breakdownsRefetching}
                        onChartTypeChange={setChartType}
                        onClearBreakdowns={clearBreakdowns}
                        onRemoveBreakdown={removeBreakdown}
                        onSubmit={addBreakdown}
                      />
                    ) : (
                      <Column sx={{ alignItems: "center", pt: 24, px: 4, flex: 1, overflowY: "auto" }}>
                        <Box as="img" src={ErrorSrc} sx={{ mb: 4, maxWidth: "200px" }} />
                        <Text fontWeight="semibold" mb={2}>
                          Error
                        </Text>
                        <Text color="text.secondary" size="sm" mb={4}>
                          Update your query and try again
                        </Text>

                        <Button variant="secondary" onClick={() => setBreakdownColumns([])}>
                          Reset
                        </Button>
                      </Column>
                    )}
                  </Box>
                </Box>
              </Tabs>
            </Column>
          </Box>
        )}
      </Row>

      {onSave && (
        <ActionBar ref={footerRef}>
          <ButtonGroup>
            <Button isLoading={saveLoading} isDisabled={!userCanEditAudience} size="lg" variant="primary" onClick={handleSave}>
              Save audience
            </Button>
            {onReset && (
              <Button isDisabled={!userCanEditAudience || !canUndo} size="lg" onClick={onReset}>
                Discard changes
              </Button>
            )}
          </ButtonGroup>
        </ActionBar>
      )}

      <Modal
        footer={
          <>
            <Button onClick={copySQLToClipboard}>Copy SQL</Button>
            <Button variant="primary" onClick={() => setTransformedSql((state) => ({ ...state, visible: false }))}>
              OK
            </Button>
          </>
        }
        bodySx={{ p: 0 }}
        isOpen={transformedSql.visible}
        sx={{ height: "90%", width: "800px" }}
        title="Transformed SQL"
        onClose={() => setTransformedSql((state) => ({ ...state, visible: false }))}
      >
        {loading || transformedSql.loading ? (
          <Box
            sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: "100%" }}
          >
            <Spinner size="lg" />
            <Text mt={4}>Fetching transformed SQL...</Text>
          </Box>
        ) : (
          <Editor readOnly language="sql" value={transformedSql.sql ?? ""} />
        )}
      </Modal>
    </>
  );
};
