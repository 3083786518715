import { FC, ReactNode } from "react";

import { Box } from "@hightouchio/ui";
import { mergeAttributes, Node } from "@tiptap/core";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { PluginKey } from "@tiptap/pm/state";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { Suggestion, SuggestionOptions } from "@tiptap/suggestion";

export type TypeaheadOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: { options: TypeaheadOptions; node: ProseMirrorNode }) => string;
  suggestion: Omit<SuggestionOptions, "editor">;
};

export const newTypeaheadPlugin = (opts: { pluginKey: string; suggestionChar: string }) => {
  const { pluginKey, suggestionChar } = opts;

  const TypeaheadPluginKey = new PluginKey(pluginKey);
  return Node.create<TypeaheadOptions>({
    name: pluginKey,

    addOptions() {
      return {
        HTMLAttributes: {},
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        suggestion: {
          char: suggestionChar,
          pluginKey: TypeaheadPluginKey,
          command: ({ editor, range, props }) => {
            // increase range.to by one when the next node is of type "text"
            // and starts with a space character
            const nodeAfter = editor.view.state.selection.$to.nodeAfter;
            const overrideSpace = nodeAfter?.text?.startsWith(" ");

            if (overrideSpace) {
              range.to += 1;
            }

            editor
              .chain()
              .focus()
              .insertContentAt(range, [
                { type: this.name, attrs: props },
                { type: "text", text: " " },
              ])
              .run();

            window.getSelection()?.collapseToEnd();
          },
          allow: ({ state, range }) => {
            const $from = state.doc.resolve(range.from);
            const type = state.schema.nodes[this.name];
            const allow = type == null ? false : !!$from.parent.type.contentMatch.matchType(type);

            return allow;
          },
        },
      };
    },

    group: "inline",

    inline: true,

    selectable: opts.pluginKey === "liquid",

    atom: true,

    addAttributes() {
      return {
        id: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-id"),
          renderHTML: (attributes) => {
            if (!attributes.id) {
              return {};
            }

            return { "data-id": attributes.id };
          },
        },

        label: {
          default: null,
          parseHTML: (element) => element.getAttribute("data-label"),
          renderHTML: (attributes) => {
            if (!attributes.label) {
              return {};
            }

            return {
              "data-label": attributes.label,
            };
          },
        },
      };
    },

    parseHTML() {
      return [{ tag: `span[data-type="${this.name}"]` }];
    },

    renderHTML({ node, HTMLAttributes }) {
      return [
        "span",
        mergeAttributes({ "data-type": this.name }, this.options.HTMLAttributes, HTMLAttributes),
        `${suggestionChar}${node.attrs.id}`,
      ];
    },

    renderText({ node }) {
      return `${suggestionChar}${node.attrs.id}`;
    },

    addKeyboardShortcuts() {
      return {
        // Delete the type ahead block
        Backspace: () =>
          this.editor.commands.command(({ tr, state }) => {
            let isTypeahead = false;
            const { selection } = state;
            const { empty, anchor } = selection;

            if (!empty) {
              return false;
            }

            state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
              if (node.type.name === this.name) {
                isTypeahead = true;
                tr.insertText(this.options.suggestion.char || "", pos, pos + node.nodeSize);

                return false;
              }
              // Type complaining not all code path returns a value.
              // This is for when `void` overload of the function.
              return;
            });

            return isTypeahead;
          }),
      };
    },

    addProseMirrorPlugins() {
      return [
        Suggestion({
          editor: this.editor,
          ...this.options.suggestion,
        }),
      ];
    },

    addStorage() {
      return {
        lookup: new Map<string, string>(),
      };
    },

    addNodeView() {
      return ReactNodeViewRenderer((props) => {
        return (
          <NodeViewWrapper className="rte-node-view">
            {suggestionChar === "{{" ? (
              <TypeAheadNodeView>
                {"{{"} {props.node.attrs.label || props.node.attrs.id} {"}}"}
              </TypeAheadNodeView>
            ) : (
              <TypeAheadNodeView>
                {suggestionChar}
                {props.node.attrs.label || props.node.attrs.id}
              </TypeAheadNodeView>
            )}
          </NodeViewWrapper>
        );
      });
    },
  });
};

const TypeAheadNodeView: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Box display="inline" borderRadius="4px" backgroundColor="forest.200" padding="1px">
      {children}
    </Box>
  );
};
