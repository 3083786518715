import { useEffect, ReactNode, FC } from "react";

import { Row, Select, TextInput, Text } from "@hightouchio/ui";
import moment from "moment";

import {
  AbsoluteRelativeTimestampOperators,
  DateIntervalOptions,
  IntervalOperators,
  IntervalOptions,
  IntervalUnit,
  IntervalValue,
  OperatorsWithoutValue,
  PropertyCondition,
  TimestampOperator,
  Window,
  RelativeDirection,
  TimeType,
} from "src/types/visual";
import { Column } from "src/ui/box";
import { DateTimeSelect } from "src/ui/datetime-select";
import { FieldError } from "src/ui/field";
import { isNumericString } from "src/utils/string";

type Props = {
  error?: ReactNode;
  condition: PropertyCondition | Window;
  onChange: (updates: Partial<PropertyCondition | Window>) => void;
  hideTime: boolean;
  showRelativeDirections?: boolean;
};

export const TimestampInput: FC<Readonly<Props>> = ({
  condition,
  hideTime,
  error,
  showRelativeDirections = true,
  onChange,
}) => {
  // Set the default state to be a relative timestamp
  useEffect(() => {
    if (!condition?.timeType) {
      const defaults: Partial<PropertyCondition | Window> = { timeType: TimeType.Relative };

      // Between operator has a value of type `TimeRangeValue` consisting of `before` and `after` objects
      // so we set the relative direction where we initialize the default value (in TimeIntervalInput)
      if (condition?.operator !== TimestampOperator.Between) {
        defaults.value = {
          ...condition.value,
          direction: condition?.value?.direction ?? RelativeDirection.Backward,
        };
      }

      onChange(defaults);
    }
  }, [condition?.timeType, condition?.operator, condition?.value]);

  if (AbsoluteRelativeTimestampOperators.includes(condition.operator)) {
    return <AbsoluteRelativeTimeInput condition={condition} error={error} hideTime={hideTime} onChange={onChange} />;
  }

  return (
    <>
      {IntervalOperators.includes(condition.operator) ? (
        <TimeIntervalInput
          hideTime={hideTime}
          onChange={(value) => {
            onChange({ value });
          }}
          showRelativeDirections={showRelativeDirections}
          value={condition.value}
        />
      ) : (
        !OperatorsWithoutValue.includes(condition.operator) && (
          <TimestampComparisonInput
            hideTime={hideTime}
            onChange={(value) => {
              onChange({ value });
            }}
            value={condition.value}
          />
        )
      )}
    </>
  );
};

const AbsoluteRelativeTimeInput = ({ condition, error, onChange, hideTime }: Props) => {
  const handleTimeTypeChange = (timeTypeOrDirection: TimeType | RelativeDirection | undefined) => {
    if (timeTypeOrDirection === undefined) {
      return;
    }

    if (timeTypeOrDirection === TimeType.Relative || timeTypeOrDirection === TimeType.Absolute) {
      onChange({ value: null, timeType: timeTypeOrDirection });
    } else {
      onChange({ value: { ...condition.value, direction: timeTypeOrDirection }, timeType: TimeType.Relative });
    }
  };

  const timeTypeOptions =
    condition.operator === TimestampOperator.Between
      ? [
          { label: "relative", value: TimeType.Relative },
          { label: "absolute", value: TimeType.Absolute },
        ]
      : [
          { label: "previous", value: RelativeDirection.Backward },
          { label: "next", value: RelativeDirection.Forward },
          { label: "absolute", value: TimeType.Absolute },
        ];

  return (
    <>
      <Select
        options={timeTypeOptions}
        value={condition?.value?.direction ?? condition.timeType}
        width="auto"
        onChange={handleTimeTypeChange}
      />
      {condition.operator === TimestampOperator.Between ? (
        <TimeRangeInput
          afterValue={condition.value?.after}
          beforeValue={condition.value?.before}
          error={error}
          hideTime={hideTime}
          setAfterValue={(value) => {
            onChange({ value: { ...condition.value, after: value } });
          }}
          setBeforeValue={(value) => {
            onChange({ value: { ...condition.value, before: value } });
          }}
          timeType={condition.timeType ?? TimeType.Relative}
        />
      ) : condition.timeType === TimeType.Absolute ? (
        <TimestampComparisonInput
          hideTime={hideTime}
          onChange={(value) => {
            onChange({ value });
          }}
          value={condition.value}
        />
      ) : (
        <TimeIntervalInput
          error={error}
          hideTime={hideTime}
          onChange={(value) => {
            onChange({ value });
          }}
          showRelativeDirections={false}
          value={condition.value}
        />
      )}
    </>
  );
};

const TimeRangeInput: FC<
  Readonly<
    | {
        timeType: TimeType.Absolute;
        beforeValue: string;
        afterValue: string;
        setAfterValue: (value: string) => void;
        setBeforeValue: (value: string) => void;
        hideTime: boolean;
      }
    | {
        timeType: TimeType.Relative;
        beforeValue: IntervalValue;
        afterValue: IntervalValue;
        setAfterValue: (value: IntervalValue) => void;
        setBeforeValue: (value: IntervalValue) => void;
        hideTime: boolean;
      }
  > & { error?: ReactNode }
> = (props) => {
  if (props.timeType === TimeType.Absolute) {
    const { beforeValue, setBeforeValue, setAfterValue, afterValue, hideTime } = props;
    return (
      <>
        <TimestampComparisonInput hideTime={hideTime} onChange={setBeforeValue} value={beforeValue} />
        <Row as={Text} flexShrink={0}>
          and
        </Row>
        <TimestampComparisonInput hideTime={hideTime} isRangeEnd={true} onChange={setAfterValue} value={afterValue} />
      </>
    );
  } else {
    const { beforeValue, setBeforeValue, setAfterValue, afterValue, hideTime } = props;
    return (
      <>
        <TimeIntervalInput
          error={props.error}
          hideTime={hideTime}
          onChange={setBeforeValue}
          showRelativeDirections={false}
          value={beforeValue}
        />
        <Row as={Text} flexShrink={0}>
          and
        </Row>
        <TimeIntervalInput
          error={props.error}
          hideTime={hideTime}
          isRangeEnd={true}
          onChange={setAfterValue}
          showRelativeDirections={false}
          value={afterValue}
        />
      </>
    );
  }
};

const TimeIntervalInput = ({
  error,
  value,
  onChange,
  isRangeEnd = false,
  hideTime = false,
  showRelativeDirections = true,
}: {
  error?: ReactNode;
  value: IntervalValue;
  onChange: (value: IntervalValue) => void;
  isRangeEnd?: boolean;
  hideTime: boolean;
  showRelativeDirections?: boolean;
}) => {
  // Handles default values.
  if ((!value?.quantity && !value?.interval) || !value?.direction) {
    onChange({
      quantity: value?.quantity ?? (isRangeEnd ? 2 : 1),
      interval: value?.interval ?? IntervalUnit.Month,
      direction: value?.direction ?? RelativeDirection.Backward,
    });

    return null;
  }

  return (
    <>
      {showRelativeDirections && (
        <Select
          options={[
            { label: "previous", value: RelativeDirection.Backward },
            { label: "next", value: RelativeDirection.Forward },
          ]}
          width="auto"
          value={value.direction}
          onChange={(direction: RelativeDirection | undefined) => {
            if (direction === undefined) {
              return;
            }

            onChange({ ...value, direction });
          }}
        />
      )}
      <Column sx={{ width: "70px", flex: "0 0 auto" }}>
        <TextInput
          isInvalid={Boolean(error)}
          placeholder="time..."
          value={value?.quantity != null ? String(value.quantity) : ""}
          onChange={(event) => {
            onChange({ ...value, quantity: isNumericString(event.target.value) ? Number(event.target.value) : undefined });
          }}
        />
        {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
      </Column>
      <Select
        options={hideTime ? DateIntervalOptions : IntervalOptions}
        placeholder="unit..."
        width="auto"
        value={value?.interval}
        onChange={(interval) => {
          if (interval === undefined) {
            return;
          }

          onChange({ ...value, interval });
        }}
      />
    </>
  );
};

const TimestampComparisonInput = ({
  value,
  onChange,
  isRangeEnd = false,
  hideTime = false,
}: {
  value: string;
  onChange: (value: string) => void;
  isRangeEnd?: boolean;
  hideTime: boolean;
}) => {
  const formatValue = (value) => moment(value).utc(true).format("YYYY-MM-DD HH:mm:ss");

  // Handles default values.
  if (!value) {
    if (isRangeEnd) {
      const now = new Date();

      const oneMonthAgo = new Date(now);
      oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);

      // Handles end of the month edge cases when the amount of days
      // in consecutive months are not aligned.
      while (now.getMonth() === oneMonthAgo.getMonth()) {
        oneMonthAgo.setDate(oneMonthAgo.getDate() - 1);
      }

      onChange(formatValue(oneMonthAgo));
    } else {
      onChange(formatValue(new Date()));
    }

    return null;
  }

  return (
    <DateTimeSelect
      hideTime={hideTime}
      value={new Date(value)}
      onChange={(value) => {
        onChange(formatValue(value));
      }}
    />
  );
};
