import { FC, useMemo } from "react";

import { MinusCircleIcon, PlusIcon } from "@heroicons/react/24/solid";
import { Box, Column, Row, SectionHeading, Spinner } from "@hightouchio/ui";
import { format, parseISO } from "date-fns";
import { Delta } from "jsondiffpatch";

import emptyStateImage from "src/components/resource-activity/empty-state.svg";
import { useUser } from "src/contexts/user-context";
import { ChangelogItem } from "src/graphql";
import {
  ParsedDiff,
  parseDiff,
  QueryableChangeLogResource,
  ResourceActivity,
  useResourceActivity,
} from "src/hooks/use-resource-activity";
import { Placeholder } from "src/ui/table/placeholder";

import { Indices } from "../../../../design";
import { FeaturePreview } from "../feature-gates";
import { Banner } from "../layout/header/workspace-validation";
import { ActivityItem } from "./item";

export type ResourceActivityMapper = {
  parser: (
    metadata: Pick<ChangelogItem, "resource_name" | "old" | "new">,
    parsedDiff: ParsedDiff,
  ) => DisplayedResourceActivity | null;
  accessor: string;
  overrideDiffAccessor?: Record<string, unknown>;
};

interface Resource {
  resourceId: string;
  mappers: ResourceActivityMapper[];
  resource: QueryableChangeLogResource;
  resourceNameOverride?: string;
  // For diffing related resources, we can ignore certain columns
  ignoreColumns?: string[];
}
interface Props {
  primaryResource: Resource;
  relatedResources?: Resource[];
}

interface DefaultActivityProps {
  metadata: Pick<ChangelogItem, "resource_name" | "old" | "new">;
  resource: string;
  rawDiff?: Delta;
}

export interface DisplayedResourceActivity {
  message: string;
  changes?: string[];
  icon?: JSX.Element;
}

export function normalizeName(name: string): string {
  // Split camelcase but keep letter
  const words = name.replaceAll("_", " ").split(/(?=[A-Z])/);
  const normalizedWords = words.map((word) => word.toLowerCase()).filter((word) => word.length > 0);
  return normalizedWords.join(" ");
}

export const defaultResourceMapper = ({
  metadata,
  resource,
  rawDiff: diff,
}: DefaultActivityProps): DisplayedResourceActivity | null => {
  if (!metadata.old && metadata.new) {
    return {
      message: `created ${resource.toLowerCase()} ${metadata.new?.name ? `\`${metadata.new?.name}\`` : ""}`,
      icon: <PlusIcon />,
    };
  }
  if (metadata.old && !metadata.new) {
    return {
      message: `deleted ${resource} ${metadata.old?.name ? `\`${metadata.old?.name}\`` : null}`,
      icon: <MinusCircleIcon />,
    };
  }
  // This should only occur when a draft is approved to create a resource
  if (!diff) {
    return null;
  }

  const topLevelKeys = Object.keys(diff).map(normalizeName);
  if (topLevelKeys.length === 0) {
    return {
      message: `updated ${resource}`,
    };
  }
  if (topLevelKeys.length === 1) {
    return {
      message: `updated \`${topLevelKeys[0]}\``,
    };
  }
  return {
    message: `updated ${resource.toLowerCase()} properties`,
    changes: topLevelKeys,
  };
};

// TODO: remove this when we get a new subtle text variant in Hightouch UI
export const SubtleText: FC<{ children: any }> = ({ children }) => {
  return <span style={{ fontSize: "12px", fontWeight: 500, lineHeight: "16px", color: "#697586" }}>{children}</span>;
};

const NoResourceActivity = ({ resource, period }: { resource: string; period: "all" | "week" }) => {
  return (
    <Placeholder
      content={{
        image: emptyStateImage,
        title: period === "week" ? `No activity in the last week` : `No recent activity`,
        body: `This log shows all changes made to the ${resource.toLowerCase()}, when they occurred and by whom.`,
      }}
    />
  );
};

export const ResourceActivityTimeline: FC<Props> = ({ relatedResources = [], primaryResource }) => {
  const { workspace, user } = useUser();

  const period = workspace?.organization?.plan?.sku === "business_tier" || user?.is_admin ? "all" : "week";

  const parentResourceName = primaryResource.resourceNameOverride || primaryResource.resource;

  const allResourceActivities = [primaryResource, ...relatedResources].map(
    ({ resourceId, resource, mappers, resourceNameOverride, ignoreColumns }) => ({
      query: useResourceActivity({ resourceId, period, resource, ignoreColumns }),
      mappers,
      resource,
      resourceNameOverride,
    }),
  );

  const dateFormattedActivities: {
    [date: string]: { formattedActivity: DisplayedResourceActivity[]; activity: ResourceActivity }[];
  } = useMemo(() => {
    let formatted = {};

    if (!allResourceActivities.some(({ query }) => query.activity)) {
      return formatted;
    }

    for (const resourceActivities of allResourceActivities) {
      for (const activity of resourceActivities.query.activity) {
        const mappedActivity = resourceActivities.mappers
          .map((mapper) => {
            const parsedDiff = parseDiff(activity, mapper.accessor, mapper.overrideDiffAccessor);
            if (!parsedDiff) {
              return null;
            }
            return mapper.parser(activity.metadata, parsedDiff);
          })
          .filter((activity) => activity !== null) as DisplayedResourceActivity[];

        let formattedChanges = mappedActivity;
        if (mappedActivity.length === 0) {
          const defaultActivity = defaultResourceMapper({
            metadata: activity.metadata,
            resource: activity.metadata.resource,
            rawDiff: activity.diff,
          });
          if (!defaultActivity) {
            continue;
          }
          formattedChanges = [defaultActivity];
        }
        const date = format(parseISO(activity.metadata.created_at), "PP");
        formatted = {
          ...formatted,
          [date]: [...(formatted[date] || []), { formattedActivity: formattedChanges, activity }],
        };
      }
    }
    return formatted;
  }, [allResourceActivities]);

  if (allResourceActivities.some(({ query }) => query.loading)) {
    return (
      <Column align="center" flex={1} justify="center" minHeight={0}>
        <Spinner size="lg" />
      </Column>
    );
  }
  return (
    <Column width="100%">
      {user?.is_admin && (
        <Banner sx={{ mb: 3, zIndex: Indices.Content }}>
          <Box sx={{ fontWeight: "bold", color: "white", fontSize: 16 }}>
            You are viewing extended resource history, be careful when taking screenshots.
          </Box>
        </Banner>
      )}
      <SectionHeading mb={4}>Recent activity</SectionHeading>
      <Column gap={8}>
        <FeaturePreview
          enabled={workspace?.organization?.plan?.sku === "business_tier"}
          featureDetails={{
            image: {
              src: "https://cdn.sanity.io/images/pwmfmi47/production/e1741531ea3970f965294cb386f900bd1c149e28-856x518.png",
            },
            pitch: `View a timeline of changes to your ${parentResourceName.toLowerCase()}`,
            description: `With activity history, you can granularly track changes to the schedule, labels, and configuration of your ${parentResourceName.toLowerCase()}.`,
            bullets: [
              `Track and view the state of a ${parentResourceName.toLowerCase()} at any point in time`,
              `Gain insight into who approved and merged changes to the ${parentResourceName.toLowerCase()}`,
              `Correlate changes to your ${parentResourceName.toLowerCase()} with changes to your data`,
            ],
          }}
          featureName={`${parentResourceName.toLowerCase()} activity history`}
          variant="limited"
        />
        {Object.entries(dateFormattedActivities).length === 0 ? (
          <NoResourceActivity period={period} resource={parentResourceName} />
        ) : (
          <Box
            display="grid"
            gap={4}
            gridTemplateColumns="minmax(0px, max-content) max-content min-content"
            justifyContent="space-between"
            width="100%"
          >
            {/* We need to group activities by date with a divider between them labelled with the date */}
            {Object.entries(dateFormattedActivities)
              .sort((a, b) => {
                // We need to sort each day-group by the time of the first activity in the group.
                const [first, second] = [a[1][0], b[1][0]];
                if (first && second && first.activity.metadata.created_at > second.activity.metadata.created_at) {
                  return -1;
                }
                return 1;
              })
              .map(([date, activities], idx) => {
                return (
                  <>
                    <Row key={idx} alignItems="center" bg="gray.100" gridColumn="1 / 4" mt={2} p={2}>
                      {/* We need to do this because we don't yet have an official subtle text variant */}
                      <Box as="span" color="gray.600" fontSize="sm" fontWeight="semibold">
                        {date}
                      </Box>
                    </Row>

                    {activities
                      .sort((first, second) => {
                        if (first && second && first.activity.metadata.created_at > second.activity.metadata.created_at) {
                          return -1;
                        }
                        return 1;
                      })
                      .map(({ formattedActivity, activity }) => (
                        <ActivityItem
                          key={activity.metadata.id}
                          activity={activity}
                          attributionLoading={allResourceActivities.some(({ query }) => query.attributionLoading)}
                          formattedChanges={formattedActivity}
                          icon={formattedActivity[0]?.icon}
                        />
                      ))}
                  </>
                );
              })}
          </Box>
        )}
      </Column>
    </Column>
  );
};
