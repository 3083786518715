import { FC } from "react";

import { useUser } from "src/contexts/user-context";
import { useCanDeploySyncsQuery, useDeploySyncMutation, useLinkableWorkspaceResourcesQuery } from "src/graphql";
import { ResourceActivityDiffer } from "src/hooks/use-resource-activity";
import { syncActivityMappers } from "src/pages/syncs/sync-activity";

import { Deployment } from "./common";
import { DeploymentWizard } from "./deployment-wizard";
import { getDeploymentDiff } from "./diff";

const syncDeploymentDiffer = ResourceActivityDiffer(["workspace_id", "model_id", "segment_id", "destination_id"], ["name"]);

const getSyncDeploymentDiff = getDeploymentDiff(syncDeploymentDiffer, syncActivityMappers);

export const SyncDeploymentWizard: FC<
  Readonly<{
    deployment: Deployment<{ modelId: string; destinationId: string }>;
    onClose: () => void;
  }>
> = ({ deployment, onClose }) => {
  const { workspaces, user, isLoading: userLoading } = useUser();
  const { mutateAsync: deploy, isLoading: deploying } = useDeploySyncMutation();
  const targetWorkspaces = workspaces?.filter((workspace) => String(workspace.id) !== String(user?.current_workspace_id)) || [];

  const { data: deploymentTests, isLoading: isTestingDeployments } = useCanDeploySyncsQuery(
    {
      targetWorkspaceIds: targetWorkspaces?.map((workspace) => workspace.id) || [],
      sourceResourceId: deployment.sourceResourceId,
    },
    {
      enabled: !userLoading,
      refetchOnMount: "always",
      select: (data) => data.canDeployDestinationInstances,
    },
  );

  const { data: linkableResources, isLoading: linkableResourcesLoading } = useLinkableWorkspaceResourcesQuery(
    {
      sourceResourceId: deployment.metadata?.destinationId || "",
      resourceType: "destinations",
    },
    { select: (data) => data.getLinkableResources },
  );

  return (
    <DeploymentWizard
      onClose={onClose}
      deplomentTestsLoading={isTestingDeployments}
      isDeploying={deploying}
      getDeploymentDiff={getSyncDeploymentDiff}
      linkableResources={linkableResources}
      linkableResourcesLoading={linkableResourcesLoading}
      deployResource={async (resourceId, targetWorkspaceId) => {
        const { deploySync } = await deploy({
          syncId: resourceId,
          targetWorkspaceId,
        });
        const success = deploySync.__typename === "SuccessfulSyncDeploymentResult";
        return {
          success,
          resourceId: success ? deploySync.deployedResourceId : undefined,
        };
      }}
      deployment={deployment}
      deploymentTests={deploymentTests}
      targetWorkspaces={targetWorkspaces}
      targetWorkspacesLoading={userLoading}
    />
  );
};
