import { FC } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Alert, Column, Link, LinkIcon, Radio, Row, SuccessIcon, Text, WarningIcon } from "@hightouchio/ui";

import { CanDeployModelsQuery, DeploymentOperation, LinkableWorkspaceResourcesQuery, ValidatorNames } from "src/graphql";

import { Deployment, TargetWorkspace, ValidationResult } from "./common";
import { DeploymentDiff } from "./diff";
import { ResourceLinkForm } from "./linking";

type FailedDeploymentValidationResult = Extract<ValidationResult[0], { __typename: "FailedDeploymentValidationResult" }>;

const getExtensionSetupLink = (stepName: ValidatorNames, workspaceSlug: string) => {
  let extensionTarget = "";
  switch (stepName) {
    case ValidatorNames.DbtModelExists:
      extensionTarget = "dbt";
      break;
    case ValidatorNames.LookerExtensionExists:
      extensionTarget = "looker";
      break;
    case ValidatorNames.SigmaExtensionExists:
      extensionTarget = "sigma";
      break;
  }
  return `${import.meta.env.VITE_APP_BASE_URL}/${workspaceSlug}/extensions/${extensionTarget}`;
};

const ReasonSupplement: FC<{
  deployment: Deployment;
  result: FailedDeploymentValidationResult;
  targetWorkspace: TargetWorkspace;
}> = ({ deployment, result, targetWorkspace }) => {
  switch (result.reason) {
    case "ExtensionSetup": {
      const link = getExtensionSetupLink(result.stepName, targetWorkspace?.slug || "");
      switch (result.stepName) {
        case ValidatorNames.DbtModelExists:
          return (
            <Text>
              {result.message}. Please <Link href={link}>resolve</Link> this issue in this workspace.
            </Text>
          );
        default:
          return (
            <Text>
              {result.message}. Please <Link href={link}>set up</Link> this extension.
            </Text>
          );
      }
    }
    case "Unauthorized":
      return <Text>{result.message}. Please contact one of your workspace admins.</Text>;
    case "SegmentDeploymentNeeded":
      return (
        <Text>
          The model must be deployed before this sync can be deployed.{" "}
          <Link href={`/models/${deployment.metadata?.modelId}`}>Please deploy the model first.</Link>
        </Text>
      );
    default:
      return (
        <Text>
          Check {result.stepName} ({result.reason}): {result.message}
        </Text>
      );
  }
};

const CantDeploy: FC<{ deployment: Deployment; results: ValidationResult; targetWorkspace: TargetWorkspace }> = ({
  results,
  deployment,
  targetWorkspace,
}) => (
  <Column>
    <Row alignItems="center" width="100%" gap={2}>
      <Text color="red">
        <XMarkIcon height={20} />
      </Text>
      <Text>Can't deploy</Text>
    </Row>
    {results.map((result, idx) => {
      if (!result || result.__typename !== "FailedDeploymentValidationResult") {
        return null;
      }

      return <ReasonSupplement key={idx} deployment={deployment} result={result} targetWorkspace={targetWorkspace} />;
    })}
  </Column>
);

const DeployWithCaution: FC<{ deployment: Deployment; results: ValidationResult; targetWorkspace: TargetWorkspace }> = ({
  deployment,
  results,
  targetWorkspace,
}) => {
  if (!results) {
    return null;
  }

  return (
    <Column>
      <Row alignItems="center" width="100%" gap={1}>
        <WarningIcon color="warning.base" />
        <Text>Action required</Text>
      </Row>
      {results.map((result, index) => {
        if (!result || result.__typename !== "FailedDeploymentValidationResult") {
          return null;
        }

        return <ReasonSupplement key={index} deployment={deployment} result={result} targetWorkspace={targetWorkspace} />;
      })}
    </Column>
  );
};

const CanDeploy: FC = () => (
  <Row alignItems="center" gap={1}>
    <SuccessIcon color="success.base" />
    <Text>All checks passing</Text>
  </Row>
);

const NeedsLinkingDeploy: FC<{ resourceName: string }> = ({ resourceName }) => (
  <Row alignItems="center" gap={1}>
    <LinkIcon color="text.secondary" />
    <Text>No linked {resourceName}</Text>
  </Row>
);

const NoopDeploy: FC = () => (
  <Row alignItems="center" gap={1}>
    <SuccessIcon color="success.base" />
    <Text>Nothing to deploy</Text>
  </Row>
);

export const DeploymentTargetRadio: FC<{
  deployment: Deployment;
  targetWorkspace: TargetWorkspace;
  deploymentTest?: CanDeployModelsQuery["canDeploySegments"][0];
  getDeploymentDiff: (deployment: Deployment, sourceObj: any, targetObj: any) => DeploymentDiff[];
  isSelected: boolean;
  linkableResources: LinkableWorkspaceResourcesQuery["getLinkableResources"] | undefined;
  loading: boolean;
}> = ({ deployment, targetWorkspace, deploymentTest, getDeploymentDiff, isSelected, linkableResources, loading }) => {
  const dependentResourceType = deployment.resourceType === "segments" ? "connections" : "destinations";
  const dependentResourceName = deployment.resourceType === "segments" ? "source" : "destination";

  const isFailedTest = deploymentTest?.__typename === "FailedDeploymentTestResult";

  const isFatalError =
    isFailedTest &&
    deploymentTest.results.some((result) =>
      Boolean(result && result.__typename === "FailedDeploymentValidationResult" && result.fatal),
    );

  const isNoop =
    !isFailedTest &&
    deploymentTest?.type === DeploymentOperation.Update &&
    getDeploymentDiff(deployment, deploymentTest.sourceObj, deploymentTest.targetObj).length === 0;

  const isLinkable =
    isFailedTest &&
    deploymentTest?.results.every(
      (result) => result?.__typename === "FailedDeploymentValidationResult" && result?.reason === "UnlinkedDependency",
    );

  const hasLinkableResources = linkableResources?.some(
    (r) => String(r.workspace.id) === String(targetWorkspace?.id) && !r.existing_link,
  );

  let description;

  if (isNoop) {
    description = <NoopDeploy />;
  } else if (!isFailedTest) {
    description = <CanDeploy />;
  } else if (isLinkable) {
    description = <NeedsLinkingDeploy resourceName={dependentResourceName} />;
  } else if (isFatalError) {
    description = <CantDeploy deployment={deployment} results={deploymentTest?.results} targetWorkspace={targetWorkspace} />;
  } else {
    description = (
      <DeployWithCaution deployment={deployment} results={deploymentTest?.results} targetWorkspace={targetWorkspace} />
    );
  }

  return (
    <Column>
      <Radio
        value={targetWorkspace.id}
        isDisabled={(isFailedTest && !isLinkable) || isNoop}
        label={targetWorkspace.name}
        description={description}
      />
      {isLinkable &&
        isSelected &&
        (hasLinkableResources ? (
          <Column border="1px" borderColor="base.border" borderRadius="md" p={4} ml={6} mt={2} mb={4}>
            <ResourceLinkForm
              targetResources={linkableResources?.filter((r) => !r.existing_link) ?? []}
              sourceResourceId={deployment.metadata?.connectionId || deployment.metadata?.destinationId}
              loadingResources={loading}
              resourceName={dependentResourceName}
              resourceType={dependentResourceType}
              targetWorkspace={targetWorkspace}
            />
          </Column>
        ) : (
          <Alert
            type="warning"
            title={`No ${dependentResourceName} of the same type found in ${targetWorkspace.name}`}
            message={`In order to deploy this resource, you'll first need to create a ${dependentResourceName} of the same type in ${targetWorkspace.name}.`}
            ml={6}
            mt={2}
            mb={4}
          />
        ))}
    </Column>
  );
};
