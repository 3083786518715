import { Document } from "@tiptap/extension-document";
import History from "@tiptap/extension-history";
import { Paragraph } from "@tiptap/extension-paragraph";
import { Text } from "@tiptap/extension-text";
import { JSONContent } from "@tiptap/react";
import slackMessageParser, { Node, NodeType } from "slack-message-parser";

import { ContentSerializer, Plugins, Profile, ProfileProps } from "../common";
import { SlackMarkdown } from "../plugins/slack-markdown";
import { newTypeaheadPlugin } from "../plugins/typeahead";
import { newSuggestion } from "../plugins/typeahead/suggestion";
import { liquidDeserialize } from "./liquid";

const stringifyMarks = (marks: JSONContent["marks"]) => {
  return (
    marks
      ?.map((mark) => {
        if (mark.type === "code") return "`";
        if (mark.type === "bold") return "*";
        if (mark.type === "italic") return "_";
        if (mark.type === "strike") return "~";

        return "";
      })
      ?.join("") || ""
  );
};

const serialize = (content: JSONContent, newLineSeparator = "\n") => {
  let text = "";

  if (["doc", "paragraph"].includes(content.type as string)) {
    text += content.content?.map((content) => serialize(content, newLineSeparator)).join("") || "";
    if (content.type === "paragraph") text += newLineSeparator;
  } else if (content.type === "blockquote") {
    text += `${content.content?.map((content) => serialize(content, "\n&gt;")).join("") || ""}`;
  } else if (content.type === "codeBlock") {
    text += `\`\`\`${content.content?.map((content) => serialize(content, newLineSeparator)).join("") || ""}\`\`\``;
  } else if (content.type === "text") {
    // Make a copy because reverse mutates the array.  Also omitting link because it has a special format.
    // https://api.slack.com/reference/surfaces/formatting#linking-urls
    const marksCopy = [...(content.marks ?? [])].filter((mark) => mark.type !== "link");
    const linkMark = [...(content.marks ?? [])].find((mark) => mark.type === "link");

    if (linkMark) {
      text += `<${linkMark.attrs?.href}|${stringifyMarks(marksCopy)}${content.text || ""}${stringifyMarks(
        marksCopy.reverse(),
      )}>`;
    } else {
      text += `${stringifyMarks(marksCopy)}${content.text || ""}${stringifyMarks(marksCopy.reverse())}`;
    }
  } else if (content.type === "mention") {
    text += content.attrs?.id ? `<@${content.attrs.id}${content.attrs.label ? `|${content.attrs.label}` : ""}>` : "";
  } else if (content.type === "channel") {
    text += content.attrs?.id ? `<#${content.attrs.id}${content.attrs.label ? `|${content.attrs.label}` : ""}>` : "";
  } else if (content.type === "liquid") {
    text += content.attrs?.id ? `{{ ${content.attrs.id} }}` : "";
  }

  return text;
};

// https://api.slack.com/reference/surfaces/formatting#mentioning-users
export const slackSerializer: ContentSerializer = {
  serialize(content) {
    return serialize(content).replace(/\n$/, "");
  },
  deserialize(raw) {
    const content = String(raw) + "\n";

    return liquidDeserialize(content, {
      deserialize: (text) => parseNode(slackMessageParser(text)),
      merge: (contents) => {
        const merged: JSONContent = { type: "doc" };

        let collected: JSONContent[] | undefined;

        const collectToParagraph = (content: JSONContent) => {
          if (!["doc", "paragraph", "codeBlock"].includes(content.type as string)) {
            // Collect non-paragraph-like types into a paragraph content
            collected ??= [];
            collected.push(content);
          } else {
            merged.content ??= [];
            if (content.type === "paragraph") {
              merged.content.push({
                type: "paragraph",
                ...(collected && {
                  content: collected,
                }),
              });
            } else {
              merged.content.push(content);
            }

            collected = undefined;
          }
        };

        for (const { json } of contents) {
          if (json.type === "doc") {
            for (const content of json.content ?? []) collectToParagraph(content);
          } else {
            collectToParagraph(json);
          }
        }

        if (collected?.length) {
          merged.content ??= [];
          merged.content.push({ type: "paragraph", content: collected });
        }

        return merged;
      },
    });
  },
};

const parseNode = (node: Node): JSONContent[] | JSONContent => {
  if (node.type === NodeType.Root) {
    const contents: JSONContent[] = [];
    for (const child of node.children) {
      const content = parseNode(child);
      for (const c of Array.isArray(content) ? content : [content]) contents.push(c);
    }

    return { type: "doc", content: contents };
  }

  if (node.type === NodeType.UserLink) {
    return [{ type: "mention", attrs: { id: node.userID, label: node.label?.[0]?.source } }];
  } else if (node.type === NodeType.ChannelLink) {
    return [{ type: "channel", attrs: { id: node.channelID, label: node.label?.[0]?.source } }];
  } else if (node.type === NodeType.Code) {
    return { type: "text", text: node.text, marks: [{ type: "code" }] };
  } else if (node.type === NodeType.Bold) {
    const mergedMark = node.children && mergeJsonContentWithMarks(node.children.map((n) => parseNode(n)).flat());
    return parseText(mergedMark?.text || "", [{ type: "bold" }, ...(mergedMark?.marks || [])]);
  } else if (node.type === NodeType.Italic) {
    const mergedMark = node.children && mergeJsonContentWithMarks(node.children.map((n) => parseNode(n)).flat());
    return parseText(mergedMark?.text || "", [{ type: "italic" }, ...(mergedMark?.marks || [])]);
  } else if (node.type === NodeType.Strike) {
    const mergedMark = node.children && mergeJsonContentWithMarks(node.children.map((n) => parseNode(n)).flat());
    return parseText(mergedMark?.text || "", [{ type: "strike" }, ...(mergedMark?.marks || [])]);
  } else if (node.type === NodeType.Text) {
    // Text has no markers.
    return parseText(node.text, undefined);
  } else if (node.type === NodeType.URL) {
    const mergedMark = node.label && mergeJsonContentWithMarks(node.label.map((n) => parseNode(n)).flat());
    return parseText(mergedMark?.text ?? node.url, [{ type: "link", attrs: { href: node.url } }, ...(mergedMark?.marks || [])]);
  } else if (node.type === NodeType.PreText) {
    return { type: "codeBlock", content: [{ type: "text", text: node.text }], attrs: { language: null } };
  } else if (node.type === NodeType.Quote) {
    return { type: "blockquote", content: node.children?.map(parseNode) };
  }

  // Unhandled cases
  return {};
};

/**
 * This function parses text into json contents of paragraphs if any.
 * Paragraphs corresponds to new lines (`\n`).
 */
export const parseText = (text: string, marks: JSONContent["marks"]): JSONContent[] => {
  const parts = text.split("\n");

  // Just plain text, no paragraph.
  if (!parts?.length) return [{ type: "text", text, ...(marks && { marks }) }];

  const contents: JSONContent[] = [];
  for (const part of parts) {
    if (part !== "") contents.push({ type: "text", text: part, ...(marks && { marks }) });
    contents.push({ type: "paragraph" });
  }

  // There will always be an extra paragraph. If that's the case, just remove it.
  contents.pop();

  return contents;
};

const mergeJsonContentWithMarks = (jsonContents: JSONContent[]) => {
  if (!jsonContents.length) return undefined;

  const mergedContent: JSONContent = {};

  for (const jsonContent of jsonContents) {
    mergedContent.text ??= "";
    mergedContent.text += jsonContent.text || "";
    mergedContent.marks = [...(mergedContent.marks ?? []), ...(jsonContent.marks ?? [])];
  }

  return mergedContent;
};

export const liquidSlackProfile = (props: ProfileProps): Profile => ({
  serializer: slackSerializer,
  extensions: [
    Document,
    Text,
    Paragraph,
    newTypeaheadPlugin({ pluginKey: Plugins.LIQUID, suggestionChar: "{{" }).configure({
      suggestion: newSuggestion({
        plugin: Plugins.LIQUID,
        handler: props.handler,
      }),
    }),
    newTypeaheadPlugin({ pluginKey: Plugins.MENTION, suggestionChar: "@" }).configure({
      suggestion: newSuggestion({
        plugin: Plugins.MENTION,
        handler: props.handler,
      }),
    }),
    newTypeaheadPlugin({ pluginKey: Plugins.CHANNEL, suggestionChar: "#" }).configure({
      suggestion: newSuggestion({
        plugin: Plugins.CHANNEL,
        handler: props.handler,
      }),
    }),
    SlackMarkdown,
    History,
  ],
});
