import { FC, ReactNode } from "react";

import { Tooltip as ChakraTooltip, TooltipProps } from "@chakra-ui/tooltip";
import { Column, Paragraph, Row, Text } from "@hightouchio/ui";

import { FilterOption } from "src/components/explore/filter-popover/constants";
import { getIconAndColor } from "src/components/explore/filter-popover/utils";

const MAX_DATA_TOOLTIP_WIDTH = 216;

type DataTooltipProps = {
  children: ReactNode;
  description?: string | null;
  gutter?: number;
  isDisabled?: boolean;
  label: string;
  subtitle?: ReactNode;
  title: FilterOption;
};

export const DataTooltip: FC<Readonly<DataTooltipProps & TooltipProps>> = ({
  children,
  description,
  gutter = 8,
  isDisabled = false,
  label,
  subtitle,
  title,
  ...props
}) => {
  const { color, icon } = getIconAndColor(title) ?? {};

  return (
    <ChakraTooltip
      isDisabled={isDisabled}
      placement="right"
      bg="text.primary"
      borderRadius="md"
      gutter={gutter}
      p={3}
      label={
        <Column gap={1} minWidth={`${MAX_DATA_TOOLTIP_WIDTH}px`} width="min-content" fontWeight="normal">
          <Column>
            <Row
              as={Text}
              align="center"
              justify="flex-start"
              color={color}
              fontWeight="semibold"
              gap={0.5}
              textTransform="uppercase"
              size="sm"
              sx={{ svg: { height: "12px", width: "12px" } }}
            >
              {icon}
              {title}
            </Row>
            {subtitle}
          </Column>
          <Column align="flex-start" sx={{ span: { textAlign: "left" } }}>
            <Text color="white" fontWeight="medium" size="lg" wordBreak="break-all">
              {label}
            </Text>
            <Paragraph color="base.border" size="sm" textAlign="start" wordBreak="break-all">
              {description}
            </Paragraph>
          </Column>
        </Column>
      }
      openDelay={500}
      motionProps={{
        initial: {
          scale: 0.9,
          opacity: 0,
        },
        variants: {
          enter: {
            scale: 1,
            opacity: 1,
            transition: {
              type: "spring",
              damping: 30,
              stiffness: 1000,
            },
          },
          exit: {
            scale: 0.9,
            opacity: 0,
            transition: {
              type: "spring",
              duration: 0.15,
            },
          },
        },
      }}
      {...props}
    >
      {children}
    </ChakraTooltip>
  );
};
