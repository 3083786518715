import { Dispatch, FC, SetStateAction, useEffect } from "react";

import { RadioGroup as HightouchUiRadioGroup, Radio, useToast, Box } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import { FormkitProvider, useFormkitContext } from "src/formkit/components/formkit-context";
import { SourceDefinition, useFormkitSourceDefinitionQuery, useFormkitSourceValidationQuery } from "src/graphql";
import { Column } from "src/ui/box";
import { RadioGroup } from "src/ui/radio";

import { IPWhitelistMessage } from "../ip-whitelist-message";
import { FormMethod, FormMethodProps, Section, Step, SyncEngineProps } from "./form-method";
import { OAuthMethod, OAuthMethodProps } from "./oauth-method";

export type FormProps = {
  sourceId: string | undefined;
  definition: SourceDefinition;
  tunnelId: string | null | undefined;
  setTunnelId: (tunnelId: string | null | undefined) => void;
  config: Record<string, unknown> | undefined;
  setConfig: Dispatch<SetStateAction<Record<string, unknown> | undefined>>;
  credentialId: string | undefined;
  setCredentialId: (credential: string) => void;
  hideSecret?: boolean;
  onSubmit?: () => Promise<void>;
  /**Whether to disable authentication method radio selection. */
  disableAuthMethod?: boolean;
  /**Function called whenever OAuth 'connect' button is clicked. */
  onConnectClick?(defintion: SourceDefinition): void;
  isSetup: boolean;
} & SyncEngineProps;

export const SourceForm: FC<Readonly<FormProps>> = ({
  definition,
  setConfig,
  config,
  sourceId,
  isSetup,
  tunnelId,
  setTunnelId,
  credentialId,
  setCredentialId,
  onSubmit,
  disableAuthMethod,
  onConnectClick,
  lightningEnabled,
  setLightningEnabled,
  plannerDatabase,
  setPlannerDatabase,
  hasSetupLightning,
}) => {
  const client = useQueryClient();
  const { toast } = useToast();
  const { data } = useFormkitSourceDefinitionQuery({ type: definition?.type }, { enabled: true });

  const setupMethods = data?.formkitSourceDefinition;
  const context = {
    sourceDefinition: definition,
    sourceId,
    isSetup,
    tunnel: tunnelId,
    credentialId,
  };

  const validate = async (config, context) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitSourceValidationQuery.fetcher({
        type: context.sourceDefinition?.type,
        config,
        context,
      }),
      queryKey: useFormkitSourceValidationQuery.getKey({ ...config, ...context }),
    });

    return response.formkitSourceValidation;
  };

  const methods = useForm();

  const { methodKey, ...configWithoutMethodKey } = config ?? {};

  const setMethodKey = (methodKey: string) => {
    setConfig({ methodKey });
  };

  const setConfigWithoutMethodKey = (value: Record<string, unknown>) => {
    setConfig((prev) => ({ ...value, methodKey: prev?.methodKey }));
  };

  useEffect(() => {
    // XXX: Make sure that the config is not already set before we reset it.
    if (configWithoutMethodKey && Object.keys(configWithoutMethodKey).length) {
      methods.reset(configWithoutMethodKey, { keepDefaultValues: true });
    }
  }, [Boolean(config)]);

  useEffect(() => {
    // Always setup the setup method to the first one.
    const initialSetupMethod = setupMethods?.[0];

    if (initialSetupMethod && !config?.methodKey) {
      setConfig((prev) => ({ ...prev, methodKey: initialSetupMethod.key }));
    }
  }, [config, definition.type, setupMethods?.length]);

  useEffect(() => {
    // XXX: This useEffect watches fields and sets config state in the parent.
    // We use this pattern because (unlike the sync form) the parent component
    // needs to know the config to render specific UI elements (like the continue
    // button) and for repeat testing in the testing step.

    const fieldWatcher = methods.watch((value) => {
      setConfigWithoutMethodKey(value);
    });
    return () => fieldWatcher.unsubscribe();
  }, [methods.watch]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (setupMethods?.length === 0) {
      if (typeof onSubmit === "function") {
        return await onSubmit();
      }

      return;
    }

    const errors = await validate(config, context);
    if (typeof errors === "object" && Object.keys(errors).length) {
      methods.clearErrors();
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });

      toast({
        id: "save-source-config",
        title: "There is an error in your source configuration",
        variant: "error",
      });
    } else {
      if (typeof onSubmit === "function") {
        await onSubmit();
      }
    }
  };

  const { appUseHightouchUiInSourceForms } = useFlags();

  if (!setupMethods || !Array.isArray(setupMethods)) return null;

  return (
    <FormkitProvider {...context} useHightouchUi={appUseHightouchUiInSourceForms} validate={validate}>
      <FormProvider {...methods}>
        <SelectedMethodForm
          config={config}
          credentialId={credentialId}
          definition={definition}
          disableAuthMethod={disableAuthMethod}
          hasSetupLightning={hasSetupLightning}
          isSetup={isSetup}
          lightningEnabled={lightningEnabled}
          methodKey={String(methodKey)}
          plannerDatabase={plannerDatabase}
          setCredentialId={setCredentialId}
          setLightningEnabled={setLightningEnabled}
          setMethodKey={setMethodKey}
          setPlannerDatabase={setPlannerDatabase}
          setTunnelId={setTunnelId}
          setupMethods={setupMethods}
          sourceId={sourceId}
          tunnelId={tunnelId}
          onConnectClick={onConnectClick}
        />
        {definition.supportsIpFiltering && (
          <Box>
            <IPWhitelistMessage />
          </Box>
        )}
        <form hidden id="source-form" onSubmit={handleSubmit} />
      </FormProvider>
    </FormkitProvider>
  );
};

type SelectedMethodFormProps = OAuthMethodProps &
  FormMethodProps & {
    methodKey: string | undefined;
    setMethodKey: (methodKey: string) => void;
    disableAuthMethod: boolean | undefined;
  };

const SelectedMethodForm: FC<Readonly<SelectedMethodFormProps>> = (props) => {
  const { setupMethods, config, disableAuthMethod, methodKey, setMethodKey } = props;
  const selectedMethod = setupMethods?.find((o) => o.key === config?.methodKey);
  const { useHightouchUi } = useFormkitContext();

  return (
    <>
      {setupMethods.length > 1 && useHightouchUi && (
        <Box borderBottom="1px" borderColor="gray.300" mb={8}>
          <Step
            hasMultipleSetupMethods={false}
            section={Section.AuthMethod}
            sections={[Section.AuthMethod, Section.Form]}
            title="Choose an authentication method"
          >
            <HightouchUiRadioGroup
              isDisabled={disableAuthMethod}
              orientation="vertical"
              value={methodKey}
              onChange={setMethodKey}
            >
              {setupMethods.map((setupMethod) => (
                <Radio key={setupMethod.key} label={setupMethod.label || capitalize(setupMethod.key)} value={setupMethod.key} />
              ))}
            </HightouchUiRadioGroup>
          </Step>
        </Box>
      )}

      {setupMethods.length > 1 && !useHightouchUi && (
        <Column sx={{ borderBottom: "small", mb: 8 }}>
          <Step
            // Add an extra section to make it show the Step
            hasMultipleSetupMethods={false}
            section={Section.AuthMethod}
            sections={[Section.AuthMethod, Section.Form]}
            title="Choose an authentication method"
          >
            <RadioGroup
              disabled={disableAuthMethod}
              options={setupMethods.map((o) => ({ label: o.label || capitalize(o.key), value: o.key }))}
              value={methodKey}
              onChange={(v) => setMethodKey(v)}
            />
          </Step>
        </Column>
      )}
      {selectedMethod?.method === "form" ? (
        <FormMethod {...props} />
      ) : selectedMethod?.method === "oauth" ? (
        <OAuthMethod {...props} />
      ) : null}
    </>
  );
};
