import {
  isIntervalType,
  isTimeRangeType,
  RelativeDirection,
  TimeRangeValue,
  TimeType,
} from "@hightouch/lib/query/visual/types";
import { Box, Row, Text } from "@hightouchio/ui";
import { isEqual } from "lodash";
import pluralize from "pluralize";

import {
  ColumnReference,
  ColumnType,
  FilterableColumn,
  getInitialTraitColumn,
  IntervalValue,
  isRelatedColumn,
  isTraitColumn,
  OperatorsWithoutValue,
  PropertyCondition,
  RawSqlTraitConfig,
  RelatedColumn,
  TraitDefinition,
  TraitType,
} from "src/types/visual";
import { TraitIcon } from "src/ui/icons";
import { ordinalSuffix } from "src/utils/numbers";
import { formatDateOrDatetime } from "src/utils/time";

interface ColumnOption {
  value: any;
  label: string;
  type: ColumnType;
  case_sensitive: boolean | null;
}

interface TraitOption {
  value: RelatedColumn;
  label: string;
  operator: "exists";
  type: ColumnType | undefined;
  render: () => JSX.Element;
}

export const getColumnFromValue = (options: (ColumnOption | TraitOption)[], column: any) => {
  return options.find(({ value }) => column === value);
};

export const getColumnOptions = (columns: FilterableColumn[], isDeprecatedPropertyCondition?: boolean): ColumnOption[] => {
  return columns.map(({ alias, name, type, custom_type, column_reference, case_sensitive }) => ({
    value: isDeprecatedPropertyCondition ? getPropertyNameFromColumn(column_reference) : column_reference,
    label: alias || name,
    type: (custom_type || type) as ColumnType,
    case_sensitive,
  }));
};

export const getModelIdFromColumn = (column: ColumnReference) => {
  if (!column) {
    return null;
  }
  if (column.type === "raw") {
    return column.modelId;
  } else if (column.type === "related") {
    return getModelIdFromColumn(column.column);
  }
};

export const getPropertyNameFromColumn = (column: ColumnReference | null) => {
  if (!column) {
    return null;
  }
  if (column.type === "raw") {
    return column.name;
  } else if (column.type === "related") {
    return getPropertyNameFromColumn(column.column);
  } else if (column.type === "trait") {
    return null;
  }
};

export const formatValue = (condition: PropertyCondition, value: any) => {
  if (OperatorsWithoutValue.includes(condition.operator)) {
    return null;
  }
  if (condition.propertyOptions?.parameterize) {
    return <Text fontWeight="medium">parameterized</Text>;
  }
  if (value === null) {
    return <Text fontWeight="medium">any value</Text>;
  }
  if (Array.isArray(value)) {
    return (
      <Text isTruncated fontWeight="medium">
        {value.map(String).join(", ")}
      </Text>
    );
  }
  if (condition.propertyType === ColumnType.Date || condition.propertyType === ColumnType.Timestamp) {
    if (typeof value === "object") {
      return condition.propertyType === ColumnType.Date
        ? getDateValue(value, condition.timeType)
        : getTimeValue(value, condition.timeType);
    }

    return <AbsoluteTimeText hideTime={condition.propertyType === ColumnType.Date} value={value} />;
  }
  if (condition.propertyType === ColumnType.Number && condition.propertyOptions?.percentile && value) {
    return (
      <Text isTruncated>
        {String(value)}
        {ordinalSuffix(value)} percentile
      </Text>
    );
  }

  return <Text isTruncated>{String(value)}</Text>;
};

export const getTraitPropertyType = (trait: TraitDefinition): ColumnType | undefined => {
  if (
    trait.type === TraitType.MostFrequent ||
    trait.type === TraitType.LeastFrequent ||
    trait.type === TraitType.First ||
    trait.type === TraitType.Last
  ) {
    const columnReference = trait.config.toSelect;
    const column = trait.relationship.to_model.filterable_audience_columns.find(({ column_reference }) =>
      isEqual(column_reference, columnReference),
    );
    return column?.type as ColumnType | undefined;
  } else if (trait.type === TraitType.Count || trait.type === TraitType.Sum || trait.type === TraitType.Average) {
    return ColumnType.Number;
  } else if (trait.type === TraitType.RawSql) {
    return (trait.config as RawSqlTraitConfig).resultingType;
  } else {
    return undefined;
  }
};

export const getTraitOptions = (
  property: PropertyCondition["property"],
  traits: TraitDefinition[] | undefined,
): TraitOption[] => {
  if (traits) {
    return traits
      .filter((trait) => Boolean(trait.type))
      .map((trait) => ({
        label: trait.name,
        value:
          isRelatedColumn(property) && isTraitColumn(property.column) && property.column.traitDefinitionId === trait.id
            ? property
            : getInitialTraitColumn(trait),
        operator: "exists",
        type: getTraitPropertyType(trait),
        render: () => (
          <>
            <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
            <Text whiteSpace="nowrap">{trait.name}</Text>
          </>
        ),
      }));
  }
  return [];
};

export const isIntervalNow = (intervalValue: IntervalValue) => {
  return intervalValue.quantity === 0;
};

export const isCompletedInterval = (intervalValue: IntervalValue) => {
  return intervalValue.interval && intervalValue.quantity != null;
};

export const isCompletedTimeRange = (timeRangeValue: TimeRangeValue) => {
  const { before, after } = timeRangeValue;
  return [before, after].every((v) => typeof v === "string" || (isIntervalType(v) && isCompletedInterval(v)));
};

export const AbsoluteTimeText = ({ value, hideTime }: { value: any; hideTime?: boolean }) => {
  return (
    <Text isTruncated fontWeight="medium">
      {formatDateOrDatetime(value, !hideTime)}
    </Text>
  );
};

export const RelativeTimeText = ({ value, isFunnelCondition }: { value: any; isFunnelCondition?: boolean }) => {
  if (isIntervalNow(value)) {
    return <Text fontWeight="medium">now</Text>;
  }

  return (
    <Box display="flex">
      <Text fontWeight="medium">{pluralize(value.interval, value.quantity, true)}</Text>
      {!isFunnelCondition && (
        <Text fontWeight="medium" ml={1}>
          {getRelativeDirectionLabel(value)}
        </Text>
      )}
    </Box>
  );
};

const getDateValue = (value: any, timeType: TimeType | undefined, funnelCondition?: boolean) => {
  return getTimeValue(value, timeType, funnelCondition, true);
};

const TimeText = ({
  value,
  timeType,
  hideTime,
  isFunnelCondition,
}: {
  value: any;
  timeType: TimeType | undefined;
  hideTime?: boolean;
  isFunnelCondition?: boolean;
}) => {
  if (timeType === TimeType.Absolute) {
    return <AbsoluteTimeText hideTime={hideTime} value={value} />;
  } else {
    return <RelativeTimeText isFunnelCondition={isFunnelCondition} value={value} />;
  }
};

export const getRelativeDirectionLabel = (value: IntervalValue) => {
  if (value.direction === RelativeDirection.Forward) {
    return " from now";
  } else {
    return " ago";
  }
};

export const getTimeValue = (value: any, timeType: TimeType | undefined, isFunnelCondition?: boolean, hideTime?: boolean) => {
  if (isTimeRangeType(value) && isCompletedTimeRange(value)) {
    return (
      <Row>
        <TimeText hideTime={hideTime} isFunnelCondition={isFunnelCondition} timeType={timeType} value={value.before} />
        <Text fontWeight="medium" mx={1}>
          and
        </Text>
        <TimeText hideTime={hideTime} isFunnelCondition={isFunnelCondition} timeType={timeType} value={value.after} />
      </Row>
    );
  }

  if ((isIntervalType(value) && isCompletedInterval(value)) || timeType === TimeType.Absolute) {
    return <TimeText hideTime={hideTime} isFunnelCondition={isFunnelCondition} timeType={timeType} value={value} />;
  }

  return null;
};
