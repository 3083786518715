import { useEffect } from "react";

import { useUser } from "src/contexts/user-context";

export const Intercom = () => {
  const { user, featureFlags } = useUser();

  useEffect(() => {
    if (user && !featureFlags?.intercom_disabled) {
      const APP_ID = import.meta.env.VITE_INTERCOM_APP_ID ?? "";

      (window as any).intercomSettings = {
        app_id: APP_ID,
        name: user?.name, // Full name
        email: user?.email, // Email address
        user_id: user?.id, // current_user_id
      };
      (function () {
        const w = window as any;
        const ic = w.Intercom as any;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          const d = document;
          const i = function (...rest) {
            i.c(...rest);
          } as any;
          i.q = [];
          i.c = function (args) {
            i.q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + APP_ID;
            const x = d.getElementsByTagName("script")[0];
            x?.parentNode?.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }
  }, [!!user, featureFlags]);

  return null;
};

export const newIntercomMessage = (message: string) => {
  window.Intercom?.("showNewMessage", message);
};

const intercomPadding = 20;
export const updateIntercomPosition = (position: number) => {
  window.Intercom?.("update", { ...(window.intercomSettings || {}), horizontal_padding: position + intercomPadding });
};

export const hideIntercom = () => {
  window.Intercom?.("update", {
    hide_default_launcher: true,
  });
};
