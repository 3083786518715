import { FC, ReactNode, useEffect, useState } from "react";

import { Drawer, DrawerContent, DrawerOverlay } from "@chakra-ui/react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import { IconButton, Tooltip } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";

import { updateIntercomPosition } from "src/lib/intercom";

import { Deployment } from "./common";
import { ModelDeploymentWizard } from "./model-deployment-wizard";
import { SyncDeploymentWizard } from "./sync-deployment-wizard";

interface DeployButtonProps {
  isDisabled: boolean;
  deployment: Deployment;
}

export const DeployButton: FC<DeployButtonProps> = ({ deployment, isDisabled }) => {
  const { deployments: deploymentsEnabled } = useFlags();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => {
    setIsOpen(false);
  };

  const resourceName = deployment.resourceName.toLowerCase();

  if (!deploymentsEnabled) {
    return null;
  }

  return (
    <>
      <Tooltip message={deployment.isDraft ? `You cannot deploy a draft ${resourceName}` : `Deploy your ${resourceName}`}>
        <IconButton
          variant="secondary"
          aria-label="deploy"
          icon={ArrowUpTrayIcon}
          onClick={() => setIsOpen(true)}
          isDisabled={isDisabled}
        />
      </Tooltip>
      <DeploymentDrawer isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          {deployment.resourceType === "segments" ? (
            <ModelDeploymentWizard deployment={deployment} onClose={onClose} />
          ) : (
            <SyncDeploymentWizard deployment={deployment} onClose={onClose} />
          )}
        </DrawerContent>
      </DeploymentDrawer>
    </>
  );
};

const DeploymentDrawer: FC<{ children: ReactNode; isOpen: boolean }> = ({ children, isOpen }) => {
  useEffect(() => {
    if (isOpen) {
      updateIntercomPosition(672);
    } else {
      updateIntercomPosition(0);
    }
    return () => {
      updateIntercomPosition(0);
    };
  }, [isOpen]);

  // TODO: Add support for this drawer type to Hightouch UI
  // Global styles are a workaround for this bug https://github.com/chakra-ui/chakra-ui/issues/2893
  return (
    <Drawer
      size="lg"
      isOpen={isOpen}
      onClose={() => {}}
      placement="right"
      closeOnEsc={false}
      closeOnOverlayClick={false}
      trapFocus={false}
    >
      <DrawerOverlay />
      <DrawerContent>{children}</DrawerContent>
    </Drawer>
  );
};
