import { FC, useState } from "react";

import {
  Box,
  BoxProps,
  ChevronDownIcon,
  ConfirmationDialog,
  OverflowMenuIcon,
  Paragraph,
  Row,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/react";
import pluralize from "pluralize";

import {
  ResourcePermissionGrant,
  useDeleteFolderMutation,
  useUpdateFoldersMutation,
  useUpdateModelsWhereMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import { FolderIcon } from "src/ui/icons";
// eslint-disable-next-line no-restricted-imports
import { Menu } from "src/ui/menu";
import { TextWithTooltip } from "src/ui/text";

import { AddFolder } from "./add-folder";
import { EditFolder } from "./edit-folder";
import { Folder } from "./types";

interface FolderProps {
  isSelected: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  count: number | undefined;
  name: string;
  depth: number;
  onClick: () => void;
  icon?: JSX.Element;
  bg?: BoxProps["bg"];
  folder?: Folder;
  parentFolder?: Folder;
  setSelectedFolder: (folder: string | null) => void;
}

export const IndividualFolder: FC<FolderProps> = ({
  count,
  name,
  depth,
  icon,
  onClick,
  isSelected,
  bg,
  folder,
  parentFolder,
  setSelectedFolder,
  isOpen,
  setIsOpen,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [updatingFolderOpen, setUpdatingFolderOpen] = useState(false);
  const [addingChild, setAddingChild] = useState(false);

  const { mutateAsync: updateSegment } = useUpdateModelsWhereMutation();
  const { mutateAsync: updateFolders } = useUpdateFoldersMutation();
  const { mutateAsync: deleteFolder } = useDeleteFolderMutation();
  const { toast } = useToast();

  const hasMenu = folder?.parentId !== undefined && !!folder;

  const parentFolderName = parentFolder?.name || (folder?.type === "audiences" ? "All audiences" : "All models");

  const getDeletionBody = () => {
    if (folder?.count) {
      return (
        <Text>
          Removing the folder {name} will cause{" "}
          <Text fontWeight="semibold">
            {count} {pluralize(folder.type, count)}
          </Text>{" "}
          to be moved to the parent folder <Text fontWeight="semibold">{parentFolderName}</Text>
        </Text>
      );
    }

    if (folder?.children.length) {
      return (
        <Text>
          Removing the folder {name} will cause{" "}
          <Text fontWeight="semibold">
            {folder.children.length} {pluralize("folder", folder.children.length)}
          </Text>{" "}
          to be moved to the parent folder <Text fontWeight="semibold">{parentFolderName}</Text>
        </Text>
      );
    }

    return "There are no resource or child folders in this folder.";
  };

  const onConfirmDelete = async () => {
    // typeguard.
    if (!folder?.id) {
      return;
    }

    try {
      // move all the segments to the parent folder.
      await updateSegment({
        where: {
          folder_id: { _eq: folder.id },
        },
        input: {
          folder_id: folder?.parentId,
        },
      });

      // move the child folders to the parent folder.
      await updateFolders({
        ids: folder?.children.map((child) => child.id) || [],
        object: {
          parent_id: folder?.parentId,
        },
      });

      // delete the folder.
      await deleteFolder({
        id: folder?.id,
      });

      toast({
        id: "delete-folder",
        title: "Folder was deleted",
        variant: "success",
      });
    } catch (err) {
      toast({
        id: "delete-folder",
        title: "There was an error deleting the folder",
        variant: "error",
      });

      Sentry.captureException(err);
    }

    setSelectedFolder(parentFolder?.id || null);
  };

  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "workspace", grants: [ResourcePermissionGrant.Update] },
  ]);

  const iconNode = icon || <FolderIcon size={20} />;

  return (
    <Row
      _hover={{
        cursor: "pointer",
        bg: isSelected ? undefined : "base.background",
        ".action-button": {
          display: "block",
        },
        ".count": {
          display: hasMenu && userCanUpdate ? "none" : undefined,
          color: isSelected ? undefined : "text.secondary",
        },
      }}
      align="center"
      borderRadius="md"
      border="1px"
      borderColor={isSelected ? "primary.border" : "transparent"}
      bg={bg || isSelected ? "forest.background" : undefined}
      cursor="pointer"
      pl={`${8 + (depth + 1) * 22}px`}
      pr={3}
      py={1}
      transition="border-color 0.1s, background-color 0.1s, box-shadow 0.1s"
      width="100%"
      onClick={onClick}
    >
      {setIsOpen ? (
        !folder || folder.children.length ? (
          <Box
            _hover={{ bg: "gray.300" }}
            height="20px"
            borderRadius="sm"
            color={isSelected ? "primary.pressed" : "gray.500"}
            transform={isOpen ? undefined : "rotate(-90deg)"}
            transition="transform 0.1s"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
            }}
          >
            <Box as={ChevronDownIcon} height="20px" width="20px" />
          </Box>
        ) : (
          <Box flexShrink={0} width={5} />
        )
      ) : null}
      <Box flexShrink={0} width="16px" color={isSelected ? "primary.pressed" : "gray.500"} mr={2}>
        {iconNode}
      </Box>
      <TextWithTooltip
        message={name}
        placement="top"
        fontWeight={folder ? "normal" : "medium"}
        color={isSelected ? "primary.pressed" : "text.primary"}
        size="sm"
      >
        {name}
      </TextWithTooltip>
      <Row minW={5} justify="flex-end" ml="auto" flexShrink={0}>
        {!menuOpen && (
          <Box as={Text} color={isSelected ? "primary.pressed" : "text.secondary"} className="count" flexShrink={0} size="sm">
            {count}
          </Box>
        )}
        {hasMenu && userCanUpdate && (
          <Box
            className="action-button"
            sx={{
              display: menuOpen ? "block" : "none",
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            <Menu
              options={[
                {
                  label: "Edit folder",
                  onClick: () => setUpdatingFolderOpen(true),
                },
                {
                  label: "Add subfolder",
                  onClick: () => setAddingChild(true),
                },
                {
                  label: "Remove folder",
                  variant: "danger",
                  onClick: () => {
                    setDeleteConfirmOpen(true);
                  },
                },
              ]}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMenuOpen(true);
              }}
              onClose={() => setMenuOpen(false)}
            >
              <Box
                _hover={{ bg: "gray.300" }}
                height="20px"
                color={isSelected ? "primary.pressed" : "text.secondary"}
                borderRadius="sm"
              >
                <Box as={OverflowMenuIcon} height="20px" width="20px" />
              </Box>
            </Menu>
          </Box>
        )}
      </Row>
      <ConfirmationDialog
        confirmButtonText="Remove"
        isOpen={deleteConfirmOpen}
        title="Remove folder"
        variant="danger"
        onClose={() => setDeleteConfirmOpen(false)}
        onConfirm={onConfirmDelete}
      >
        <Paragraph>{getDeletionBody()}</Paragraph>
      </ConfirmationDialog>

      {folder?.type && updatingFolderOpen && <EditFolder folder={folder} onClose={() => setUpdatingFolderOpen(false)} />}

      {folder && addingChild && (
        <AddFolder
          toggleDisabled
          defaultParentFolder={folder.id}
          folderType={folder.type as "audiences" | "models"}
          viewType="models"
          onSave={(folder) => {
            setSelectedFolder(folder);
          }}
          onClose={() => {
            setAddingChild(false);
          }}
        />
      )}
    </Row>
  );
};
