import { FC, useEffect, useState } from "react";

import { ArrowPathIcon, FolderOpenIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlayIcon } from "@heroicons/react/24/solid";
import {
  Alert,
  Button,
  Column,
  EditableDescription,
  Link,
  Menu,
  MenuActionsButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Pill,
  Row,
  SectionHeading,
  Spinner,
  Switch,
  Text,
  Tooltip,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEqual, isMatch } from "lodash";
import pluralize from "pluralize";
import { useNavigate, useParams } from "react-router-dom";

import { DeployButton } from "src/components/deployments/button";
import { DestinationForm } from "src/components/destinations/sync-form";
import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { GitActivity } from "src/components/git/git-activity";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { useLabels } from "src/components/labels/use-labels";
import { Page } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { MetadataBar, MetadataLabel } from "src/components/metadata-bar";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { OverageContentAlert } from "src/components/overage/overage-content-alert";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { Schedule, ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { DisplaySlug } from "src/components/slug/display-slug";
import { SplitsSyncAssignmentForm } from "src/components/splits/splits-sync-assignment-form";
import { Runs } from "src/components/syncs/runs";
import { SyncAlerts } from "src/components/syncs/sync-alerts";
import { SyncName } from "src/components/syncs/sync-name";
import { WarehouseSyncLogs } from "src/components/syncs/warehouse-sync-logs";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  ResourceToPermission,
  SyncDraft,
  SyncQuery,
  SyncQueryVariables,
  SyncRunsQuery,
  useDeleteSyncMutation,
  useExternalSegmentsQuery,
  useSequencesForSyncQuery,
  useStartSyncRunMutation,
  useSyncQuery,
  useUpdateSplitSyncAssignmentsMutation,
  useUpdateSyncMutation,
  useUpdateSyncRequestMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/ui/loading";
import { Table } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { getQueryWithOpts } from "src/utils/query-with-opts";
import { isScheduleComplete } from "src/utils/schedule";
import { SyncStatus, syncStatusIsTerminal } from "src/utils/syncs";
import { useQueryString } from "src/utils/use-query-string";

import { DestinationMetadataDrawer, processAudienceSize, processMatchRate } from "./external-data";
import { syncActivityMappers } from "./sync-activity";

enum Tab {
  RUNS = "Runs",
  CONFIGURATION = "Configuration",
  SCHEDULE = "Schedule",
  ALERTS = "Alerts",
  GIT_ACTIVITY = "Git activity",
  WAREHOUSE_SYNC_LOGS = "Sync logs",
  SPLITS = "Splits",
  ACTIVITY = "Activity",
}

const getDeleteSyncErrorMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") && error.message.includes("sync_sequence")
    ? "This sync cannot be deleted because it is used in one or more sequences"
    : error.message;
};

const useFastSyncQuery = getQueryWithOpts<SyncQuery, SyncQueryVariables>(useSyncQuery, { useFastEndpoint: true });

export const SyncWrapper: FC = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const {
    data: syncData,
    refetch,
    isLoading,
  } = useIncrementalQuery(
    useFastSyncQuery(
      {
        id: id ?? "",
        includeExternalFields: false,
      },
      {
        enabled: Boolean(id),
      },
    ),
    useSyncQuery(
      {
        id: id ?? "",
      },
      {
        enabled: Boolean(id),
      },
    ),
  );

  if (!id) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={syncData?.syncs?.[0]?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Sync}
    >
      <Sync refetchSync={refetch} syncData={syncData} syncLoading={isLoading} />
    </DraftProvider>
  );
};

interface SyncProps {
  syncData: Partial<SyncQuery> | undefined;
  syncLoading: boolean;
  refetchSync: () => void;
}

const Sync: FC<SyncProps> = ({ syncData, syncLoading, refetchSync }: SyncProps) => {
  const { workspace } = useUser();
  const navigate = useNavigate();
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const { toast } = useToast();
  const [schedule, setSchedule] = useState<any>();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.RUNS);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [cancelledSyncId, setCancelledSyncId] = useState<string>();
  const [showRun, setShowRun] = useState(true);
  const [runs, setRuns] = useState<SyncRunsQuery["sync_requests"] | undefined>();
  const [viewMetadata, setViewMetadata] = useState<boolean>(false);

  const flags = useFlags();
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);

  const { labels } = useLabels();

  const { mutateAsync: updateSync, isLoading: isSyncUpdating } = useUpdateSyncMutation();
  const { mutateAsync: forceRun } = useStartSyncRunMutation();
  const { mutateAsync: cancelSyncRequest } = useUpdateSyncRequestMutation();
  const { mutateAsync: deleteSyncById } = useDeleteSyncMutation();

  const sync = syncData?.syncs?.[0];
  const latestRun = runs?.filter((run) => run.sync_attempts?.length > 0)[0];
  const syncAttempt = latestRun?.sync_attempts?.[0];
  const syncAlerts = sync?.sync_alerts;
  const model = sync?.segment;
  const destination = sync?.destination;
  const source = model?.connection;
  const usesSyncTemplate = sync?.sync_template_id;
  const currentLabels = sync?.tags ?? {};
  const labelKeys = Object.keys(currentLabels);

  const isAudienceModel = model?.query_type === QueryType.Visual;
  const showSplitsTab = isAudienceModel;
  const splits = (sync?.destination_instance_splits ?? []).map(({ split }) => split);

  const updateSplitSyncAssignments = useUpdateSplitSyncAssignmentsMutation();

  const deleteSync = async () => {
    if (!id) {
      return;
    }

    try {
      await deleteSyncById({
        id,
      });

      toast({
        id: "delete-sync",
        title: "Sync deleted",
        variant: "success",
      });

      analytics.track("Sync Deleted", {
        sync_id: id,
        destination_type: destination?.definition?.name,
        schedule_type: schedule?.type,
        source_type: model?.connection?.type,
      });

      navigate("/syncs");
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "delete-sync",
        title: "Couldn't delete this sync",
        message: getDeleteSyncErrorMessage(error),
        variant: "error",
      });
    }
  };

  const saveSplitSyncAssignments = async (selectedSplitIds) => {
    // In order to do an update, we remove the existing destination_instance-split rows (for this sync)
    // and then insert the selected destination_instance-splits (for this sync)
    const removeObjects = splits.map((split) => ({
      split_id: { _eq: split.id },
      destination_instance_id: { _eq: sync?.id },
    }));

    const addObjects = selectedSplitIds.map((splitId: string) => ({
      split_id: splitId,
      destination_instance_id: sync?.id,
    }));

    try {
      await updateSplitSyncAssignments.mutateAsync({ addObjects, removeObjects });
    } catch (error) {
      toast({
        id: "change-split-sync-assignments",
        title: "There was an error saving your splits configuration.",
        variant: "error",
      });

      throw error;
    }

    toast({
      id: "change-split-sync-assignments",
      title: "Successfully updated split group assignments.",
      variant: "success",
    });
  };

  const { draft, editingDraft, editingDraftChanges, updateResourceOrDraft, setEditingDraft, onViewDraft } = useDraft();

  const newResource = draft?.new_resource as SyncDraft;
  const draftSchedule = newResource?._set?.schedule;

  const { data: externalSegmentsData } = useExternalSegmentsQuery({ syncId: sync?.id }, { enabled: !!sync });
  // We used to support multiple external segments per sync, where only one
  // would be valid at a time. When there is more than one, we only care about
  // the most recent, active one.
  const externalSegment = externalSegmentsData?.external_segments?.sort((a, b) => {
    return a.created_at > b.created_at ? -1 : 1;
  })?.[0];

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO: hookup once there are more than one overage.

  const { data: sequences, isLoading: sequencesLoading } = useSequencesForSyncQuery(
    { syncId: String(id) },
    { enabled: Boolean(id), select: (data) => data.sync_sequences },
  );

  const toggleSyncPause = async (enabled: boolean) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id,
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "toggle-sync-pause",
        title: `Sync was ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "toggle-sync-pause",
        title: `Sync could not be ${enabled ? "enabled" : "disabled"}`,
        message: error.message,
        variant: "error",
      });
    }

    refetchSync();
  };

  const startRun = async (resync = false) => {
    const { startSyncRun } = await forceRun({
      id: Number(id),
      full_resync: resync,
    });

    // Only render a popup message if we actually scheduled a sync.
    const scheduled = startSyncRun?.scheduled;
    analytics.track("Sync Manually Started", {
      sync_id: id,
      destination_type: destination?.definition?.name,
      schedule_type: schedule?.type,
      source_type: model?.connection?.type,
    });

    if (scheduled) {
      if (resync) {
        toast({
          id: "start-sync-run",
          title: "Resync will begin shortly",
          variant: "success",
        });
      } else {
        toast({
          id: "start-sync-run",
          title: "Manual run will begin shortly",
          variant: "success",
        });
      }
    }

    refetchSync();
  };

  const cancelRun = async () => {
    setCancelling(true);
    setCancelledSyncId(latestRun?.id);
    cancelSyncRequest({
      id: latestRun?.id,
      object: {
        trigger_cancel: true,
      },
    });

    refetchSync();
  };

  const TABS = [
    Tab.RUNS,
    newResource?._set?.config && editingDraft
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Configuration <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.CONFIGURATION,
        }
      : Tab.CONFIGURATION,
    showSplitsTab && Tab.SPLITS,
    !usesSyncTemplate &&
      (draftSchedule !== undefined && editingDraft
        ? {
            render: () => (
              <Row sx={{ alignItems: "center" }}>
                Schedule <DraftCircle sx={{ ml: 2 }} />
              </Row>
            ),
            value: Tab.SCHEDULE,
          }
        : Tab.SCHEDULE),
    // TODO: Feature flag
    Tab.ACTIVITY,
    !usesSyncTemplate && !editingDraftChanges && Tab.ALERTS,
    model?.git_sync_metadata && Tab.GIT_ACTIVITY,
    source?.definition?.supportsInWarehouseDiffing && !editingDraftChanges && Tab.WAREHOUSE_SYNC_LOGS,
  ].filter(Boolean);

  const running = syncAttempt?.status === SyncStatus.ACTIVE;

  const {
    data: { autorun },
  } = useQueryString();

  useEffect(() => {
    let autoRunTimeout: number | undefined | null;
    if (sync && autorun) {
      autoRunTimeout = window.setTimeout(() => {
        startRun();
      }, 400);
    }
    return () => {
      if (autoRunTimeout) {
        clearTimeout(autoRunTimeout);
        autoRunTimeout = null;
      }
    };
  }, [autorun, sync?.id]);

  const [description, setDescription] = useState(sync?.description ?? "");

  useEffect(() => {
    setDescription(sync?.description ?? "");
  }, [sync?.description]);

  useEffect(() => {
    if (sync?.schedule || sync?.schedule === null) {
      setSchedule(sync?.schedule ? sync.schedule : { type: ScheduleType.MANUAL });
    }
  }, [sync?.schedule, editingDraft]);

  useEffect(() => {
    setEnabled(!sync?.schedule_paused);
  }, [sync?.schedule_paused]);

  useEffect(() => {
    // if the latest run id no longer match the cancelled id then it means cancel is successful.
    if (syncAttempt?.status === SyncStatus.CANCELLED || cancelledSyncId !== latestRun?.id) {
      setCancelling(false);
    }
  }, [syncAttempt?.status, latestRun?.id, cancelledSyncId]);

  useEffect(() => {
    if (syncStatusIsTerminal(syncAttempt?.status as SyncStatus)) {
      setShowRun(true);
    }
  }, [syncAttempt?.status]);

  useEffect(() => {
    if (draft && editingDraft && draftSchedule !== undefined) {
      setSchedule(draftSchedule ? draftSchedule : { type: ScheduleType.MANUAL });
    }
  }, [editingDraft]);

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: id,
      destination_type: destination?.definition?.name,
      schedule_type: schedule?.type,
      source_type: model?.connection?.type,
    });

    if (!workspace?.approvals_required) {
      toast({
        id: "update-sync",
        title: "Sync was updated",
        variant: "success",
      });
    }

    refetchSync();
  };

  const updateConfig = async (config) => {
    if (!id || !sync) {
      return;
    }

    const updatePayload = {
      config: { ...config, configVersion: sync?.config?.configVersion },
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (updateResourceOrDraft) {
      await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        () =>
          updateSync({
            id,
            object: updatePayload,
          }),
        sync.draft || false,
      );
    }
  };

  const updateSchedule = async () => {
    if (!id || !sync) {
      return;
    }

    const updatePayload = {
      schedule: schedule?.type === "manual" ? null : schedule,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (updateResourceOrDraft) {
      await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        () =>
          updateSync({
            id,
            object: updatePayload,
          }),
        sync.draft || false,
      );
    }
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id: id,
        object: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      toast({
        id: "update-sync-labels",
        title: "Couldn't update labels",
        message: error.message,
        variant: "error",
      });
    }
  };

  const updateDescription = async (description: string) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id: id,
        object: {
          description,
        },
      });

      onUpdate();
    } catch (error) {
      toast({
        id: "update-sync-description",
        title: "Couldn't update description",
        message: error.message,
        variant: "error",
      });
    }
  };

  const hasPrimaryKeyIssue =
    model?.query_type === QueryType.Visual
      ? Boolean(model?.parent?.columns?.length && !model?.parent?.columns.some((c) => c.name === model?.parent?.primary_key))
      : Boolean(model?.columns?.length && !model?.columns.some((c) => c.name === model?.primary_key));

  const { hasPermission: userCanStart } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: id },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Delete], resource_id: id },
  ]);
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id },
  ]);
  const { hasPermission: userCanEnable } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Enable], resource_id: id },
  ]);

  const folder = useFolder({
    folderId: sync?.segment?.folder?.id || null,
    folderType: sync?.segment?.query_type === "visual" ? "audiences" : "models",
    viewType: "syncs",
  });

  // Since when we edit the draft, we set the schedule on the draft instead of the
  // actual resource, we need to check if there is a draft when we are setting the schedule
  // to the manual schedule type.
  const scheduleSaveDisabled =
    isEqual(schedule, sync?.schedule) ||
    (sync?.schedule === null && isMatch(schedule, { type: ScheduleType.MANUAL }) && !draft) ||
    (editingDraft && isEqual(draftSchedule, schedule)) ||
    !isScheduleComplete({ schedule, flags, workspace });

  const crumbs: Crumb[] = [{ label: "Syncs", link: "/syncs" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: "/syncs?folder=" + folder.id,
      });
    });
  }

  crumbs.push({
    label: "Sync",
  });

  const loading = syncLoading || sequencesLoading;

  if (!loading && !sync) {
    return <Warning subtitle="It may have been deleted" title="Sync not found" />;
  }

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: sync?.id }]}>
        <Page
          crumbs={crumbs}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Sync}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          sync={sync}
          title={`${model?.name ?? "Private model"} to ${
            destination?.name ?? destination?.definition?.name ?? "private destination"
          } - Syncs`}
        >
          <Row align="center" justify="space-between" width="100%">
            <SyncName destination={destination} model={model} source={source} sync={sync} />
            <Row gap={4}>
              <Row align="center" gap={2}>
                <Text textTransform="uppercase" size="sm" fontWeight="semibold" color="text.tertiary">
                  {enabled ? "Enabled" : "Disabled"}
                </Text>
                <Switch
                  isChecked={enabled}
                  isDisabled={!userCanEnable}
                  onChange={(value) => {
                    setEnabled(value);
                    toggleSyncPause(value);
                  }}
                />
              </Row>

              {(userCanUpdate || userCanStart || userCanDelete) && (
                <Menu>
                  <MenuActionsButton variant="secondary" />

                  <MenuList>
                    {userCanUpdate && (
                      <MenuItem
                        icon={FolderOpenIcon}
                        onClick={() => {
                          setIsFolderModalOpen(true);
                        }}
                      >
                        Move to folder
                      </MenuItem>
                    )}

                    {userCanUpdate && (
                      <MenuItem
                        icon={PencilIcon}
                        onClick={() => {
                          setIsEditLabelModalOpen(true);
                        }}
                      >
                        Edit labels
                      </MenuItem>
                    )}

                    {userCanStart && (
                      <MenuItem
                        icon={ArrowPathIcon}
                        isDisabled={running}
                        onClick={() => {
                          setShowRun(false);
                          startRun(true);
                        }}
                      >
                        Resync full query
                      </MenuItem>
                    )}

                    {(userCanUpdate || userCanStart) && userCanDelete && <MenuDivider />}

                    {userCanDelete && (
                      <MenuItem
                        icon={TrashIcon}
                        isDisabled={running}
                        variant="danger"
                        onClick={() => {
                          setDeleting(true);
                        }}
                      >
                        Delete
                      </MenuItem>
                    )}
                  </MenuList>
                </Menu>
              )}

              <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}>
                {sync && (
                  <DeployButton
                    isDisabled={Boolean(!sync.slug || sync.draft || draft)}
                    deployment={{
                      resourceName: "Sync",
                      sourceResourceId: sync.id,
                      resourceType: "destination_instances",
                      metadata: { destinationId: sync.destination?.id, modelId: sync.segment?.id },
                      isDraft: Boolean(sync.draft || draft),
                    }}
                  />
                )}
              </Permission>

              <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: id }]}>
                {running || cancelling ? (
                  <Button isDisabled={cancelling} onClick={cancelRun}>
                    <Spinner size="sm" mr={2} />
                    {cancelling ? "Canceling..." : "Cancel run"}
                  </Button>
                ) : (
                  <Tooltip message={overageText} isDisabled={!overageLockout}>
                    <Button
                      isDisabled={!showRun || overageLockout || !!sync?.draft}
                      icon={PlayIcon}
                      onClick={() => {
                        setShowRun(false);
                        startRun(false);
                      }}
                    >
                      Run
                    </Button>
                  </Tooltip>
                )}
              </Permission>
            </Row>
          </Row>
          <Row>
            <EditableDescription
              isDisabled={!userCanUpdate}
              value={description}
              onChange={setDescription}
              onSubmit={updateDescription}
            />
          </Row>

          <>
            <MetadataBar>
              <Column>
                <MetadataLabel>Sync ID</MetadataLabel>
                <Text>{sync?.id}</Text>
              </Column>
              <Column>
                <MetadataLabel>Schedule</MetadataLabel>
                <Schedule schedule={sync?.schedule} />
              </Column>
              {model?.query_type !== QueryType.Visual && (
                <Column>
                  <MetadataLabel mb="-2px">Slug</MetadataLabel>
                  <DisplaySlug currentSlug={sync?.slug} />
                </Column>
              )}
              {sync?.config?.mode && (
                <Column>
                  <MetadataLabel>Mode</MetadataLabel>
                  <Text textTransform="capitalize">{sync?.config?.mode}</Text>
                </Column>
              )}
              {sync?.destination_metadata && (
                <Column>
                  <MetadataLabel>External</MetadataLabel>
                  <Row align="center" gap={2}>
                    <Pill>
                      {sync.destination_metadata.matchRate
                        ? processMatchRate(sync.destination_metadata.matchRate)
                        : processAudienceSize(sync.destination_metadata.audienceSize)}
                    </Pill>
                    <Row
                      onClick={() => {
                        setViewMetadata(true);
                      }}
                    >
                      <Link href="">See more</Link>
                    </Row>
                  </Row>
                </Column>
              )}
              {!editingDraftChanges && labelKeys.length > 0 && (
                <Column>
                  <MetadataLabel>Labels</MetadataLabel>
                  <Labels labels={currentLabels} />
                </Column>
              )}
            </MetadataBar>

            <Column gap={6} width="100%">
              <Tabs setTab={(tab) => setTab(tab as Tab)} tab={tab} tabs={TABS} />
              <>
                {tab === Tab.RUNS && (
                  <>
                    {hasPrimaryKeyIssue && (
                      <Alert
                        type="warning"
                        title="Undefined primary key"
                        message="Without a primary key your syncs may fail or undefined behavior may occur. Go to your model and check that your primary key is set to a valid column in your model."
                        actions={
                          <Link
                            href={
                              model?.query_type === QueryType.Visual
                                ? flags.schemaV2
                                  ? `/schema-v2/view?source=${model?.parent?.connection?.id}&id=${model?.parent?.id}`
                                  : `/schema/parent-models/${model?.parent?.id}`
                                : `/models/${model?.id}`
                            }
                          >
                            Go to your model
                          </Link>
                        }
                      />
                    )}
                    {id && (
                      <Runs
                        setRuns={setRuns}
                        syncData={syncData}
                        syncId={id}
                        onRun={() => {
                          setShowRun(false);
                          startRun(false);
                        }}
                      />
                    )}
                  </>
                )}

                {tab === Tab.CONFIGURATION && overageLockout && <OverageContentAlert />}
                {tab === Tab.CONFIGURATION && !overageLockout && !usesSyncTemplate && model && source?.definition && (
                  <PermissionProvider
                    fallback={<Spinner m="auto" size="lg" />}
                    permissions={
                      workspace?.approvals_required
                        ? [] // when the workspace enables approvals, anyone can submit a draft.
                        : [{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id }]
                    }
                  >
                    {destination && destination?.definition && (
                      <DestinationForm
                        destination={destination}
                        destinationDefinition={destination.definition}
                        externalSegment={externalSegment}
                        model={model}
                        slug={destination?.definition?.type}
                        sourceDefinition={source.definition}
                        sync={sync}
                        onSubmit={updateConfig}
                      />
                    )}
                  </PermissionProvider>
                )}
                {tab === Tab.CONFIGURATION && !overageLockout && usesSyncTemplate && (
                  <Alert
                    type="info"
                    title="Sync template"
                    message="This sync uses a shared configuration template"
                    actions={
                      <Link
                        href={
                          flags.schemaV2
                            ? `/schema-v2/settings/sync-templates/${sync?.sync_template_id}`
                            : `/schema/sync-templates/${sync?.sync_template_id}`
                        }
                      >
                        Edit the template
                      </Link>
                    }
                  />
                )}

                {tab === Tab.SPLITS && showSplitsTab && (
                  <SplitsSyncAssignmentForm
                    audienceId={model?.id}
                    splitsAssignedToCurrentSync={splits}
                    onSubmit={saveSplitSyncAssignments}
                  />
                )}
                {tab === Tab.ACTIVITY && id && (
                  <ResourceActivityTimeline
                    primaryResource={{ mappers: syncActivityMappers, resource: "Sync", resourceId: String(id) }}
                  />
                )}

                {tab === Tab.SCHEDULE && overageLockout && <OverageContentAlert />}
                {tab === Tab.SCHEDULE && !overageLockout && (
                  <PermissionProvider
                    fallback={<Spinner m="auto" size="lg" />}
                    permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                  >
                    <Row width="100%" justify="space-between">
                      <Column width="100%">
                        {sequences && sequences.length > 0 && (
                          <Column>
                            <SectionHeading>Sequences</SectionHeading>
                            <Text mb={4} color="text.secondary">
                              This sync is currently being run by {sequences.length} {pluralize("sequence", sequences.length)}
                            </Text>
                            <Table
                              columns={[
                                { name: "Name", key: "name" },
                                {
                                  name: "Syncs",
                                  cell: ({ members }) => `${members.length} ${pluralize("sync", members.length)}`,
                                },
                              ]}
                              data={sequences}
                              showHeaders={false}
                              onRowClick={({ id }) => navigate(`/sequences/${id}`)}
                            />
                          </Column>
                        )}
                        <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
                        <Alert
                          mt={8}
                          type="info"
                          title="Sync triggers"
                          message={
                            <>
                              You can also trigger this sync via Airflow, Dagster, or Prefect. For more information view our{" "}
                              <Link href={`${import.meta.env.VITE_DOCS_URL}/extensions/airflow`}>Airflow Operator</Link>
                              {", "}
                              <Link href={`${import.meta.env.VITE_DOCS_URL}/extensions/dagster`}>Dagster</Link>
                              {", or "}
                              <Link href={`${import.meta.env.VITE_DOCS_URL}/extensions/prefect`}>Prefect docs</Link>. If you
                              need an API key, you can create one in <Link href="/settings/api-keys">Settings.</Link>
                            </>
                          }
                        />
                      </Column>
                      <SidebarForm
                        hideInviteTeammate
                        hideSendMessage
                        buttons={[
                          userCanUpdate ? (
                            <Button isDisabled={scheduleSaveDisabled} onClick={() => updateSchedule()}>
                              {workspace?.approvals_required ? "Save draft" : "Save"}
                            </Button>
                          ) : null,
                        ]}
                        docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/schedule-sync-ui/`}
                        invite="If you need help setting up this sync"
                        name="scheduling syncs"
                      />
                    </Row>
                  </PermissionProvider>
                )}

                {tab === Tab.ALERTS && id && (
                  <SyncAlerts
                    alerts={syncAlerts || []}
                    rowThresholdAttempted={sync?.row_threshold_attempted}
                    rowThresholdTotal={sync?.row_threshold_total}
                    syncId={Number(id)}
                  />
                )}

                {tab === Tab.GIT_ACTIVITY && <GitActivity id={id} />}

                {tab === Tab.WAREHOUSE_SYNC_LOGS && (
                  <WarehouseSyncLogs config={sync?.warehouse_history_config} id={id} source={source ?? undefined} />
                )}
              </>
            </Column>
          </>
        </Page>
      </PermissionProvider>

      <EditLabelModal
        description="You can label syncs that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={currentLabels}
        loading={isSyncUpdating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      {sync?.segment && isFolderModalOpen && (
        <MoveFolder
          folder={sync.segment.folder}
          folderType={sync.segment.query_type === "visual" ? "audiences" : "models"}
          modelIds={[sync.segment.id]}
          viewType="syncs"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <DestinationMetadataDrawer
        definition={destination?.definition}
        metadata={sync?.destination_metadata}
        isOpen={viewMetadata}
        onClose={() => {
          setViewMetadata(false);
        }}
      />

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sync"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={deleteSync}
      />
    </>
  );
};
