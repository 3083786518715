import { MouseEventHandler, useEffect, useMemo, useState } from "react";

import { ListItem, UnorderedList } from "@chakra-ui/react";
import { PlayIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Alert,
  Button,
  ButtonGroup,
  Column,
  EditableDescription,
  EditableHeading,
  Link,
  Menu,
  MenuActionsButton,
  MenuItem,
  MenuList,
  Row,
  Switch,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import groupBy from "lodash/groupBy";
import pluralize from "pluralize";
import { useNavigate, useParams } from "react-router-dom";
import { isPresent } from "ts-extras";

import { ActionBar } from "src/components/action-bar";
import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { DetailPage } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { Warning } from "src/components/warning";
import {
  ResourcePermissionGrant,
  useDeleteGoalMutation,
  useEnqueueGoalComputationMutation,
  useGoalQuery,
  useParentModelsQuery,
  useUpdateGoalMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { AggregationType, ConditionType, Goal } from "src/types/visual";
import { PageSpinner } from "src/ui/loading";
import { InfoModal } from "src/ui/modal";
import { formatDate } from "src/utils/time";

import { MetricForm } from "./metric-form";
import { MetricFormData, useMetricForm } from "./use-metric-form";

export const Metric = () => {
  const { metric_id: id } = useParams<{ metric_id?: string }>();
  const { toast } = useToast();
  const { appEnableGoals, schemaV2 } = useFlags();
  const navigate = useNavigate();

  const {
    isLoading,
    data: goal,
    refetch,
  } = useGoalQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id), select: (data) => data?.goals_by_pk },
  );

  const updateGoalMutation = useUpdateGoalMutation();
  const deleteGoalMutation = useDeleteGoalMutation();
  const enqueueGoalMutation = useEnqueueGoalComputationMutation();

  const { data: parentModel, isLoading: isParentLoading } = useParentModelsQuery(
    { filters: { id: { _eq: goal?.parent_model_id } } },
    { enabled: Boolean(goal?.parent_model_id), select: (data) => data?.segments?.[0] },
  );

  const [metricName, setMetricName] = useState(goal?.name ?? "");
  const [metricDescription, setMetricDescription] = useState(goal?.description ?? "");
  const [metricEnabled, setMetricEnabled] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showErrorsModal, setShowErrorsModal] = useState(false);

  const metricData: MetricFormData = useMemo(
    () => ({
      aggregationType: (goal?.aggregation as AggregationType | undefined) ?? AggregationType.Count,
      attributionWindow: goal?.attribution_window,
      column: goal?.config?.column,
      config: {
        type: ConditionType.Event,
        subconditions: goal?.config?.subconditions,
        eventModelId: goal?.config?.eventModelId,
        relationshipId: goal?.config?.relationshipId,
      },
    }),
    [goal],
  );

  useEffect(() => {
    setMetricName(goal?.name ?? "");
    setMetricDescription(goal?.description ?? "");
    setMetricEnabled(goal?.enabled ?? false);
  }, [goal]);

  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Update] },
  ]);

  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Delete] },
  ]);

  const { handleSubmit, ...metricFormProps } = useMetricForm();

  const {
    formState: { isDirty },
    reset,
  } = metricFormProps;

  const trackUpdate = () => {
    analytics.track("Metric Updated", {
      metric_id: id,
      metric_name: goal?.name,
      parent_model_id: goal?.parent_model_id,
    });
  };

  useEffect(() => {
    if (!appEnableGoals) {
      navigate(schemaV2 ? `/schema-v2/` : "/schema/parent-models");
    }
  }, [appEnableGoals]);

  if (!id || isLoading || isParentLoading) {
    return <PageSpinner />;
  }

  if (!goal) {
    return <Warning subtitle="It may have been deleted" title="Source not found" />;
  }

  const updatedAt = goal.updated_at || goal.created_at ? formatDate(goal.updated_at || goal.created_at) : "--";
  const updatedBy = goal.updated_by_user?.name || goal.created_by_user?.name;

  const toggleMetricEnabled = async () => {
    try {
      await updateGoalMutation.mutateAsync({ id, input: { enabled: !metricEnabled } });

      setMetricEnabled((prevValue) => !prevValue);

      toast({
        id: "update-metric",
        title: `Metric was ${metricEnabled ? "disabled" : "enabled"}`,
        variant: "success",
      });

      trackUpdate();
    } catch (error) {
      toast({
        id: "update-metric",
        title: "Failed to update this metric",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const saveMetricInfo = async () => {
    try {
      await updateGoalMutation.mutateAsync({
        id,
        input: {
          name: metricName,
          description: metricDescription,
        },
      });

      toast({
        id: "update-metric",
        title: "Metric was updated",
        variant: "success",
      });

      trackUpdate();
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "update-metric",
        title: "Failed to update this metric",
        message: "There was a problem updating this metric. Please try again.",
        variant: "error",
      });
    }
  };

  const updateMetric = async (data: MetricFormData) => {
    // Need to make sure the data is formatted correctly for the backend
    const { attributionWindow, aggregationType, column, ...restData } = data;
    const formattedData: Goal = {
      ...restData,
      aggregation: aggregationType,
      attribution_window: attributionWindow,
      config: {
        ...restData.config,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore graphql thinks this is a string but it's a NUMBER
        eventModelId: isPresent(restData.config?.eventModelId) ? Number(restData.config?.eventModelId) : null,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore graphql thinks this is a string but it's a NUMBER
        relationshipId: isPresent(restData.config?.relationshipId) ? Number(restData.config?.relationshipId) : null,
      },
    };

    if (column) {
      formattedData.config["column"] = column;
    }

    try {
      await updateGoalMutation.mutateAsync({ id: id ?? "", input: { ...formattedData } });

      toast({
        id: "update-metric",
        title: "Metric was updated",
        variant: "success",
      });

      refetch();

      trackUpdate();
    } catch (error) {
      toast({
        id: "update-metric",
        title: "Failed to update this metric",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const saveMetric: MouseEventHandler<HTMLButtonElement> = (event) => {
    handleSubmit(updateMetric, () => {
      toast({
        id: "metrics-form-toast",
        title: "There is an error in the form",
        message: "Check the form and try again.",
        variant: "error",
      });
    })(event);
  };

  const deleteMetric = async () => {
    try {
      await deleteGoalMutation.mutateAsync({ id });

      analytics.track("Metric Deleted", {
        metric_id: id,
        metric_name: goal?.name,
        parent_model_id: goal?.parent_model_id,
      });

      navigate(schemaV2 ? `schema-v2/settings/metrics` : "/schema/metrics");
    } catch (error) {
      toast({
        id: "delete-metric",
        title: "Failed to delete this metric",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const recalculateMetric = async () => {
    try {
      await enqueueGoalMutation.mutateAsync({ goal_id: goal.id });

      toast({
        id: "recalculate-metric",
        title: "The metric will be recalculated shortly",
        message: "This process might take a few minutes.",
        variant: "success",
      });
    } catch (error) {
      toast({
        id: "recalculate-metric",
        title: "Failed to recalculate metric",
        message: "There was a problem recalculating this metric. Please try again.",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const audiencesGroupedByErrorMessage = groupBy(goal.errors ?? [], "error");

  return (
    <>
      <DetailPage
        crumbs={[
          { label: "Metrics", link: schemaV2 ? `/schema-v2/settings/metrics` : "/schema/metrics" },
          { label: goal?.name ?? "--" },
        ]}
        header={
          <Column flex={1} minWidth={0} mb={4}>
            <Row flex={1} justify="space-between" minWidth={0} pt={1}>
              <EditableHeading
                isDisabled={!userCanUpdate}
                size="lg"
                value={metricName}
                onChange={setMetricName}
                onSubmit={saveMetricInfo}
              />
              <Row align="center" gap={4}>
                <Row align="center" gap={2} sx={{ span: { fontSize: "10px" } }}>
                  <Text color="text.tertiary" fontWeight="semibold" size="sm" textTransform="uppercase">
                    {metricEnabled ? "Enabled" : "Disabled"}
                  </Text>
                  <Switch
                    isChecked={metricEnabled}
                    isDisabled={!userCanUpdate || updateGoalMutation.isLoading}
                    onChange={toggleMetricEnabled}
                  />
                </Row>

                <Menu>
                  <MenuActionsButton variant="secondary" />

                  <MenuList>
                    <MenuItem
                      isDisabled={!userCanDelete}
                      icon={TrashIcon}
                      variant="danger"
                      onClick={() => {
                        setShowDeleteModal(true);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </MenuList>
                </Menu>

                <Button
                  icon={PlayIcon}
                  isDisabled={!userCanUpdate || enqueueGoalMutation.isLoading}
                  isLoading={enqueueGoalMutation.isLoading}
                  onClick={recalculateMetric}
                >
                  Recalculate
                </Button>
              </Row>
            </Row>
            <Row>
              <EditableDescription value={metricDescription} onChange={setMetricDescription} onSubmit={saveMetricInfo} />
            </Row>
            <DetailBar>
              <Row align="center" gap={2} flexShrink={0}>
                <IntegrationIcon
                  src={parentModel?.connection?.definition?.icon}
                  name={parentModel?.connection?.definition?.name ?? ""}
                />
                <Link
                  href={
                    schemaV2
                      ? `/schema-v2/view?source=${parentModel?.connection?.id}&id=${parentModel?.id}`
                      : `/schema/parent-models/${parentModel?.id}`
                  }
                >
                  <Text isTruncated fontWeight="medium" color="inherit">
                    {parentModel?.name}
                  </Text>
                </Link>
              </Row>
              <Row align="center" flexShrink={0}>
                <Text>
                  Updated:
                  <Text fontWeight="semibold" ml={1}>
                    {updatedAt}
                  </Text>{" "}
                  {updatedBy && `by ${updatedBy}`}
                </Text>
              </Row>
            </DetailBar>
          </Column>
        }
        title={`${goal.name} metric`}
      >
        <Column mb={32} gap={4}>
          {goal.errors.length > 0 && (
            <Alert
              type="error"
              title={`Latest metric calculation failed for ${goal.errors.length} audiences`}
              message="Click below to view all errors and affected audiences."
              actions={
                <Button variant="secondary" onClick={() => setShowErrorsModal(true)}>
                  View errors
                </Button>
              }
            />
          )}
          <Text fontWeight="medium" size="lg">
            Metric definition
          </Text>
          <MetricForm metricData={metricData} metricFormProps={metricFormProps} parentModel={parentModel} />
        </Column>

        <ActionBar>
          <ButtonGroup>
            <Button
              isDisabled={!userCanUpdate || !isDirty}
              isLoading={updateGoalMutation.isLoading}
              size="lg"
              variant="primary"
              onClick={saveMetric}
            >
              Save metric
            </Button>
            <Button
              isDisabled={!userCanUpdate || updateGoalMutation.isLoading || !metricFormProps.formState.isDirty}
              size="lg"
              onClick={() => {
                reset();
              }}
            >
              Discard changes
            </Button>
          </ButtonGroup>
        </ActionBar>
      </DetailPage>

      <DeleteConfirmationModal
        isOpen={showDeleteModal}
        label="metric"
        onClose={() => {
          setShowDeleteModal(false);
        }}
        onDelete={deleteMetric}
      />

      <InfoModal isOpen={showErrorsModal} title="Audience errors" width="600px" onClose={() => setShowErrorsModal(false)}>
        <Column gap={5}>
          {Object.keys(audiencesGroupedByErrorMessage).map((errorMessage) => {
            const audienceErrors = audiencesGroupedByErrorMessage[errorMessage];
            const audienceCount = audienceErrors?.length;
            return (
              <Alert
                key={errorMessage}
                type="error"
                title={errorMessage}
                message={
                  <>
                    <Row mb={2}>
                      The following {pluralize("audience", audienceCount)} {pluralize("is", audienceCount)} affected:
                    </Row>
                    <UnorderedList>
                      {audienceErrors?.map(({ audience }) => (
                        <ListItem key={audience.id}>
                          <Row>
                            <Link href={`/audiences/${audience.id}/performance`}>{audience.name}</Link>
                          </Row>
                        </ListItem>
                      ))}
                    </UnorderedList>
                  </>
                }
              />
            );
          })}
        </Column>
      </InfoModal>
    </>
  );
};
