import { Fragment, useEffect, useMemo, useRef, useState } from "react";

import { Square3Stack3DIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import {
  Checkbox as HightouchUiCheckbox,
  Button as HightouchUiButton,
  IconButton,
  ButtonGroup,
  Tooltip as HightouchUiTooltip,
  TextInput,
  Combobox,
  Select as HightouchUiSelect,
  Tooltip,
  GroupedCombobox,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Container, Flex, Grid, Text } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { usePermission } from "src/contexts/permission-context";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
// eslint-disable-next-line no-restricted-imports
import { Checkbox } from "src/ui/checkbox";
import { FieldError } from "src/ui/field";
import { ArrowRightIcon, TraitIcon, XIcon } from "src/ui/icons";
import { AsteriskIcon } from "src/ui/icons/asterisk";
import { LightningIcon } from "src/ui/icons/lightning";
import { Input } from "src/ui/input";
import { CreatableSelect, Select } from "src/ui/select";
import { flattenOptions } from "src/ui/select/select";
import { automap, suggest } from "src/utils/automap";
import { ICON_MAP, NEW_ICON_MAP } from "src/utils/destinations";

import { ExtendedOption, isExtendedTypes, Option, fromExtendedOptiontoOption, ExtendedTypesSync } from "../../../../formkit";
import { isColumnReference } from "../../types/visual";
import { ArrayInlineMapper } from "./array-inline-mapper";
import { AssociationMapper, AssociationOptions } from "./association-mapper";
import { ColumnOption, useFormkitContext } from "./formkit-context";
import { InlineMapper } from "./inline-mapper";
import { Mapper, resolveFromValue } from "./mapper";
import { MappingsHeader } from "./mappings-header";
import { MappingType, JsonColumnProps, SelectedOptionContext } from "./types";

type Props = {
  name: string;
  options?: ExtendedOption[];
  loading?: any;
  object?: string;
  error?: string;
  required?: string;
  creatable?: boolean;
  /** `creatableTypes` are the types the user can select from when creating their own field. */
  creatableTypes?: Option[];
  autoSyncColumnsDefault?: boolean;
  excludeMappings?: string[];
  reload?: () => void;
  advanced?: boolean;
  associationOptions?: AssociationOptions;
  useHightouchUi?: boolean;
  templates?: { name: string; description: string; placeholders?: string[] }[];

  allEnabled?: boolean;
  allEnabledKey?: string;
  allEnabledLabel?: string;
  enableInLineMapper?: boolean;
};

export const formatOptionLabel = (object) => {
  const Icon = ICON_MAP[isExtendedTypes(object?.extendedType) ? object?.extendedType?.type : object?.type];

  return (
    <Row sx={{ alignItems: "center" }}>
      {Icon && (
        <Row sx={{ mr: 2 }}>
          <Icon size={14} />
        </Row>
      )}
      <Text>{object.label}</Text>
      {object?.object?.label && <Text sx={{ fontSize: 1, color: "blacks.5", ml: 2 }}>{object?.object?.label}</Text>}
    </Row>
  );
};

const retrieveErrorMessage = (errors, mappingCount: number, name: string) => {
  if (errors) {
    for (let i = 0; i < mappingCount; i++) {
      for (const direction of ["from", "to"]) {
        const mappingPath = `${name}[${i}].${direction}`;
        const errorMessage = errors?.[mappingPath];
        if (typeof errorMessage === "string") {
          return errorMessage.replace(mappingPath, "This");
        }
      }
    }
  }
  return "";
};

export const Mappings: React.FC<Readonly<Props>> = ({
  name,
  object,
  options,
  required,
  loading,
  reload,
  creatable,
  creatableTypes,
  allEnabled,
  allEnabledLabel,
  allEnabledKey,
  autoSyncColumnsDefault = false,
  excludeMappings = [],
  advanced,
  associationOptions,
  templates,
  useHightouchUi = false,
  enableInLineMapper,
}) => {
  const { model, destination, columns, supportsMatchboosting } = useFormkitContext();
  const [jsonColumnProperties, setJsonColumnProperties] = useState<JsonColumnProps>({
    selectedColumnProps: undefined,
    allColumnsProps: undefined,
  });

  const { errors } = useDestinationForm();
  const { watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });
  const autoSyncKey = allEnabledKey ?? "autoSyncColumns";
  const watchAutoSyncColumns = watch(autoSyncKey);
  /**
   * visible: If suggestions are available
   * disabled: If all suggestions are exhausted
   * hidden: If no suggestions from start (initial render), (unless it's custom mappings)
   */
  const [suggestionState, setSuggestionState] = useState<"visible" | "disabled" | "hidden">("hidden");
  const { appEnableInLineMapper } = useFlags();

  const hasAutoSuggestedRef = useRef(false);

  useEffect(() => {
    if (watchAutoSyncColumns) {
      setValue(name, []);
    }
  }, [watchAutoSyncColumns]);

  const watchFieldArray = watch(name);

  useEffect(() => {
    if (!watchFieldArray) {
      setValue(name, []);
    }
  }, [watchFieldArray]);

  const controlledFields =
    fields?.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    }) || [];

  useEffect(() => {
    if (allEnabled && watchAutoSyncColumns === undefined && !controlledFields.length) {
      setValue(autoSyncKey, autoSyncColumnsDefault);
    } else if (!allEnabled) {
      setValue(autoSyncKey, undefined);
    }
  }, [autoSyncColumnsDefault]);

  useEffect(() => {
    const subscription = watch((state, { name: key }) => {
      const currentMappings = state[name];

      // handles when externalIdMapping overlaps with current component
      if (currentMappings && key && excludeMappings.includes(key)) {
        const value = state[key];
        let otherMappings: any[] = [];
        if (Array.isArray(value)) {
          otherMappings = value;
        } else if (typeof value === "object") {
          otherMappings = [value];
        }

        const fieldsWithoutExcluded = currentMappings.filter((c) => !otherMappings.some((e) => c.to === e.to));
        setValue(name, fieldsWithoutExcluded);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const excludedFields: any[] = [];

  for (const key of excludeMappings) {
    const watchMapping = watch(key);
    if (Array.isArray(watchMapping)) {
      excludedFields.push(...watchMapping);
    } else if (typeof watchMapping === "object") {
      excludedFields.push(watchMapping);
    }
  }

  // handles existing mappings
  const isOptionExcluded = (option) => {
    const valueAlreadyMapped = controlledFields.some(
      ({ to }) => to === (isColumnReference(option.value) ? option.label : option.value),
    );
    const usedInOtherMappings = excludedFields.some(
      ({ to }) => to === (isColumnReference(option.value) ? option.label : option.value),
    );

    return valueAlreadyMapped || usedInOtherMappings;
  };

  useEffect(() => {
    //Remove options that may not be available now or possible duplicates (due to modifiers)
    const existingMappings: string[] = [];
    const indexesToRemove: number[] = [];
    controlledFields?.forEach(({ to }, index) => {
      //Remove mappings if option no longer exist
      if (to && options && !options?.some((o) => o?.value === to) && !creatable) {
        indexesToRemove.push(index);
      }
      //Remove any duplicate mappings (from options having required again)
      if (to && existingMappings.includes(to) && options) {
        indexesToRemove.push(index);
      }
      existingMappings.push(to);
    });
    if (indexesToRemove.length > 0) {
      remove(indexesToRemove);
    }

    //Add in required options
    const requiredOptionsNotPresent = options?.filter(
      (option) => (option.required ?? option.extendedType?.required) && !isOptionExcluded(option),
    );
    if (requiredOptionsNotPresent?.length) {
      const requiredMappings = requiredOptionsNotPresent.map((r) => {
        // Map references differently
        if (r?.extendedType?.type === "REFERENCE") {
          return {
            to: r.value,
            type: "reference",
            lookup: {
              object: r.objectType || r.value,
            },
            object: r["object"]?.value,
          };
        }

        return {
          to: r.value,
          type: "standard",
          object: r["object"]?.value,
        };
      });

      append(requiredMappings);
    }
  }, [controlledFields, excludedFields, options]);

  const suggestColumns = () => {
    const flatColumns = flattenOptions(columns);
    // We convert back from `ExtendedOption` to `Option`
    // This is to not change much logic in the downstream function.
    const unmappedOptions = (!options?.length ? flatColumns : options?.map(fromExtendedOptiontoOption))?.filter(
      (option) => !isOptionExcluded(option),
    );

    return automap(columns, unmappedOptions);
  };

  // Want to autosuggest boosted columns for match booster when setting up sync
  useEffect(() => {
    if (
      !hasAutoSuggestedRef.current &&
      !fields?.length &&
      options?.length &&
      columns?.length &&
      columns.find((col) => col.label === "Boosted column name")
    ) {
      // suggest mappings code
      const unmappedDestinationColumns = suggestColumns();
      if (unmappedDestinationColumns.find((col) => col.from?.["type"] === "boosted")) {
        append(unmappedDestinationColumns);
      }

      // toggle flag so that this does not run again
      hasAutoSuggestedRef.current = true;
    }
  }, [options?.length, columns?.length]);

  useEffect(
    () => {
      setSuggestionState((prevSuggestionState) => {
        const unmappedDestinationColumns = suggestColumns();
        if (unmappedDestinationColumns.length) {
          setSuggestionState("visible");
        } else if (((options?.length ?? 0) > 0 && prevSuggestionState === "visible") || !options || options.length === 0) {
          setSuggestionState("disabled");
        }

        return prevSuggestionState;
      });
    },
    //Watching fields as `controlledFields` is used within nested functions of this useEffect
    [options?.length, fields?.length],
  );

  const permission = usePermission();

  const columnOptionGroups = useMemo(() => {
    return columns.map((optionGroup) => ({
      ...optionGroup,
      options: optionGroup.options ?? [],
    }));
  }, [columns]);

  if (!watchFieldArray) {
    return null;
  }

  if (allEnabled && watchAutoSyncColumns) {
    return (
      <Controller
        name={autoSyncKey}
        render={({ field }) => {
          if (useHightouchUi) {
            return (
              <HightouchUiCheckbox
                isChecked={field.value}
                label={allEnabledLabel || "Sync all columns (columns will be synced with the same name)"}
                onChange={field.onChange}
              />
            );
          }

          return (
            <Checkbox
              label={allEnabledLabel || "Sync all columns (columns will be synced with the same name)"}
              value={field.value}
              onChange={field.onChange}
            />
          );
        }}
      />
    );
  }

  const loadEligibleInlineMapperColumns = async (currentSelectedColumn?: string) => {
    const allColumnsProps = extractEligibleInlineMapperColumns(columns);
    if (isValidJsonColumnMetadata(allColumnsProps)) {
      setJsonColumnProperties((currentValue) => ({
        selectedColumnProps: currentSelectedColumn ? allColumnsProps[currentSelectedColumn] : currentValue.selectedColumnProps,
        allColumnsProps,
      }));
    }
  };

  const matchBoostingEnabled = (model?.parent?.matchboosting_enabled || model?.matchboosting_enabled) && supportsMatchboosting;

  return (
    <Column aria-label={`${name} mappings`}>
      {allEnabled && (
        <Controller
          name={autoSyncKey}
          render={({ field }) => {
            if (useHightouchUi) {
              return (
                <HightouchUiCheckbox
                  isChecked={field.value}
                  isDisabled={permission.unauthorized}
                  label={allEnabledLabel || "Sync all columns (columns will be synced with the same name)"}
                  mb={4}
                  onChange={field.onChange}
                />
              );
            }

            return (
              <Checkbox
                label={allEnabledLabel || "Sync all columns (columns will be synced with the same name)"}
                sx={{ mb: 4 }}
                value={field.value}
                onChange={field.onChange}
              />
            );
          }}
        />
      )}

      {matchBoostingEnabled && (
        <Column
          sx={{
            border: "1px",
            borderStyle: "solid",
            borderRadius: "6px",
            borderColor: "gray.400",
            mt: 3,
            mb: 4,
            padding: 2,
            gap: 2,
          }}
        >
          <Column>
            <Row gap={1}>
              <SparklesIcon color="#F5C24D" width={20} />
              <Text sx={{ fontWeight: "medium" }}>Match boosting available</Text>
            </Row>
          </Column>
          <Column>
            Hightouch will send all of the enriched identifiers selected below to {destination?.name} to increase match rates.
          </Column>
        </Column>
      )}

      <Grid
        sx={{
          gridTemplateColumns: useHightouchUi ? "minmax(0, 1fr) 16px minmax(0, 1fr) 36px" : "1fr max-content 1fr max-content",
          alignItems: "center",
        }}
      >
        {controlledFields?.length > 0 && (
          <MappingsHeader columns={4} loading={loading} object={object} reload={reload} useHightouchUi={useHightouchUi} />
        )}
      </Grid>

      <Container marginTop="10px">
        {controlledFields.map(({ id }, index) => (
          <Fragment key={id}>
            <Controller
              name={`${name}.${index}`}
              render={(data) => {
                const destinationFieldOptions = options?.map((option) => {
                  if (isOptionExcluded(option)) {
                    return { ...option, disabled: true };
                  } else {
                    return option;
                  }
                });

                const selectedOption = options?.find((option) => option.value == data.field.value?.to);

                const allDestinationOptions = [...(destinationFieldOptions ?? [])];

                // If mapping destination is configured, but it's not in the list of
                // available options, it's either a custom value typed by the user or
                // it comes from a suggested mapping
                if (typeof data.field.value?.to === "string" && !selectedOption) {
                  allDestinationOptions.push({
                    label: data.field.value.to,
                    value: data.field.value.to,
                    disabled: false,
                  });
                }

                const isMappingRequired = Boolean(selectedOption?.required ?? selectedOption?.extendedType?.required);
                const referenceObjectOptions = selectedOption?.referenceObjects;

                const selectFieldProps = {
                  readOnly: isMappingRequired,
                  formatOptionLabel,
                  options: destinationFieldOptions ?? [],
                  value: data.field.value?.to,
                  onChange: (option) => {
                    if (option?.extendedType?.type === "REFERENCE") {
                      data.field.onChange({
                        to: option?.value,
                        object: option?.object?.value,
                        lookup: {
                          object: option?.referenceObjects?.[0]?.value || option?.objectType || option?.value,
                          from: data.field.value?.from,
                        },
                        type: "reference",
                      });
                    } else if (option?.extendedType?.type === "OBJECT" && enableInLineMapper && appEnableInLineMapper) {
                      data.field.onChange({
                        // We need to clear out previous configuration, so from has to be undefined at the initial state of the in-line mapper.
                        ...data.field.value,
                        from: undefined,
                        to: option?.value,
                        object: option?.object?.value,
                        type: MappingType.OBJECT,
                      });
                    } else if (option?.extendedType?.type === "ARRAY" && enableInLineMapper && appEnableInLineMapper) {
                      data.field.onChange({
                        // We need to clear out previous configuration, so from has to be undefined at the initial state of the in-line mapper.
                        ...data.field.value,
                        from: undefined,
                        to: option?.value,
                        object: option?.object?.value,
                        type: MappingType.ARRAY,
                      });
                    } else {
                      if (data.field.value?.type === "reference") {
                        data.field.onChange({
                          from: data.field.value?.lookup?.from,
                          to: option?.value,
                          object: option?.object?.value,
                          type: "standard",
                        });
                      } else {
                        data.field.onChange({
                          ...data.field.value,
                          to: option?.value,
                          object: option?.object?.value,
                          type: data.field.value?.type,
                        });
                      }
                    }
                  },
                };

                return (
                  <Grid
                    sx={{
                      gridTemplateColumns: useHightouchUi
                        ? "minmax(0, 1fr) 16px minmax(0, 1fr) 36px"
                        : "1fr max-content 1fr max-content",
                      alignItems: "center",
                    }}
                  >
                    {data.field.value?.type === "reference" ? (
                      <AssociationMapper
                        associationOptions={associationOptions}
                        index={index}
                        name={name}
                        useHightouchUi={useHightouchUi}
                        value={data.field.value}
                        onChange={data.field.onChange}
                      />
                    ) : advanced ? (
                      <Mapper
                        matchBoostingEnabled={matchBoostingEnabled}
                        enableInLineMapper={enableInLineMapper && appEnableInLineMapper}
                        isDisabled={permission.unauthorized}
                        isError={!!errors?.[`${name}[${index}].from`]}
                        jsonColumnProperties={jsonColumnProperties}
                        placeholder="Select a value..."
                        selectedOption={selectedOption}
                        templates={templates ?? []}
                        useHightouchUi={useHightouchUi}
                        value={data.field.value}
                        onReloadEligibleInlineMapperColumns={loadEligibleInlineMapperColumns}
                        onChange={(value) => {
                          const availableOptions = destinationFieldOptions?.filter((o) => !o.disabled);
                          if (!data.field.value?.to && availableOptions?.length && value.type === MappingType.STANDARD) {
                            // Ensure that the fieldName is used for the label property since FuzzySet methods (used in automap.ts)
                            // require string arguments.
                            const fieldName = resolveFromValue(value.from);
                            data.field.onChange(suggest({ label: fieldName, value: value.from }, availableOptions));
                          } else {
                            data.field.onChange({ to: data.field.value?.to, object: data.field.value?.object, ...value });
                          }
                        }}
                        onChangeJsonColumnProperties={setJsonColumnProperties}
                      />
                    ) : (
                      <>
                        {useHightouchUi ? (
                          <GroupedCombobox
                            isDisabled={permission.unauthorized}
                            isInvalid={Boolean(errors?.[`${name}[${index}].from`])}
                            optionAccessory={(option) => {
                              return option?.value?.["type"] === "additionalColumnReference"
                                ? {
                                    type: "icon",
                                    icon: Square3Stack3DIcon,
                                  }
                                : undefined;
                            }}
                            optionGroups={columnOptionGroups}
                            placeholder="Select a column..."
                            value={data.field.value.from}
                            onChange={(value) => {
                              let option: ColumnOption | undefined;

                              // Find an original option object for a selected value
                              for (const optionGroup of columnOptionGroups) {
                                for (const optionGroupItem of optionGroup.options) {
                                  if (optionGroupItem.value === value) {
                                    option = optionGroupItem;
                                    break;
                                  }
                                }
                              }

                              const availableOptions = destinationFieldOptions?.filter((o) => !o.disabled);
                              if (!data.field.value?.to && option) {
                                data.field.onChange(suggest(option as any, availableOptions));
                              } else {
                                data.field.onChange({
                                  to: data.field.value?.to,
                                  object: data.field.value?.object,
                                  from: option?.value,
                                  fieldType: data.field.value?.fieldType,
                                });
                              }
                            }}
                          />
                        ) : (
                          <Select
                            formatOptionLabel={formatFromColumnOption}
                            isError={!!errors?.[`${name}[${index}].from`]}
                            options={columns}
                            placeholder="Select a column..."
                            value={data.field.value.from}
                            onChange={(option) => {
                              const availableOptions = destinationFieldOptions?.filter((o) => !o.disabled);
                              if (!data.field.value?.to) {
                                data.field.onChange(suggest(option, availableOptions));
                              } else {
                                data.field.onChange({
                                  to: data.field.value?.to,
                                  object: data.field.value?.object,
                                  from: option?.value,
                                  fieldType: data.field.value?.fieldType,
                                });
                              }
                            }}
                          />
                        )}
                      </>
                    )}

                    <ArrowRightIcon color="base.3" size={16} />
                    <Column>
                      {options || creatable ? (
                        creatable ? (
                          <Flex sx={{ flexDirection: "column", flex: 1, gap: useHightouchUi ? 2 : 0 }}>
                            <>
                              {useHightouchUi ? (
                                <Combobox<any, string>
                                  supportsCreatableOptions
                                  createOptionMessage={(inputValue) => `Create field "${inputValue}"`}
                                  emptyOptionsMessage="Type in a field name and Hightouch will create that field in the destination"
                                  isDisabled={permission.unauthorized || isMappingRequired}
                                  isInvalid={Boolean(errors?.[`${name}[${index}].to`])}
                                  isOptionDisabled={(option) => {
                                    // For some reason when option is selected, it becomes disabled
                                    // and has `disabled = true` property, so we need to take this into account
                                    // and make sure selected option can't be disabled
                                    const isSelectedOption = option.value === data.field.value?.to;
                                    if (isSelectedOption) {
                                      return false;
                                    }

                                    return Boolean(option.disabled);
                                  }}
                                  optionAccessory={(option) => {
                                    return option.type
                                      ? {
                                          type: "icon",
                                          icon: NEW_ICON_MAP[option.type],
                                        }
                                      : undefined;
                                  }}
                                  optionLabel={(option) => {
                                    return option.object?.label ? `${option.label} (${option.object.label})` : option.label;
                                  }}
                                  options={allDestinationOptions}
                                  placeholder="Select or add a field..."
                                  value={data.field.value?.to}
                                  width="sm"
                                  onChange={(value) => {
                                    const option = destinationFieldOptions?.find((option) => option.value === value);
                                    if (option?.extendedType?.type === "REFERENCE") {
                                      data.field.onChange({
                                        to: option?.value,
                                        object: option?.object?.value,
                                        lookup: {
                                          object: option?.referenceObjects?.[0]?.value || option?.objectType || option?.value,
                                          from: data.field.value?.from,
                                        },
                                        type: "reference",
                                      });
                                    } else {
                                      if (data.field.value?.type === "reference") {
                                        data.field.onChange({
                                          from: data.field.value?.lookup?.from,
                                          to: option?.value,
                                          object: option?.object?.value,
                                          type: MappingType.STANDARD,
                                        });
                                      } else if (
                                        data.field.value?.type === "OBJECT" &&
                                        enableInLineMapper &&
                                        appEnableInLineMapper
                                      ) {
                                        data.field.onChange({
                                          // We need to clear out previous configuration, so from has to be undefined at the initial state of the in-line mapper.
                                          from: undefined,
                                          to: option?.value,
                                          object: option?.object?.value,
                                          type: MappingType.OBJECT,
                                        });
                                      } else if (
                                        data.field.value?.type === "ARRAY" &&
                                        enableInLineMapper &&
                                        appEnableInLineMapper
                                      ) {
                                        data.field.onChange({
                                          // We need to clear out previous configuration, so from has to be undefined at the initial state of the in-line mapper.
                                          from: undefined,
                                          to: option?.value,
                                          object: option?.object?.value,
                                          type: MappingType.ARRAY,
                                        });
                                      } else {
                                        data.field.onChange({
                                          ...data.field.value,
                                          to: option?.value,
                                          object: option?.object?.value,
                                          type: data.field.value?.type,
                                        });
                                      }
                                    }
                                  }}
                                  onCreateOption={(inputValue) => {
                                    data.field.onChange({
                                      ...data.field.value,
                                      to: inputValue || undefined,
                                    });
                                  }}
                                />
                              ) : (
                                <CreatableSelect
                                  {...selectFieldProps}
                                  empty="Type in a field name and Hightouch will create the field in the destination!"
                                  formatCreateLabel={(string) => {
                                    return `Create field "${string}"...`;
                                  }}
                                  formatOptionLabel={formatOptionLabel}
                                  isClearable={false}
                                  isDisabled={permission.unauthorized || isMappingRequired}
                                  isError={!!errors?.[`${name}[${index}].to`]}
                                  isValidNewOption={(inputValue, _, selectOptions) => {
                                    return !selectOptions.find((v) => v.value === inputValue) && Boolean(inputValue);
                                  }}
                                  placeholder="Select or add a field..."
                                  onCreateOption={(value) =>
                                    data.field.onChange({ ...data.field.value, to: value || undefined })
                                  }
                                />
                              )}
                            </>
                            {creatableTypes &&
                              data.field.value?.to &&
                              !options?.find((option: Option) => option.value === data.field.value?.to) && (
                                <>
                                  {useHightouchUi ? (
                                    <HightouchUiSelect
                                      isDisabled={permission.unauthorized}
                                      options={creatableTypes}
                                      placeholder="Select a field type..."
                                      value={data.field.value?.fieldType}
                                      width="100%"
                                      onChange={(value) => {
                                        data.field.onChange({
                                          ...data.field.value,
                                          fieldType: value,
                                        });
                                      }}
                                    />
                                  ) : (
                                    <Select
                                      options={creatableTypes}
                                      placeholder="Select a field type..."
                                      value={data.field.value?.fieldType}
                                      onChange={(creatableType) => {
                                        data.field.onChange({ ...data.field.value, fieldType: creatableType?.value });
                                      }}
                                    />
                                  )}
                                </>
                              )}
                          </Flex>
                        ) : (
                          <>
                            {useHightouchUi ? (
                              <Combobox
                                isDisabled={permission.unauthorized || isMappingRequired}
                                isInvalid={Boolean(errors?.[`${name}[${index}].to`])}
                                isOptionDisabled={(option) => {
                                  // For some reason when option is selected, it becomes disabled
                                  // and has `disabled = true` property, so we need to take this into account
                                  // and make sure selected option can't be disabled
                                  const isSelectedOption = option.value === data.field.value?.to;
                                  if (isSelectedOption) {
                                    return false;
                                  }

                                  return Boolean(option.disabled);
                                }}
                                optionAccessory={(option) => {
                                  return option?.extendedType?.type
                                    ? {
                                        type: "icon",
                                        icon: NEW_ICON_MAP[option.extendedType.type],
                                      }
                                    : undefined;
                                }}
                                optionLabel={(option) => {
                                  return option.object?.label ? `${option.label} (${option.object.label})` : option.label;
                                }}
                                options={allDestinationOptions}
                                placeholder="Select a field..."
                                value={data.field.value?.to}
                                width="sm"
                                onChange={(value) => {
                                  const option = destinationFieldOptions?.find((option) => option.value === value);

                                  if (option?.extendedType?.type === "REFERENCE") {
                                    data.field.onChange({
                                      to: option?.value,
                                      object: option?.object?.value,
                                      lookup: {
                                        object: option?.referenceObjects?.[0]?.value || option?.objectType || option?.value,
                                        from: data.field.value?.from,
                                      },
                                      type: "reference",
                                    });
                                  } else if (
                                    option?.extendedType?.type === "OBJECT" &&
                                    enableInLineMapper &&
                                    appEnableInLineMapper
                                  ) {
                                    data.field.onChange({
                                      // We need to clear out previous configuration, so from has to be undefined at the initial state of the in-line mapper.
                                      ...data.field.value,
                                      from: undefined,
                                      to: option?.value,
                                      object: option?.object?.value,
                                      type: MappingType.OBJECT,
                                    });
                                  } else if (
                                    option?.extendedType?.type === "ARRAY" &&
                                    enableInLineMapper &&
                                    appEnableInLineMapper
                                  ) {
                                    data.field.onChange({
                                      // We need to clear out previous configuration, so from has to be undefined at the initial state of the in-line mapper.
                                      ...data.field.value,
                                      from: undefined,
                                      to: option?.value,
                                      object: option?.object?.value,
                                      type: MappingType.ARRAY,
                                    });
                                  } else {
                                    if (data.field.value?.type === "reference") {
                                      data.field.onChange({
                                        from: data.field.value?.lookup?.from,
                                        to: option?.value,
                                        object: option?.object?.value,
                                        type: "standard",
                                      });
                                    } else {
                                      data.field.onChange({
                                        ...data.field.value,
                                        to: option?.value,
                                        object: option?.object?.value,
                                        type: data.field.value?.type,
                                      });
                                    }
                                  }
                                }}
                              />
                            ) : (
                              <Select
                                {...selectFieldProps}
                                isError={!!errors?.[`${name}[${index}].to`]}
                                placeholder="Select a field..."
                              />
                            )}
                          </>
                        )
                      ) : (
                        <>
                          {useHightouchUi ? (
                            <TextInput
                              isDisabled={permission.unauthorized}
                              isInvalid={Boolean(errors?.[`${name}[${index}].to`])}
                              placeholder="Enter a field..."
                              value={data.field.value.to ?? ""}
                              width="auto"
                              onChange={(event) => {
                                data.field.onChange({
                                  ...data.field.value,
                                  to: event.target.value,
                                });
                              }}
                            />
                          ) : (
                            <Input
                              error={!!errors?.[`${name}[${index}].to`]}
                              placeholder="Enter a field..."
                              value={data.field.value.to}
                              onChange={(to) => data.field.onChange({ ...data.field.value, to })}
                            />
                          )}
                        </>
                      )}
                      {selectedOption?.extendedType?.type === "REFERENCE" &&
                        (selectedOption?.referenceObjects?.length ?? 0) > 1 && (
                          <Row sx={{ mt: 2, alignItems: "center" }}>
                            <Text sx={{ color: "base.4", fontSize: 1, fontWeight: "semi", whiteSpace: "nowrap", mr: 2 }}>
                              Linked to:
                            </Text>
                            <Select
                              isError={!!errors?.[`${name}[${index}].to`]}
                              options={referenceObjectOptions}
                              value={data.field.value?.lookup?.object}
                              onChange={(selected) => {
                                data.field.onChange({
                                  ...data.field.value,
                                  lookup: { by: null, byType: null, from: undefined, object: selected?.value },
                                });
                              }}
                            />
                          </Row>
                        )}
                    </Column>

                    {required && controlledFields.length <= 1 ? (
                      // needs to exist to satisfy grid
                      <Row sx={{ width: useHightouchUi ? "36px" : "18px", minHeight: "36px" }}></Row>
                    ) : isMappingRequired ? (
                      // needs to exist at same width of 'X' icon to satisfy grid;
                      <Row
                        sx={{
                          width: useHightouchUi ? "36px" : "18px",
                          minHeight: "36px",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingLeft: "4px",
                        }}
                      >
                        <Tooltip message="Required">
                          <AsteriskIcon color="red" size={12} />
                        </Tooltip>
                      </Row>
                    ) : (
                      <>
                        {useHightouchUi ? (
                          <IconButton
                            aria-label="Remove mapping"
                            icon={XMarkIcon}
                            isDisabled={permission.unauthorized}
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        ) : (
                          <Button
                            aria-label="remove mapping"
                            disabled={permission.unauthorized}
                            variant="plain"
                            onClick={() => remove(index)}
                          >
                            <XIcon color="base.6" size={18} />
                          </Button>
                        )}
                      </>
                    )}
                    <Container
                      sx={{
                        marginTop: useHightouchUi ? "-12px" : "-14px",
                        gridRowStart: "2",
                        gridColumnStart: "1",
                        gridRowEnd: "3",
                        gridColumnEnd: "5",
                        paddingBottom: "14px",
                      }}
                    >
                      {data.field.value?.type === "object" && enableInLineMapper && appEnableInLineMapper && (
                        <InlineMapper
                          currentDepth={1}
                          enabledNestedInlineMapper={enableInLineMapper && appEnableInLineMapper}
                          errorPrefix={`${name}[${index}].from`}
                          jsonColumnProperties={jsonColumnProperties}
                          retrieveErrorMessage={retrieveErrorMessage}
                          selectedOptionContext={extendedTypeToFormkitOptions(selectedOption)}
                          templates={templates ?? []}
                          type="object"
                          useHightouchUi={useHightouchUi}
                          value={data.field.value}
                          onChange={(value) => {
                            data.field.onChange({
                              ...data.field.value,
                              from: value,
                              type: MappingType.OBJECT,
                            });
                          }}
                          onChangeJsonColumnProperties={setJsonColumnProperties}
                          onReloadEligibleInlineMapperColumns={loadEligibleInlineMapperColumns}
                        />
                      )}
                      {data.field.value?.type === "array" &&
                        enableInLineMapper &&
                        appEnableInLineMapper &&
                        data.field.value?.from && (
                          <ArrayInlineMapper
                            currentDepth={1}
                            enabledNestedInlineMapper={enableInLineMapper && appEnableInLineMapper}
                            errorPrefix={`${name}[${index}].children`}
                            jsonColumnProperties={jsonColumnProperties}
                            retrieveErrorMessage={retrieveErrorMessage}
                            selectedOptionContext={extendedTypeToFormkitOptions(selectedOption)}
                            templates={templates ?? []}
                            useHightouchUi={useHightouchUi}
                            value={data.field.value}
                            onChange={(value) => {
                              data.field.onChange({
                                ...data.field.value,
                                children: value,
                                type: MappingType.ARRAY,
                              });
                            }}
                            onChangeJsonColumnProperties={setJsonColumnProperties}
                            onReloadEligibleInlineMapperColumns={loadEligibleInlineMapperColumns}
                          />
                        )}
                    </Container>
                  </Grid>
                );
              }}
            />
          </Fragment>
        ))}
      </Container>
      <FieldError error={retrieveErrorMessage(errors, controlledFields.length, name)} />
      {useHightouchUi ? (
        <ButtonGroup mt={4}>
          <HightouchUiButton
            isDisabled={permission.unauthorized}
            onClick={() => {
              append({ type: "standard" });
            }}
          >
            Add mapping
          </HightouchUiButton>

          {suggestionState !== "hidden" && (
            <HightouchUiTooltip isDisabled={suggestionState !== "disabled"} message="All possible suggestions applied">
              <HightouchUiButton
                isDisabled={permission.unauthorized || watchAutoSyncColumns || suggestionState === "disabled"}
                onClick={() => {
                  append(suggestColumns());
                }}
              >
                Suggest mappings
              </HightouchUiButton>
            </HightouchUiTooltip>
          )}
        </ButtonGroup>
      ) : (
        <Row sx={{ mt: 4 }}>
          <Button variant="secondary" onClick={() => append({ type: "standard" })}>
            Add mapping
          </Button>

          {suggestionState !== "hidden" && (
            <Button
              aria-label="suggest mappings"
              disabled={watchAutoSyncColumns ?? suggestionState === "disabled"}
              sx={{ minWidth: "32px", ml: 1 }}
              tooltip={suggestionState !== "disabled" ? "Suggest mappings" : "All possible suggestions applied"}
              variant="text-secondary"
              onClick={() => {
                append(suggestColumns());
              }}
            >
              <LightningIcon size={16} />
            </Button>
          )}
        </Row>
      )}
    </Column>
  );
};

export const extractEligibleInlineMapperColumns = (columns: ColumnOption[]): { [column: string]: string[] | null } => {
  const allColumnProps = {};

  columns.forEach((column) => {
    if (column.metadata?.properties !== undefined) {
      allColumnProps[column.label] = column.metadata.properties;
    }
    if (column.options) {
      column.options.forEach((nestedColumn) => {
        if (nestedColumn.metadata?.properties !== undefined) {
          allColumnProps[nestedColumn.label] = nestedColumn.metadata.properties;
        }
      });
    }
  });

  return allColumnProps;
};

export const formatFromColumnOption = (option) => {
  if (typeof option?.value === "object") {
    if (option?.value?.type === "additionalColumnReference") {
      return (
        <Row sx={{ alignItems: "center" }}>
          <TraitIcon color="base.5" size={16} sx={{ mr: 2 }} />
          <Text>{option.label}</Text>
        </Row>
      );
    }
  }
  return option?.label;
};

export const extendedTypeToFormkitOptions = (
  extendedOption: ExtendedOption | undefined,
): SelectedOptionContext[] | undefined => {
  let childProperties: { [key: string]: ExtendedTypesSync } | undefined;

  if (!extendedOption) return undefined;

  if (extendedOption.extendedType?.type === "OBJECT" && extendedOption.extendedType?.properties) {
    childProperties = extendedOption.extendedType.properties;
  }

  if (
    extendedOption.extendedType?.type === "ARRAY" &&
    extendedOption.extendedType?.items &&
    extendedOption.extendedType?.items?.type === "OBJECT"
  ) {
    childProperties = extendedOption.extendedType.items?.properties;
  }

  if (!childProperties) return undefined;

  const options: SelectedOptionContext[] = [];

  for (const [key, value] of Object.entries(childProperties)) {
    if (value.type === "OBJECT" && value.properties) {
      const nestedOption: SelectedOptionContext[] = [];

      for (const [nestedKey, nestedValue] of Object.entries(value.properties)) {
        nestedOption.push({
          value: nestedKey,
          label: nestedValue.label ?? nestedKey,
          required: nestedValue.required,
          type: nestedValue.type,
        });
      }

      options.push({
        value: key,
        label: value.label ?? key,
        required: value.required,
        type: value.type,
        properties: nestedOption,
      });
    } else {
      options.push({
        value: key,
        label: value.label ?? key,
        required: value.required,
        type: value.type,
      });
    }
  }

  return options;
};

export const isValidJsonColumnMetadata = (columns: Record<string, string[] | null>): columns is Record<string, string[]> => {
  const keys = Object.keys(columns);

  for (const key of keys) {
    if (columns[key] === null) {
      return false;
    }
  }
  return true;
};
