import { FC, Suspense } from "react";

import { Box, Column, Text, Row, Spinner } from "@hightouchio/ui";
import { Outlet } from "react-router-dom";

import { Logo } from "src/ui/brand";

export type Props = {
  logo: string;
  name?: string;
};

export const Layout: FC<Readonly<Props>> = ({ logo }) => {
  return (
    <Column width="100%" height="100vh" overflow="hidden">
      <Row
        borderBottom="1px"
        borderColor="base.border"
        height="64px"
        px={6}
        align="center"
        width="100%"
        justify="space-between"
      >
        <Row width="200px">
          <Box as="img" src={logo} />
        </Row>

        <Row width="200px" justify="flex-end">
          <Row align="center" gap={2}>
            <Text fontWeight="semibold">Powered by</Text>
            <Logo variant="full" size="92px" textColor="black" />
          </Row>
        </Row>
      </Row>

      <Column flex={1} align="center" width="100%">
        <Suspense fallback={<Spinner size="lg" m="auto" />}>
          <Outlet />
        </Suspense>
      </Column>
    </Column>
  );
};
