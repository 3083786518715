import { ReactNode, FC } from "react";

import { useDisclosure, useOutsideClick } from "@chakra-ui/react";
import {
  Box,
  BoxProps,
  Button,
  ChakraPortal,
  ChakraPopover,
  ChakraPopoverContent,
  ChakraPopoverTrigger,
  Column,
} from "@hightouchio/ui";

import { useManualPortal } from "src/hooks/use-manual-portal";
import { FieldError } from "src/ui/field";

import { Indices } from "../../../../../design";
import { HStack } from "./condition";

const MAX_FILTER_WIDTH = 320;

type Props = {
  children: ReactNode;
  content: ReactNode;
  error?: ReactNode | string | Error;
} & BoxProps;

export const Filter: FC<Readonly<Props>> = ({ content, children, error, ...props }) => {
  // Need to attach the content to the DOM manually
  // because chakra Portal's `containerRef` renders the
  // content inside the main tree.
  const containerRef = useManualPortal();
  const { isOpen, onToggle, onClose } = useDisclosure();

  useOutsideClick({
    enabled: isOpen,
    ref: containerRef,
    handler: onClose,
  });

  if (!containerRef.current) return null;

  return (
    <Column {...props}>
      <ChakraPopover isLazy closeOnBlur={false} isOpen={isOpen} onClose={onClose} placement="bottom">
        <ChakraPopoverTrigger>
          <Box as={Button} sx={{ border: "none", p: 0 }} onClick={onToggle}>
            <HStack
              _hover={{
                borderColor: isOpen ? "primary.base" : "gray.border",
                bg: "base.lightBackground",
              }}
              _active={{
                border: "1px solid",
                borderColor: "primary.base",
                boxShadow: error ? "outlineRed" : "outline",
              }}
              px={2}
              gap={1}
              alignItems="center"
              height="32px"
              fontWeight="normal"
              border="1px solid"
              borderColor={error ? "danger" : isOpen ? "primary.base" : "base.border"}
              boxShadow={error ? "outlineRed" : isOpen ? "outline" : "none"}
              bg="base.lightBackground"
              borderRadius="6px"
              maxWidth={`${MAX_FILTER_WIDTH}px`}
              overflow="hidden"
              transition="all 120ms ease-in"
              whiteSpace="nowrap"
            >
              {children}
            </HStack>
          </Box>
        </ChakraPopoverTrigger>

        <ChakraPortal containerRef={containerRef}>
          <Box sx={{ ">div": { zIndex: Indices.Modal } }}>
            <ChakraPopoverContent width="max-content">
              <Column align="flex-start" flex={1} maxWidth="fit-content" minWidth={0} p={2}>
                <HStack gap={2} sx={{ overflowX: "auto" }}>
                  {content}
                </HStack>
              </Column>
            </ChakraPopoverContent>
          </Box>
        </ChakraPortal>
      </ChakraPopover>

      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
};
