import { useState } from "react";

import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Box, Button, ChevronLeftIcon, ChevronRightIcon, Column, IconButton, Link, Paragraph } from "@hightouchio/ui";

export interface DialogProps {
  pages: { src: string; altText: string; description: string }[];
  docsLink?: string;
  isOpen: boolean;
  title: string;
  closeOnOverlayClick?: boolean;
  confirmButtonText: string;
  onClose: () => void;
}

export function NewFeatureDialog({
  closeOnOverlayClick = true,
  confirmButtonText,
  isOpen,
  docsLink,
  pages,
  title,
  onClose,
}: DialogProps) {
  const [visiblePageIndex, setVisiblePageIndex] = useState(0);

  const previousPage = () => {
    setVisiblePageIndex((prevPage) => {
      if (prevPage > 0) {
        return prevPage - 1;
      }

      return prevPage;
    });
  };

  const nextPage = () => {
    setVisiblePageIndex((prevPage) => {
      if (prevPage < pages.length - 1) {
        return prevPage + 1;
      }

      return prevPage;
    });
  };

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={closeOnOverlayClick} onClose={onClose}>
      <ModalOverlay>
        <ModalContent
          w="3xl"
          maxW="3xl"
          motionProps={{
            initial: {
              scale: 0.9,
              opacity: 0,
            },
            variants: {
              enter: {
                scale: 1,
                opacity: 1,
                transition: {
                  type: "spring",
                  damping: 30,
                  stiffness: 1000,
                },
              },
              exit: {
                scale: 0.9,
                opacity: 0,
                transition: {
                  type: "spring",
                  duration: 0.15,
                },
              },
            },
          }}
        >
          <Column flex="1" minHeight={0} gap={4}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
              {pages.map(({ src, description, altText }, index) => {
                if (index !== visiblePageIndex) {
                  return null;
                }

                return (
                  <Column key={index} gap={4} alignItems="center">
                    <Box
                      display="grid"
                      gridTemplateColumns="40px 1fr 40px"
                      minHeight="250px"
                      alignItems="center"
                      gap={2}
                      sx={{
                        button: {
                          border: "none",
                        },
                      }}
                    >
                      <IconButton
                        aria-label="Go to previous page."
                        icon={ChevronLeftIcon}
                        isDisabled={visiblePageIndex === 0}
                        size="lg"
                        onClick={previousPage}
                      />

                      <Column height="400px" width="100%" p={4} alignItems="center" justifyContent="center">
                        <Box as="img" alt={altText} src={src} />
                      </Column>

                      <IconButton
                        aria-label="Go to next page."
                        icon={ChevronRightIcon}
                        isDisabled={visiblePageIndex >= pages.length - 1}
                        size="lg"
                        onClick={nextPage}
                      />
                    </Box>
                    <Paragraph size="lg" color="text.secondary">
                      {description}
                    </Paragraph>
                  </Column>
                );
              })}
            </ModalBody>
          </Column>

          <ModalFooter mt={4} alignItems="center" display="flex" flexDirection="row" justifyContent="center" gap={4}>
            <Button variant="primary" onClick={onClose} size="lg">
              {confirmButtonText}
            </Button>
            {docsLink && (
              <Link href={docsLink} target="_blank">
                Read the docs
              </Link>
            )}
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
