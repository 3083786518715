import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { BoltIcon, BoltSlashIcon, FolderOpenIcon, PlayIcon, TagIcon, TrashIcon } from "@heroicons/react/24/outline";
import { SparklesIcon } from "@heroicons/react/24/solid";
import {
  Box,
  Button,
  Column,
  ConfirmationDialog,
  DestinationBadge,
  DestinationBadgeGroup,
  Heading,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Paragraph,
  Pill,
  Row,
  SearchInput,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { formatDistanceToNowStrict } from "date-fns";
import { isEmpty } from "lodash";
import pluralize from "pluralize";
import { useLocation, useNavigate } from "react-router-dom";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import syncPlaceholder from "src/assets/placeholders/sync.svg";
import { DraftBadge } from "src/components/drafts/draft-badge";
import { DraftIcon } from "src/components/drafts/draft-icon";
import {
  createdByFilterConfig,
  destinationFilterConfig,
  Filters,
  labelFilterConfig,
  sourceFilterConfig,
  syncStatusFilterConfig,
  useFilters,
} from "src/components/folders/filters";
import { Folders } from "src/components/folders/folder-list";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolderState } from "src/components/folders/use-folder-state";
import { isFilterActive } from "src/components/folders/utils";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabelModal } from "src/components/labels/edit-label-modal";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { ConfettiModal } from "src/components/onboarding/confetti-modal";
import { PageAlert } from "src/components/page-alert";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import { MappingType } from "src/formkit/components/types";
import {
  OrderBy,
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SyncsBoolExp,
  SyncsOrderBy,
  SyncsQuery,
  SyncsQueryVariables,
  useAddLabelsToSyncsMutation,
  useDeleteSyncsMutation,
  useDraftsQuery,
  useStartSyncRunMutation,
  useSyncFiltersQuery,
  useSyncsLabelsQuery,
  useSyncsQuery,
  useUpdateSyncsMutation,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { ColumnReference } from "src/types/visual";
import { InfoIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";
import { PageTable, SortOption, TableColumn, useTableConfig, useTableSort } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { Placeholder } from "src/ui/table/placeholder";
import { useRowSelect } from "src/ui/table/use-row-select";
import { QueryTypeIcon } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";
import { getQueryWithOpts } from "src/utils/query-with-opts";
import { SyncStatus, SyncStatusBadge } from "src/utils/syncs";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

const initialSort: SortOption<keyof SyncsOrderBy> = { key: "created_at", direction: OrderBy.Desc, label: "Newest" };
const sortOptions: SortOption<keyof SyncsOrderBy>[] = [
  { key: "status", direction: OrderBy.Asc, label: "Status" },
  { key: "segment.name" as any, direction: OrderBy.Asc, label: "Model name" },
  { key: "destination.name" as any, direction: OrderBy.Asc, label: "Destination name" },
  { key: "last_run_at" as any, direction: OrderBy.DescNullsLast, label: "Last run" },
  initialSort,
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

const getBulkDeleteSyncMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") && error.message.includes("sync_sequence")
    ? "One or more of the selected syncs cannot be deleted because they are used in sequences"
    : error.message;
};

interface Mapping {
  from?: ColumnReference | string | { type: MappingType.BOOSTED; semanticType: string };
  to: string | undefined;
  type: "reference" | "standard";
  [x: string]: unknown;
}

interface Segment {
  id: number;
  matchboosting_enabled?: boolean;
  parent?: Segment;
  [x: string]: unknown;
}

const isSyncMatchBoosted = (segment: Segment, config: Record<string, unknown>) => {
  const modelSupportsMB = Boolean(segment?.matchboosting_enabled || segment?.parent?.matchboosting_enabled);
  const allMappings = getAllMappings(config);
  return modelSupportsMB && allMappings.some((m) => typeof m?.from === "object" && m.from?.type === MappingType.BOOSTED);
};

function getAllMappings(configuration: Record<string, any>) {
  const mappings: Mapping[] = [];

  for (const field of Object.keys(configuration)) {
    const value = configuration[field];
    if (Array.isArray(value) && value.every(isMapping)) {
      for (const v of value) {
        mappings.push(v);
      }
    } else {
      if (isMapping(value)) {
        mappings.push(value);
      }
    }
  }

  return mappings;
}

const isMapping = (m: unknown) => {
  return (
    typeof m === "object" &&
    m !== null &&
    m !== undefined &&
    typeof m["to"] === "string" &&
    m["type"] !== "destinationOnlyMapping"
  );
};

const useFastSyncsQuery = getQueryWithOpts<SyncsQuery, SyncsQueryVariables>(useSyncsQuery, { useFastEndpoint: true });

export const Syncs: FC = () => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const routeState = location.state as { onboardingSync: string };
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState(false);
  const [confirmBulkRun, setConfirmBulkRun] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [processingAction, setProcessingAction] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);
  const {
    selectedFolder,
    selectedFolderType,
    setSelectedFolder,
    setSelectedFolderType,
    setMovingToFolder,
    movingToFolder,
    header,
    refetchFolders,
    clearFolderType,
    totalCount,
    audienceCount,
    modelCount,
    nestedFolders: folders,
    loadingFolders,
  } = useFolderState({ search, resourceType: "syncs" });
  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "source", grants: [ResourcePermissionGrant.Delete] }]);
  const { hasPermission: userCanUpdate } = useHasPermission([{ resource: "source", grants: [ResourcePermissionGrant.Update] }]);
  const { hasPermission: userCanRun } = useHasPermission([{ resource: "sync", grants: [ResourcePermissionGrant.Start] }]);

  const { limit, offset, page, setPage } = useTableConfig<SyncsOrderBy>();
  const orderBy = useTableSort<SyncsOrderBy>(initialSort, sortOptions);

  const { data: allSyncs, isLoading: filtersLoading } = useSyncFiltersQuery(undefined, { select: (data) => data.syncs });

  const filterDefinitions = useMemo(() => {
    return {
      viewKey: "sync",
      loading: filtersLoading,
      filters: {
        destination: { options: destinationFilterConfig(allSyncs || []), title: "Destination" },
        source: { options: sourceFilterConfig(allSyncs || []), title: "Source" },
        status: { options: syncStatusFilterConfig(), title: "Status" },
        created: { options: createdByFilterConfig(allSyncs || []), title: "Creator" },
        label: { options: labelFilterConfig(allSyncs || []), title: "Label" },
      },
    };
  }, [allSyncs]);

  const {
    result: { state: filterState, data: filterData },
    state: { creatingView, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, selectView, updateCurrentView, resetViewFilters, clearFilters },
  } = useFilters(filterDefinitions);

  const hasuraFilters = useMemo(() => {
    if (!allSyncs?.length) {
      return {};
    }

    const folderFilter: SegmentsBoolExp | undefined = (() => {
      const folderIds: string[] = [];

      if (selectedFolder?.id) {
        folderIds.push(selectedFolder.id);
      }
      if (selectedFolder?.flattenedChildren?.length) {
        folderIds.push(...selectedFolder.flattenedChildren.map((f) => f.id));
      }

      switch (selectedFolderType) {
        case "models":
          return {
            query_type: { _neq: QueryType.Visual },
            is_schema: { _eq: false },
            ...(folderIds.length ? { folder_id: { _in: folderIds } } : {}),
          };
        case "audiences":
          return { query_type: { _eq: QueryType.Visual }, ...(folderIds.length ? { folder_id: { _in: folderIds } } : {}) };
        default:
          return undefined;
      }
    })();

    const segmentFilter = () => {
      if (!folderFilter && !isFilterActive(filterState.source)) {
        return {};
      }
      return {
        segment: {
          ...(isFilterActive(filterState.source)
            ? { connection_id: { _in: filterState.source.selected.map((filter) => filter.id) } }
            : {}),
          ...(folderFilter || {}),
        },
      };
    };

    const destinationFilter = () => {
      if (isFilterActive(filterState.destination)) {
        return { destination_id: { _in: filterState.destination.selected.map((filter) => filter.id) } };
      }
      return {};
    };

    const statusFilter = () => {
      if (isFilterActive(filterState.status)) {
        return { status: { _in: filterState.status.selected.map((filter) => filter.id) } };
      }
      return {};
    };

    const labelFilter = () => {
      if (isFilterActive(filterState.label)) {
        return {
          _or: filterState.label.selected.map((filter) => {
            const key = filter.id.split(":")[0];
            const value = filter.id.split(":")[1];
            const obj = {};
            obj[key!] = value;
            return {
              tags: { _contains: obj },
            };
          }),
        };
      }
      return {};
    };

    const createdFilter = () => {
      if (isFilterActive(filterState.created)) {
        return {
          _or: [
            {
              created_by: { _in: filterState.created.selected.map((f) => f.id) },
            },
            {
              created_by: { _is_null: true },
            },
          ],
        };
      }
      return {};
    };

    const andFilter = () => {
      const filter = {
        ...destinationFilter(),
        ...segmentFilter(),
        ...statusFilter(),
      };
      if (Object.keys(filter).length) {
        return {
          _and: [filter],
        };
      }
      return {};
    };

    const hasuraFilters: SyncsBoolExp = {
      ...andFilter(),
      ...labelFilter(),
      ...createdFilter(),
    };

    return hasuraFilters;
  }, [filterState, selectedFolder, selectedFolderType]);

  const hasuraFiltersWithSearch = useMemo(() => {
    if (search) {
      const searchFilters: SyncsBoolExp[] = [
        { segment: { name: { _ilike: `%${search}%` } } },
        { destination: { name: { _ilike: `%${search}%` } } },
        { destination: { type: { _ilike: `%${search}%` } } },
      ];
      return { _and: [hasuraFilters, { _or: searchFilters }] };
    } else {
      return hasuraFilters;
    }
  }, [hasuraFilters, search]);

  const syncsQuery = useFastSyncsQuery(
    {
      offset,
      limit,
      filters: hasuraFiltersWithSearch,
      orderBy,
    },
    {
      refetchInterval: 3000,
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const syncsLabelsQuery = useSyncsLabelsQuery(
    {
      offset,
      limit,
      filters: hasuraFiltersWithSearch,
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const { data: drafts } = useDraftsQuery({
    resourceType: "sync",
    status: "pending",
  });

  const { labels } = useLabels();

  const { mutateAsync: bulkDeleteSyncs, isLoading: loadingBulkDelete } = useDeleteSyncsMutation();
  const { mutateAsync: updateSyncs } = useUpdateSyncsMutation();
  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToSyncsMutation();
  const { mutateAsync: forceRun } = useStartSyncRunMutation();

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a sync, upgrade your plan.";
  const audiencesEnabled = entitlementsData.entitlements.audiences;

  const bulkUpdateStatus = async (enabled: boolean) => {
    setProcessingAction(true);

    try {
      await updateSyncs({
        ids: selectedRows.map(String),
        object: {
          schedule_paused: !enabled,
        },
      });

      toast({
        id: "bulk-update-syncs",
        title: `Selected syncs were ${enabled ? "enabled" : "disabled"}`,
        variant: "success",
      });

      onRowSelect([]);
      setProcessingAction(false);
    } catch (error) {
      toast({
        id: "bulk-update-syncs",
        title: error.message,
        variant: "success",
      });

      Sentry.captureException(error);
    }
  };

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await addLabels({ ids: selectedRows.map(String), labels });
      setAddingLabels(false);

      toast({
        id: "bulk-add-labels",
        title: `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "sync",
          selectedRows.length,
        )}`,
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-add-labels",
        title: "Couldn't update labels",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const bulkDelete = async () => {
    if (!userCanDelete) {
      toast({
        id: "bulk-delete-syncs",
        title: "You don't have permissions to delete syncs",
        variant: "error",
      });
      return;
    }

    try {
      setProcessingAction(true);

      await bulkDeleteSyncs({ ids: selectedRows.map(String) });

      setConfirmingDelete(false);

      toast({
        id: "bulk-delete-syncs",
        title: "Selected syncs were deleted",
        variant: "success",
      });

      onRowSelect([]);
    } catch (error) {
      toast({
        id: "bulk-delete-syncs",
        title: getBulkDeleteSyncMessage(error),
        variant: "error",
      });

      Sentry.captureException(error);
    }

    setProcessingAction(false);
  };

  const bulkRun = async () => {
    setProcessingAction(true);

    return await Promise.all(selectedRows.map((id) => forceRun({ id: Number(id), full_resync: false })))
      .then(() => {
        toast({
          id: "bulk-run-syncs",
          title: "Selected syncs are running",
          variant: "success",
        });

        onRowSelect([]);
        setProcessingAction(false);
      })
      .catch((error) => {
        toast({
          id: "bulk-run-syncs",
          title: "Error bulk running syncs",
          variant: "error",
        });

        Sentry.captureException(error);
      });
  };

  const syncs = useMemo(() => {
    const labelData = syncsLabelsQuery.data?.syncs;
    if (labelData) {
      return syncsQuery.data?.syncs?.map((s) => ({ ...s, labels: labelData.find((d) => s.id === d.id)?.labels }));
    }
    return syncsQuery.data?.syncs;
  }, [syncsQuery.data, syncsLabelsQuery.data]);

  const syncsCount = syncsQuery.data?.syncs_aggregate?.aggregate?.count ?? 0;

  const selectedSegmentQueryTypes =
    syncs?.filter((sync) => selectedRows.includes(sync.id))?.map((sync) => sync.segment?.query_type) || [];

  const moveToFolderType = selectedFolderType || (selectedSegmentQueryTypes.includes("visual") ? "audiences" : "models");

  const selectedSyncsMixesModelsAndAudiences =
    selectedSegmentQueryTypes.some((type) => type === QueryType.Visual) &&
    selectedSegmentQueryTypes.some((type) => type !== QueryType.Visual);

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Last run",
        min: "160px",
        max: "160px",
        cell: ({ id, status, sync_requests, draft: isInitialDraft }) => {
          if (isInitialDraft) {
            return (
              <Row sx={{ flex: 1, span: { width: "104px", textAlign: "center" } }}>
                <DraftBadge />
              </Row>
            );
          }

          const syncRequest = sync_requests?.[0];
          const request = syncRequest ? syncRequest : { status_computed: status };

          const draft = drafts?.drafts.find((d) => String(d.resource_id) === String(id));
          return (
            <Column gap={1}>
              <Row align="center" gap={2}>
                <SyncStatusBadge request={request} status={status} />
                {draft && <DraftIcon draft={draft} />}
              </Row>
              {syncRequest?.finished_at ? (
                <Text size="sm" color="text.secondary">{`${formatDistanceToNowStrict(new Date(syncRequest?.finished_at), {
                  addSuffix: true,
                })}`}</Text>
              ) : syncRequest?.completion_ratio ? (
                <Text size="sm" color="text.secondary">
                  {Math.round(syncRequest.completion_ratio * 100)}% complete
                </Text>
              ) : null}
            </Column>
          );
        },
        breakpoint: "sm",
      },
      {
        name: "Model",
        cell: ({ segment, config }) => (
          <Row gap={3} width="100%" align="center" overflow="hidden">
            <IntegrationIcon src={segment?.connection?.definition?.icon} name={segment?.connection?.definition?.name} />
            <Column gap={1} overflow="hidden">
              <Text isTruncated fontWeight="medium">
                {segment?.name ?? "Private model"}
              </Text>
              <Row gap={2}>
                <Pill>
                  <Row gap={1} align="center" whiteSpace="nowrap">
                    <QueryTypeIcon type={segment?.query_type} />
                    {segment?.query_runs?.[0] ? `${abbreviateNumber(segment?.query_runs?.[0]?.size)} rows` : "Unknown size"}
                  </Row>
                </Pill>
                {isSyncMatchBoosted(segment, config) && (
                  <Pill>
                    <Row gap={2} align="center">
                      <SparklesIcon color="#F5C24D" width={16} />
                      Boosted
                    </Row>
                  </Pill>
                )}
              </Row>
            </Column>
          </Row>
        ),
      },
      {
        name: "Destination",
        cell: ({ destination, labels }) => {
          return (
            <Row gap={3} overflow="hidden" width="100%" align="center">
              <IntegrationIcon
                name={destination?.definition?.name ?? "Private destination"}
                src={destination?.definition?.icon}
              />
              <Column gap={1} overflow="hidden">
                <Text isTruncated fontWeight="medium">
                  {destination?.name ?? destination?.definition?.name ?? "Private destination"}
                </Text>
                {Object.keys(labels ?? {}).length > 0 && (
                  <DestinationBadgeGroup>
                    {Object.keys(labels).map((key) => (
                      <DestinationBadge key={key}>{labels[key]}</DestinationBadge>
                    ))}
                  </DestinationBadgeGroup>
                )}
              </Column>
            </Row>
          );
        },
      },
      { ...LastUpdatedColumn, breakpoint: "md" },
      {
        name: "Labels",
        key: "tags",
        cell: (labels) => {
          if (isEmpty(labels)) {
            return "--";
          }
          return <Labels labels={labels} />;
        },
        breakpoint: "lg",
      },
    ],
    [drafts],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/syncs/${id}`, navigate, event), [navigate]);

  const placeholder = useMemo(
    () => ({
      image: searchPlaceholder,
      title: "No syncs found",
      error: "Syncs failed to load, please try again.",
    }),
    [],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  const disabledRowsSelected = Boolean(
    syncs?.some(({ id, status }) => status === SyncStatus.DISABLED && selectedRows.find((rowId) => rowId === id)),
  );

  return (
    <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
      <Page
        sidebar={
          <>
            <Row pr={6} pl={2}>
              <SearchInput
                placeholder="Search all syncs..."
                value={search ?? ""}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
            </Row>
            <Column overflow="auto" gap={4} px={2}>
              <Folders
                totalCount={totalCount}
                folders={folders ?? []}
                loading={loadingFolders}
                modelCount={modelCount}
                audienceCount={audienceCount}
                audiencesRootName={audiencesEnabled ? "Audience syncs" : undefined}
                modelsRootName="Model syncs"
                refetchFolders={refetchFolders}
                rootFolder={selectedFolderType}
                selectedFolder={selectedFolder}
                setRootFolder={setSelectedFolderType}
                setSelectedFolder={setSelectedFolder}
                viewType="syncs"
                activeSearch={Boolean(search)}
                clearFolderType={clearFolderType}
              />
              <Box borderBottom="1px solid" borderColor="base.divider" />
              <Filters
                clearFilters={clearFilters}
                createView={createView}
                creatingView={creatingView}
                deleteView={deleteView}
                filters={filterData}
                resetFilters={resetViewFilters}
                resource="sync"
                selectView={selectView}
                selectedView={selectedView}
                updateCurrentView={updateCurrentView}
                updatingView={updatingView}
                viewNotSaved={viewNotSaved}
                views={views}
              />
            </Column>
          </>
        }
        title="Syncs"
      >
        <PageTable
          sortOptions={sortOptions}
          header={
            <>
              <Heading isTruncated size="xl">
                {header}
              </Heading>
              <Row flexShrink={0} gap={3}>
                {selectedRows.length > 0 && (
                  <Row align="center" gap={2}>
                    <Text>{`${pluralize("sync", selectedRows.length, true)} selected`}</Text>
                    <Menu>
                      <MenuButton>Actions</MenuButton>
                      <MenuList>
                        {userCanUpdate && (
                          <>
                            <MenuItem
                              icon={FolderOpenIcon}
                              onClick={() => {
                                setMovingToFolder(true);
                              }}
                            >
                              Move to folder
                            </MenuItem>
                            <MenuItem
                              icon={TagIcon}
                              onClick={() => {
                                setAddingLabels(true);
                              }}
                            >
                              Add labels
                            </MenuItem>
                            <MenuItem
                              icon={BoltSlashIcon}
                              isDisabled={processingAction}
                              onClick={() => {
                                bulkUpdateStatus(false);
                              }}
                            >
                              Disable
                            </MenuItem>
                            <MenuItem
                              icon={BoltIcon}
                              isDisabled={processingAction}
                              onClick={() => {
                                bulkUpdateStatus(true);
                              }}
                            >
                              Enable
                            </MenuItem>
                            {userCanRun && (
                              <MenuItem icon={PlayIcon} isDisabled={processingAction} onClick={() => setConfirmBulkRun(true)}>
                                Run
                              </MenuItem>
                            )}
                          </>
                        )}

                        <ConfirmationDialog
                          confirmButtonText="Run syncs"
                          isOpen={confirmBulkRun}
                          title="Confirm bulk run"
                          variant="warning"
                          onClose={() => setConfirmBulkRun(false)}
                          onConfirm={() => bulkRun()}
                        >
                          <Paragraph>
                            {disabledRowsSelected && "Some of the syncs you've selected are currently disabled. "}
                            {`Are you sure you want to run ${pluralize("Sync", selectedRows.length, true)}?`}
                          </Paragraph>
                        </ConfirmationDialog>

                        {userCanUpdate && userCanDelete && <MenuDivider />}

                        {userCanDelete && (
                          <MenuItem
                            icon={TrashIcon}
                            isDisabled={processingAction}
                            variant="danger"
                            onClick={() => {
                              setConfirmingDelete(true);
                            }}
                          >
                            Delete
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Row>
                )}

                <PermissionedLinkButton
                  href="/syncs/new"
                  isDisabled={overageLockout}
                  permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}
                  tooltip={overageLockout && overageText}
                  variant="primary"
                  onClick={() => {
                    analytics.track("Add Sync Clicked");
                  }}
                >
                  Add sync
                </PermissionedLinkButton>
              </Row>
            </>
          }
          columns={columns}
          data={syncs}
          error={Boolean(syncsQuery.error)}
          loading={syncsQuery.isLoading || syncsQuery.isPreviousData}
          placeholder={placeholder}
          selectedRows={selectedRows}
          onRowClick={onRowClick}
          onSelect={onRowSelect}
          rowHeight="80px"
          pagination={{
            count: syncsCount,
            label: "syncs",
            page,
            setPage,
          }}
        />

        <Modal
          bodySx={{ borderRadius: 2, pb: 5 }}
          footer={
            <>
              <Button onClick={() => setConfirmingDelete(false)}>Cancel</Button>
              <Button isLoading={loadingBulkDelete} variant="danger" onClick={bulkDelete}>
                Delete
              </Button>
            </>
          }
          header={
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoIcon sx={{ color: "red", mr: 3 }} />
              <Heading>
                Delete {pluralize("this", selectedRows.length, false)} {pluralize("Sync", selectedRows.length, false)}?
              </Heading>
            </Box>
          }
          isOpen={confirmingDelete}
          sx={{ borderRadius: 0, width: "600px" }}
          onClose={() => setConfirmingDelete(false)}
        >
          You will lose your sync {pluralize("configuration", selectedRows.length, false)}.
        </Modal>

        <EditLabelModal
          description="You can label syncs that have similar properties"
          existingLabelOptions={labels}
          hint="Example keys: team, project, region, env."
          isOpen={addingLabels}
          loading={loadingAddLabels}
          saveLabel={`Apply to ${selectedRows.length} ${pluralize("sync", selectedRows.length)}`}
          title="Add labels"
          onClose={() => setAddingLabels(false)}
          onSave={bulkAddLabels}
        />

        {movingToFolder && (
          <MoveFolder
            disabled={selectedSyncsMixesModelsAndAudiences}
            folder={null}
            folderType={moveToFolderType}
            modelIds={selectedRows.map((row) => {
              const sync = syncs?.find((sync) => sync.id === row);
              return sync?.segment?.id;
            })}
            viewType="syncs"
            onClose={() => {
              setMovingToFolder(false);
              syncsQuery.refetch();
              onRowSelect([]);
            }}
          />
        )}

        {routeState?.onboardingSync && <ConfettiModal syncId={routeState.onboardingSync} />}
      </Page>
    </PermissionProvider>
  );
};

const Loader = () => {
  const { resources } = useUser();

  if (resources?.sync) {
    return <Syncs />;
  }

  let props;

  if (!resources?.source) {
    props = pageAlertProps.source;
  } else if (!resources?.destination) {
    props = pageAlertProps.destination;
  } else if (!resources?.model) {
    props = pageAlertProps.model;
  }

  return (
    <Page fullWidth outsideTopbar={props ? <PageAlert {...props} /> : null} title="Syncs">
      <Heading mb={8} size="xl">
        Syncs
      </Heading>
      <Placeholder
        content={{
          image: syncPlaceholder,
          title: "No syncs in this workspace",
          body: "A sync defines how and when your data will be sent to a destination. Syncs are usually configured by mapping your data to specific fields in the downstream tool.",
          button: props ? null : (
            <PermissionedLinkButton
              href="/syncs/new"
              permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}
              variant="primary"
            >
              Add sync
            </PermissionedLinkButton>
          ),
        }}
      />
    </Page>
  );
};

export default Loader;

const pageAlertProps = {
  model: {
    title: "First, you need to configure a model",
    description:
      "Before you can create your first sync, you'll need to define how your data source will be queried. Hightouch supports different modeling methods such as querying with SQL, selecting an existing table, or importing models from tools like Looker and dbt.",
    button: (
      <PermissionedLinkButton
        href="/models/new"
        permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}
        variant="primary"
      >
        Configure model
      </PermissionedLinkButton>
    ),
  },
  source: {
    title: "First, you need to configure a data source",
    description:
      "Hightouch must be connected to least one data source before you can create a sync. Your source can be a data warehouse, spreadsheet, or other data system.",
    button: (
      <PermissionedLinkButton
        href="/sources/new"
        permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Create] }]}
        variant="primary"
      >
        Configure data source
      </PermissionedLinkButton>
    ),
  },
  destination: {
    title: "First, you need to configure a destination",
    description:
      "Hightouch must be connected to at least one destination before you can create a sync. Your destination can be chosen from our catalog of 100+ supported tools.",
    button: (
      <PermissionedLinkButton
        href="/destinations/new"
        permissions={[{ resource: "destination", grants: [ResourcePermissionGrant.Create] }]}
        variant="primary"
      >
        Configure destination
      </PermissionedLinkButton>
    ),
  },
};
